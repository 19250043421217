import React, { useContext } from 'react';
import { useSession } from '../../SessionProvider';
import { Typography } from '@mui/material';

export default function CustomHeader(props) {
    const { sessionData } = useSession();

    let headerContent = props.headerText;

    if (sessionData) {
        headerContent += ` - ${sessionData.currentCompanyName}`;
    }

    return (
        <Typography variant="h4" component="h2" className="header">
            {headerContent}
        </Typography>
    );
}

