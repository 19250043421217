import React, {  useState, useEffect, useRef } from 'react';
import InputField from '../Generic/InputField';
import MultilineInputField from '../Generic/MultilineInputField';
import FormikSelectField from '../Generic/FormikSelectField';
import MultiSelectTreeField from '../Generic/MultiSelectTreeField';
import StatusBackdrop from "../Generic/StatusBackdrop";
import { useQuery } from "react-query";
import { Toast } from 'primereact/toast';
import { KeysToLowerCase } from "../Generic/MiscFunctions";
import { fetchAlarmNodeChannels } from './AlarmQueryFunctions';
import {
    Paper, 
    Grid,
    Typography    
} from '@mui/material';


export default function AlarmStep1ContentEdit(props) {
    const toastTargets = useRef(null);

    // New handler for tracking the selected value
    const handleDropdownChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    // Combined handler that calls both props.formik?.handleChange and handleDropdownChange
    const combinedOnChangeHandler = (event) => {
        // Call the existing handler
        if (props.formik?.handleChange) {
            props.formik.handleChange(event);
        }

        // Call handleDropdownChange with the event object
        handleDropdownChange(event);
    };

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            {/*{(isLoadingTargets && !isLoadedTargets) &&*/}
            {/*    <StatusBackdrop open={(isLoadingTargets && !isLoadedTargets)} />}*/}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >  
                <Toast
                    ref={toastTargets}
                    position='center'
                />
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="body1">
                        Alarm Target: {
                            props.thisTarget ?
                            props.thisTarget.NodeChannelDisplayName + " > " + props.thisTarget.NodeDisplayName :
                            'Item not found'}
                    </Typography>
                </Grid>                                
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                    
                        <InputField
                            name={'alarmName'}
                            label={'Name'}
                            onBlur={props.formik?.onBlur}
                            errors={props.formik?.errors['alarmName']}
                            width={'75%'}
                        />
                    
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    
                        <MultilineInputField
                            name={'alarmSummary'}
                            label={'Summary'}
                            onBlur={props.formik?.onBlur}
                            onChange={props.formik?.handleChange}
                            errors={props.formik?.errors["alarmSummary"]}
                            width={'75%'}
                        />
                    
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    
                        <FormikSelectField
                            name={'thresholdDataTypeID'}
                            label={'Threshold Data Type'}
                            data={props.dataTypes}
                            onChange={(e) => combinedOnChangeHandler(e)} // Pass the combined handler
                            onBlur={props.formik?.onBlur}
                            errors={props.formik?.errors["thresholdDataTypeID"]}
                            width={'75%'}
                            disabled={true}
                        />
                    
                </Grid>               
            </Grid>
        </Paper>
    );

}

