import React from 'react';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTheme } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useClasses } from '../../customHooks';
import AssetDetailInnerTableExpandedRow from './AssetDetailInnerTableExpandedRow';
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import { GetSeverityStatusColorVIB } from '../Generic/MiscFunctions';
import CommunicationStatus from "../Generic/CommunicationStatus";
import BatteryStatus from "../Generic/BatteryStatus";
import TableRow from '@mui/material/TableRow';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                // Soft hide the button.
                visibility: 'hidden',
            },
        },
    },
}

export default function AssetDetailTableExpandable(props) {
    const classes = useClasses(styles);
    const theme = useTheme();

    const updateRowsExpanded = (allRowsExpanded) => {
        let detailRowsExpanded = allRowsExpanded.map(item => { return item.index; });
        props.onRowExpansionChange(detailRowsExpanded);
    }

    const getTrafficLight = (dataIndex) => {
        let status = props.data[dataIndex].StatusInfo;

        return (
            <StatusTrafficLightDisplay
                Status={status}
                alarmStatusInfo={props.alarmStatusInfo}
                keyName={
                    dataIndex + "-" +
                    props.data[dataIndex].NodeID}
            />
        );
    }

    const columns = [
        {
            name: "PositionID",
            label: "Asset Position",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    //console.log("PROPS: " + JSON.stringify(props.data[dataIndex]));
                    let statusColor = GetSeverityStatusColorVIB(
                        props.data[dataIndex].StatusInfo.Severity,
                        props.alarmStatusInfo,
                        props.data[dataIndex].CheckinInfo.SecondsSinceCheckIn
                    );
                    let posName = "P" + props.data[dataIndex].PositionType.ID + " "
                        + props.data[dataIndex].PositionType.DisplayName;

                    return (
                        <span style={{ color: statusColor.StatusColor }}>
                            <strong>{posName}</strong>
                        </span>
                    );
                }
            }
        },
        {
            name: "NodeName",
            label: "Node Name",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    let statusColor = GetSeverityStatusColorVIB(
                        props.data[dataIndex].StatusInfo.Severity,
                        props.alarmStatusInfo,
                        props.data[dataIndex].CheckinInfo.SecondsSinceCheckIn
                    );
                    return (
                        <span style={{ color: statusColor.StatusColor }}>
                            <strong>{props.data[dataIndex].DisplayName}</strong>
                        </span>
                    )

                }
            }
        },
        {
            name: "NodeStatus",
            label: "Node Status",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <React.Fragment>
                            {getTrafficLight(dataIndex)}                            
                        </React.Fragment>
                    )
                }
            }

        },
        {
            name: "VibrationStatus",
            label: "Vibration Status",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {

                    // TODO: axial, horizontal, vertical are hard-coded... need to return vibration axes, not sensor axes
                    let xAmpDetail = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 4 && a.ComputedValue == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 4).map(object => { return object.ComputedValue })));
                    let xAmpStatus = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 4 && a.AlarmTypeID == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 4).map(object => { return object.AlarmTypeID })));
                    let xStatusColor = xAmpStatus ? GetSeverityStatusColorVIB(xAmpStatus.AlarmTypeID, props.alarmStatusInfo, xAmpDetail?.SecondsSinceCheckIn) : GetSeverityStatusColorVIB(null, props.alarmStatusInfo, xAmpDetail?.SecondsSinceCheckIn);
                    let yAmpDetail = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 5 && a.ComputedValue == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 5).map(object => { return object.ComputedValue })));
                    let yAmpStatus = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 5 && a.AlarmTypeID == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 5).map(object => { return object.AlarmTypeID })));
                    let yStatusColor = yAmpStatus ? GetSeverityStatusColorVIB(yAmpStatus.AlarmTypeID, props.alarmStatusInfo, yAmpDetail?.SecondsSinceCheckIn) : GetSeverityStatusColorVIB(null, props.alarmStatusInfo, yAmpDetail?.SecondsSinceCheckIn);
                    let zAmpDetail = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 6 && a.ComputedValue == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 6).map(object => { return object.ComputedValue })));
                    let zAmpStatus = props.data[dataIndex].AmplitudeData.find(a => a.AxisID == 6 && a.AlarmTypeID == Math.max(...props.data[dataIndex].AmplitudeData.filter(a => a.AxisID == 6).map(object => { return object.AlarmTypeID })));
                    let zStatusColor = zAmpStatus ? GetSeverityStatusColorVIB(zAmpStatus.AlarmTypeID, props.alarmStatusInfo, zAmpDetail?.SecondsSinceCheckIn) : GetSeverityStatusColorVIB(null, props.alarmStatusInfo, zAmpDetail?.SecondsSinceCheckIn);

                    let xComputedValue = xAmpDetail ? `Axial: ${xAmpDetail.ComputedValue.toFixed(3)} ${xAmpDetail.UnitTypeSymbol}` : 'No Axial Data';
                    let yComputedValue = yAmpDetail ? `Horizontal: ${yAmpDetail.ComputedValue.toFixed(3)} ${yAmpDetail.UnitTypeSymbol}` : 'No Horizontal Data';
                    let zComputedValue = zAmpDetail ? `Vertical: ${zAmpDetail.ComputedValue.toFixed(3)} ${zAmpDetail.UnitTypeSymbol}` : 'No Vertical Data';

                    return (
                        <React.Fragment>
                            <span style={{ color: xStatusColor.StatusColor  }}>
                                <strong>{ xComputedValue}</strong>
                            </span><br />
                            <span style={{ color: yStatusColor.StatusColor }}>
                                <strong>{ yComputedValue }</strong>
                            </span><br />
                            <span style={{ color: zStatusColor.StatusColor }}>
                                <strong>{ zComputedValue }</strong>
                            </span>
                        </React.Fragment>
                    )

                }
            }
        },
        {
            name: "Temperature",
            label: "Surface Temperature",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    if (props.data[dataIndex].TemperatureInfo.ComputedValue) {
                        return (
                            <span>
                                <strong>{props.data[dataIndex].TemperatureInfo.ComputedValue.toFixed(1) + " "
                                    + props.data[dataIndex].TemperatureInfo.UnitTypeSymbol}</strong>
                            </span>
                        );
                    } else {
                        return (
                            <span>
                                <strong>No Temperature Data</strong>
                            </span>
                        );
                    }
                }
            }
        },
        {
            name: "WirelessSignal",
            label: "Node Connectivity",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    const minCheckInTime = props.data[dataIndex].CheckinInfo.SecondsSinceCheckIn;

                    const nodeIsDown = (minCheckInTime === null || minCheckInTime > 604800);
                    return (
                        <React.Fragment>
                            <CommunicationStatus
                                value={props.data[dataIndex].SignalInfo.ComputedValue}
                                unitType={props.data[dataIndex].SignalInfo.UnitTypeSymbol}
                                checkinInfo={[props.data[dataIndex].CheckinInfo]}
                                minCheckInTime={minCheckInTime}
                            />
                            {nodeIsDown &&
                                <PriorityHighIcon sx={{ color: theme.palette.error.main }} fontSize="large" />}
                        </React.Fragment>
                    )
                }
            }
        },
        {
            name: "Battery",
            label: "Node Battery",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    const minCheckInTime = props.data[dataIndex].CheckinInfo.SecondsSinceCheckIn;
                    return (
                        <BatteryStatus
                            value={isFinite(props.data[dataIndex].BatteryInfo.ComputedValue) ?
                                props.data[dataIndex].BatteryInfo.ComputedValue : -1}
                            unitType={props.data[dataIndex].BatteryInfo.UnitTypeSymbol}
                            minCheckInTime={minCheckInTime}
                        />
                    );                    
                }
            }
        },
        {
            name: "NodeID",
            options: {
                display: "excluded"
            }
        },
        {
            name: "ObjectID",
            options: {
                display: "excluded",

            }
        }

    ];

    const options = {
        elevation: 5,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        sort: false,
        sortFilterList: false,
        viewColumns: false,
        selectableRows: 'single',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        rowsPerPage: 10,
        count: props.count,
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        rowsExpanded: props.detailRowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            updateRowsExpanded(allRowsExpanded);
        },
        renderExpandableRow: (rowData, rowMeta) => {
            let colspan = columns.length;
            //console.log("NodeData: " + JSON.stringify(props.data));
            let nodeData = props.data.find(n => n.NodeID === rowData[7]);
            let opacity = (nodeData.IsConnected ? 1 : 0.6);
            
            return (
                <TableRow>
                    <AssetDetailInnerTableExpandedRow
                        colspan={colspan}
                        nodeID={rowData[7]}
                        xAmpData={nodeData.AmplitudeData.filter(d => d.AxisID == 4)}
                        yAmpData={nodeData.AmplitudeData.filter(d => d.AxisID == 5)}
                        zAmpData={nodeData.AmplitudeData.filter(d => d.AxisID == 6)}
                        alarmStatusInfo={props.alarmStatusInfo}
                        opacity={opacity}
                    />
                </TableRow>
            );
        },

    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    return (
        <CacheProvider value={muiCache}>
            <MUIDataTable
                data={props.data}
                columns={columns}
                options={options}
                components={components}
                className={classes.overrides}
            />
        </CacheProvider>
    );
}