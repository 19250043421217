import React from 'react';
import { Popover, List, ListItemButton, ListItemText } from '@mui/material';

export default function ControlGateMenu(props) {
    return (
        <Popover
            id={props.id}
            open={props.controlGateMenuOpen}
            anchorEl={props.anchorEl}
            onClose={props.handleControlGateMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List>
                <ListItemButton onClick={() => props.handleDownloadL5X(props.objectID)}>
                    <ListItemText>Export L5X</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => props.handleDownloadAOP(props.objectID)}>
                    <ListItemText>Export AOP XML</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => props.handleDownloadMemoryMap(props.objectID)}>
                    <ListItemText>Export Memory Map</ListItemText>
                </ListItemButton>
            </List>
        </Popover>
    )
}