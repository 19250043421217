import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactMailIcon from '@mui/icons-material/ContactMail';

export default function AlarmMgmtMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            <Tooltip title="Alarm Management">
                <Button
                    onClick={handleClick}
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'alarm-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    color="inherit"

                >
                    Alarm Management
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="alarm-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    component={Link}
                    to={"/Alarm/AlarmDashboard"}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    Alarm Dashboard
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    to={"/Alarm/Add"}>
                    <ListItemIcon>
                        <EditNotificationsIcon />
                    </ListItemIcon>
                    Create Alarm
                </MenuItem>
                {/*<MenuItem*/}
                {/*    component={Link}*/}
                {/*    to={"/"}*/}
                {/*    disable*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <EditNoteIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    Notification Management*/}
                {/*</MenuItem>*/}
                {/*<MenuItem*/}
                {/*    component={Link}*/}
                {/*    to={"/RecipientGroups"}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <ContactMailIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    Recipient Group Management*/}
                {/*</MenuItem>*/}
            </Menu>
        </React.Fragment>
    );
}


