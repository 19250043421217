import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSession } from '../../SessionProvider';
import { Grid, Typography, Tabs, Tab, Box } from '@mui/material';
import CompanyHome from './CompanyHome';
import ManageUsers from './ManageUsers';
import InviteUsers from './InviteUsers';
import StatusMessage from "../Generic/StatusMessage";
import StatusBackdrop from "../Generic/StatusBackdrop";
import { useQuery, useMutation } from "react-query";
import {
    fetchDashboards,
    fetchPendingInvites,
    fetchTimeZones,
    fetchUsers,
    createCompany,
    editCompany,
    removeUser,
    sendInvite,
    fetchCompanyInfo
} from './CompanyQueryFunctions';
import { Toast } from 'primereact/toast';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function CompanyManagement() {
    const { sessionData } = useSession();

    const companyID = sessionData?.currentCompanyID;
    const userID = sessionData?.userID;
    const viewAll = sessionData?.viewAll;

    const [activeTab, setActiveTab] = useState(0);
    const [errorState, setErrorState] = useState();
    const [dashboards, setDashboards] = useState();
    const [isLoadedDashboards, setIsLoadedDashboards] = useState(false);
    const [users, setUsers] = useState();
    const [userInfo, setUserInfo] = useState();
    const [isLoadedUsers, setIsLoadedUsers] = useState(false);
    const [invites, setInvites] = useState();
    const [isLoadedInvites, setIsLoadedInvites] = useState(false);
    const [timeZonesList, setTimeZonesList] = useState();
    const [isLoadedTimeZones, setIsLoadedTimeZones] = useState(false);
    const [companyInfo, setCompanyInfo] = useState();
    const [isLoadedCompanyInfo, setIsLoadedCompanyInfo] = useState(false);

    const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [timeZone, setTimeZone] = useState(1);
    const [emailList, setEmailList] = useState();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);

    const toast = useRef(null);

    const { isLoading: dashboardsLoading, error: dashboardsError, data: dataD } =
        useQuery(["dashboards", companyID, viewAll], fetchDashboards, {
            onSuccess: (dataD) => {
                setDashboards(dataD);
                setIsLoadedDashboards(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    const { isLoading: usersLoading, error: usersError, data: dataU } =
        useQuery(["users", sessionData.userID, sessionData.currentCompanyID, sessionData.viewAll], fetchUsers, {
            onSuccess: (dataU) => {
                for (let i = 0; i < dataU.Users.length; i++) {
                    if (dataU.Users[i].ID == sessionData.userID) {
                        setUserInfo(dataU.Users[i]);
                    }
                }
                setUsers(dataU);
                setIsLoadedUsers(true);
            },
            onError: (usersError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(usersError || "An unexpected error occurred.");
            }
        });

    const { isLoading: invitesLoading, error: invitesError, data: dataI } =
        useQuery(["invites", sessionData.currentCompanyID], fetchPendingInvites, {
            onSuccess: (dataI) => {
                for (let i = 0; i < dataI.length; i++) {
                    dataI[i].InvitedOn = formatDate(dataI[i].InvitedOn);
                }
                setInvites(dataI);
                setIsLoadedInvites(true);
            },
            onError: (invitesError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(invitesError || "An unexpected error occurred.");
            }
        });

    const { isLoading: companyInfoLoading, companyInfoError, dataC } =
        useQuery(["companyInfo", sessionData.currentCompanyID], fetchCompanyInfo, {
            onSuccess: (dataC) => {
                setCompanyInfo(dataC);
                setTimeZone(dataC.TimeZoneID);
                setIsLoadedCompanyInfo(true);
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", error);
                setErrorState(error || "An unexpected error occurred.");
            }
        });

    const { isLoading: timeZonesLoading, error: timeZonesError, data: dataZ } =
        useQuery(["timezone"], fetchTimeZones, {
            onSuccess: (dataZ) => {
                setTimeZonesList(dataZ)
                setIsLoadedTimeZones(true);
            },
            onError: (timeZonesError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(timeZonesError || "An unexpected error occurred.");
            }
        });

    const doCreateCompany =
        useMutation((company) => createCompany(company), {
            onSuccess: (result) => {
                setEditCompanyModalOpen(false);
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'Company created successfully',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to create company',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doEditCompany =
        useMutation((company) => editCompany(company), {
            onSuccess: (result) => {
                setEditCompanyModalOpen(false);
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'Company edited successfully',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to edit company',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });

    const doRemoveUser =
        useMutation((ID) => removeUser(sessionData.userID, ID, sessionData.currentCompanyID), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'User removed successfully',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Match the life of the toast 
            },
            onError: (removeError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: 'An error occurred while attempting to remove the user',
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
                // Wait for the toast to likely close before navigating
                setTimeout(() => {
                    window.location.reload();
                }, 30000); // Match the life of the toast
            }
            
        });

    const doSendInvite =
        useMutation((invite) => sendInvite(invite), {
            onSuccess: (result) => {
                let failCount = 0;
                let successCount = 0;
                for (let i = 0; i < result.length; i++) {
                    if (result[i] == 0) {
                        successCount++;
                    } else {
                        failCount++;
                    }
                }
                let toastMessage = {
                    severity: 'info',
                    summary: 'Result',
                    detail: 'Sent Invites. Success: ' + successCount + ' Failures: ' + failCount,
                    life: 3000,
                    position: 'center'
                }
                toast.current.show(toastMessage);
            }
        });

    const formatDate = (date) => {
        let notime = date.split("T")[0];
        let parts = notime.split("-");
        return parts[1] + "/" + parts[2] + "/" + parts[0];
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleEditCompanyClick = (event) => {
        setIsEditing(true);
        setCompanyName(dashboards.CompanyName);
        setTimeZone(companyInfo.TimeZoneID);
        setEditCompanyModalOpen(true);
    }

    const handleCreateCompanyClick = (event) => {
        setIsEditing(false);
        setCompanyName("");
        setTimeZone(companyInfo.TimeZoneID);
        setEditCompanyModalOpen(true);
    }

    const handleSaveClick = (event) => {
        if (isEditing) {
            const editCompany = {
                companyID: sessionData.currentCompanyID,
                NewCompanyName: companyName,
                NewTimeZoneID: timeZone
            }
            doEditCompany.mutateAsync(editCompany);
        } else {
            const newCompany = {
                companyName: companyName,
                adminUserID: userID,
                timeZoneID: timeZone
            }
            doCreateCompany.mutateAsync(newCompany);
        }
        
    }

    const handleCloseEditModalClick = (event) => {
        setEditCompanyModalOpen(false);
    }

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    }

    const onTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
    }

    const handleRemoveClick = (id) => {
        doRemoveUser.mutateAsync(id);
    }

    const handleSendInviteClick = () => {
        const invite = {
            inviteEmails: emailList,
            inviterID: sessionData.userID,
            companyID: sessionData.currentCompanyID,
            companyName: dashboards.CompanyName,
            inviterName: dashboards.AdminUser.DisplayName
        }
        doSendInvite.mutateAsync(invite);
        setInviteModalOpen(false);
    }

    const handleInviteUsersClick = (event) => {
        setInviteModalOpen(true);
    }

    const handleCloseInviteClick = (event) => {
        setInviteModalOpen(false);
    }

    const handleEmailListChange = (event) => {
        setEmailList(event.target.value);
    }


    return (
        <Box>
            {(dashboardsLoading || usersLoading || invitesLoading || timeZonesLoading || companyInfoLoading) &&
                <StatusBackdrop open={(dashboardsLoading || usersLoading || invitesLoading || timeZonesLoading || companyInfoLoading)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="warning"
                    location="Company"
                    statusCode={errorState?.request?.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center" sx={{ p: "10px" }}>Company Management</Typography>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                                variant="fullWidth"
                            >
                                <Tab label="Company Home" {...a11yProps(0)} />
                                <Tab label="Manage Users" {...a11yProps(1)} />
                                <Tab label="Invite Users" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={activeTab} index={0}>
                            {isLoadedDashboards && isLoadedTimeZones && isLoadedCompanyInfo && < CompanyHome
                                dashboards={dashboards}
                                companyName={companyName}
                                isEditing={isEditing}
                                timeZonesList={timeZonesList}
                                timeZone={timeZone}

                                editCompanyModalOpen={editCompanyModalOpen}
                                handleCompanyNameChange={handleCompanyNameChange}
                                handleCloseEditModalClick={handleCloseEditModalClick}
                                handleCreateCompanyClick={handleCreateCompanyClick}
                                handleEditCompanyClick={handleEditCompanyClick}
                                handleSaveClick={handleSaveClick}
                                onTimeZoneChange={onTimeZoneChange}
                            />}
                        </CustomTabPanel>
                        <CustomTabPanel value={activeTab} index={1}>
                            {isLoadedUsers && <ManageUsers
                                users={users}
                                handleRemoveClick={handleRemoveClick}
                                userInfo={userInfo}
                            />}
                        </CustomTabPanel>
                        <CustomTabPanel value={activeTab} index={2}>
                            {isLoadedInvites && <InviteUsers
                                invites={invites}
                                emailList={emailList}
                                inviteModalOpen={inviteModalOpen}

                                handleInviteUsersClick={handleInviteUsersClick}
                                handleCloseInviteClick={handleCloseInviteClick}
                                handleSendInviteClick={handleSendInviteClick}
                                handleEmailListChange={handleEmailListChange}
                            />}
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        
    )
}