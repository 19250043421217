import React, { useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';

export default function StatusMessage(props) {
    const toast = useRef(null);

    useEffect(() => {
        if (props.message) {
            showToast(props.severity, props.message, props.location, props.statusCode);
        }
    }, []);

    const showToast = (severity, summary, location, statusCode) => {
        let detailComponent = '';
        if (location && statusCode) {
            detailComponent = (
                <>
                    <div>Message: { summary }</div>
                    <div>Location: {location}</div>
                    <div>Status Code: {statusCode}</div>
                </>
            );
        }

        toast.current.show({
            severity: mapSeverity(severity),
            summary: summary,
            detail: detailComponent,
            life:5000
        });
    };

    const mapSeverity = (severity) => {
        switch (severity) {
            case 'error':
                return 'error';
            case 'warning':
                return 'warn';
            case 'info':
                return 'info';
            case 'success':
                return 'success';
            default:
                return 'info';
        }
    };

    return <Toast ref={toast} position="center" className="custom-toast" />;
}
