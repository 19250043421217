import apiClient from "../../http-common";
import { fetchRequest } from "../Generic/MiscFunctions";


export const fetchCompanyNodes = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getcompanynodes/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company nodes after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchCompanyNodesVBTx = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getcompanynodesvbtx/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company nodes after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};



export const fetchVibrationEnums = async () => {
    const maxRetries = 1;
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getenums`);
            if (Object.keys(response.data).length === 0) {
                throw new Error("Empty data response."); // Retry on empty data if appropriate
            }
            return response.data; // Return the data if the response is successful
        } catch (error) {
            if (attempt === maxRetries - 1) {
                // Log the error or handle it according to your application's needs
                console.error("Error fetching vibration enums after several attempts:", error);

                // After the final attempt, throw the error
                throw new Error("Failed to fetch vibration enums after several retries.");
            }
            attempt++;
            // Wait for a short delay before trying again, if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    // In case a new error is thrown after retries are exhausted
    throw new Error("An unexpected error occurred while fetching vibration enums.");
};

export const fetchVibrationVBTxEnums = async () => {
    const maxRetries = 1;
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getenumsvbtx`);
            if (Object.keys(response.data).length === 0) {
                throw new Error("Empty data response."); // Retry on empty data if appropriate
            }
            return response.data; // Return the data if the response is successful
        } catch (error) {
            if (attempt === maxRetries - 1) {
                // Log the error or handle it according to your application's needs
                console.error("Error fetching vibration enums after several attempts:", error);

                // After the final attempt, throw the error
                throw new Error("Failed to fetch vibration enums after several retries.");
            }
            attempt++;
            // Wait for a short delay before trying again, if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    // In case a new error is thrown after retries are exhausted
    throw new Error("An unexpected error occurred while fetching vibration enums.");
};



export const fetchVibrationDashboard = async ({ queryKey }) => {
    const [_key, companyID, userID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID || !userID) {
        throw new Error("Both 'companyID' and 'userID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getvibdashboard/${companyID}/${userID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch vibration dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchUserInfo = async ({ queryKey }) => {
    const [_key, companyID, viewAll] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/User/getalluserinfo/${companyID}/${viewAll}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch user info after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchAsset = async ({ queryKey }) => {
    const [_key, objectID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!objectID) {
        throw new Error("The 'objectID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getobject/${objectID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch asset data after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const fetchCompanyVibrationAlarms = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getvibalarms/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch company vibration alarms after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const fetchAssetDetail = async ({ queryKey }) => {
    const [_key, objectID, userID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID || !objectID) {
        throw new Error("Both 'userID' and 'objectID' parameters are required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Vibration/getassetdetail/${objectID}/${userID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch asset detail after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};


export const postAsset = async (data) => {
    try {
        const response = await apiClient.post(`/Vibration/postAsset/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error posting asset data:", error);
        throw new Error("Error posting asset data:");
    }
};

export const postAssetVBTx = async (data) => {
    try {
        const response = await apiClient.post(`/Vibration/postAssetVBTx/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error posting asset data:", error);
        throw new Error("Error posting asset data:");
    }
};


export const updateAsset = async (data) => {
    try {
        const response = await apiClient.put(`/Vibration/putAsset/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating asset:", error);
        // Handle the error as needed (e.g., re-throw, return error message, etc.)
        throw new Error("Error updating asset:"); // or return a specific error response
    }
};

export const updateAssetVBTx = async (data) => {
    try {
        const response = await apiClient.put(`/Vibration/putAssetvbtx/?companyID=${data.CompanyID}&userID=${data.UserID}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating asset:", error);
        // Handle the error as needed (e.g., re-throw, return error message, etc.)
        throw new Error("Error updating asset:"); // or return a specific error response
    }
};


export const deleteAsset = async (data) => {
    //console.log("DATA: " + JSON.stringify(data));
    try {
        const response = await apiClient.delete(`/Vibration/deleteAsset`, { data });
        //console.log(JSON.stringify(response));
        return response;
    } catch (error) {
        console.error("Error deleting asset:", error);
        // Handle the error as needed
        throw error("Error deleting asset:"); // or return a specific error response
    }
};

export const fetchDefectDashboard = async ({ queryKey }) => {
    const [_key, userID, companyID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/getmasterdefectlist/${userID}?companyID=${companyID}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

export const fetchDefectDetail = async ({ queryKey }) => {
    const [_key, recipeStateID, refresh] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!recipeStateID) {
        throw new Error("The 'recipeStateID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/getdefectdetail/${recipeStateID}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect detail after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};

export const fetchDefectRecipes = async ({ queryKey }) => {
    const [_key, userID, vibrationObjectID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!userID) {
        throw new Error("The 'userID' parameter is required.");
    }
    const vibObjectIDIsPresent =
        vibrationObjectID !== undefined
        && vibrationObjectID !== null
        && vibrationObjectID !== 'null';


    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/Defect/getmasterdefectlist/${userID}${vibObjectIDIsPresent ? '?vibrationObjectID=' + vibrationObjectID : ''}`);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Failed to fetch defect dashboard after ${maxRetries} attempts: ${error.message}`);
            }
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
        }
    }
};