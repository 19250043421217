import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import GSIcon from '../images/GraceSense_Icon_Glow.png';
import UserAccountMenu from './UserAccountMenu';
import CompanyMenu from './CompanyMenu';
import HardwareSettingsMenu from './HardwareSettingsMenu';
import AlarmMgmtMenu from './AlarmMgmtMenu';
import VibrationAnalysisMenu from './VibrationAnalysisMenu';
import SwitchTo from './SwitchTo';
import MenuSearch from './MenuSearch';
import { useTheme } from "@mui/material/styles";

export default function PrimarySearchAppBar() {
    const theme = useTheme();

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setOpen(open ? false : true);
    };

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="warning">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <Typography sx={{ display: { xs: 'flex', md: 'none' }, pl: 1 }}>Messages</Typography>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Typography sx={{ display: { xs: 'flex', md: 'none' }, pl: 1 }}>Notifications</Typography>
            </MenuItem>
            <CompanyMenu /><br />
            <UserAccountMenu />
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <SwitchTo />
                    <Box
                        component="img"
                        sx={{
                            height: 30,
                            mr: 0.5,
                        }}
                        alt="GraceSense Icon."
                        src={GSIcon}
                        to={"/"}
                        color={theme.palette.background.default} 
                    />
                    <Typography sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none',
                            lg: 'flex'
                        },
                    }} style={{ fontSize: 22 }}
                       color={theme.palette.background.default} 
                    >
                        Maintenance Hub 3.0
                    </Typography>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="collapsed menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ width: 32, height: 32 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >                                                        
                            <MenuItem>
                                <HardwareSettingsMenu />
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, pl: 25 }}>   
                        <VibrationAnalysisMenu />
                        <AlarmMgmtMenu />
                       {/* <HardwareSettingsMenu />*/}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <MenuSearch />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="warning">
                                <MailIcon sx={{ width: 32, height: 32 }} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon sx={{ width: 32, height: 32 }} />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="show help icon" color="inherit">
                            <HelpIcon sx={{ width: 32, height: 32 }} />
                        </IconButton>
                        <CompanyMenu /> <br />
                        <UserAccountMenu />
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </Box>
    );
}
