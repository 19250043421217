import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    CalculateTimeDifference,
    GetSeverityStatusColorVIB
} from '../Generic/MiscFunctions';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import EmptyAssetDetail from '../images/EmptyAssetDetail.png';
import AssetDetailTableExpandable from './AssetDetailTableExpandable';
import ObjectHeader from './ObjectHeader';
import { fetchAssetDetail, fetchVibrationEnums } from './VibrationQueryFunctions';
import { useSession } from '../../SessionProvider';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
}));

export default function AssetDetail(props) {
    // Consume parameters from URL access
    //:companyID/:objectID/:userID
    const { sessionData } = useSession();
    let { objectID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // If access is not via URL, use props
    if (!objectID) {
        objectID = props.objectID
    }

    // State to handle recurring DB interactions
    const initalState = 0;
    const [refresh, setRefresh] = useState(initalState);
    const [vibrationObject, setVibrationObject] = useState([]);
    const [vibrationObjectNodes, setVibrationObjectNodes] = useState([]);
    const [nodeDiagnosticStatus, setNodeDiagnosticStatus] = useState([]);
    const [nodeCheckInStatus, setNodeCheckInStatus] = useState([]);
    const [nodeMaxAlarmValues, setNodeMaxAlarmValues] = useState([]);
    const [nodeAmplitudeData, setNodeAmplitudeData] = useState([]);
    const [nodeFrequencyData, setNodeFrequencyData] = useState([]);
    const [nodePhaseData, setNodePhaseData] = useState([]);
    const [errorState, setErrorState] = useState();

    // State to handle relevant enumerations
    const [driveType, setDriveType] = useState([]);
    const [machineType, setMachineType] = useState([]);
    const [positionType, setPositionType] = useState([]);
    const [axisOrientations, setAxisOrientations] = useState([]);
    const [bands, setBands] = useState([]);
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);

    // State to control rendered dashboard objects
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [detailData, setDetailData] = useState([]);
    const [detailRowsExpanded, setDetailRowsExpanded] = useState([]);

    // Force react components to refresh every 10 seconds (will run on refresh variable change)
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 10000);
        return () => clearInterval(interval)
    }, [refresh]);


    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", { refresh }], fetchVibrationEnums, {
            onSuccess: (dataE) => {
                setDriveType(dataE.DriveTypes);
                setMachineType(dataE.MachineTypes);
                setAlarmStatusInfo(dataE.AlarmStatusInfo);
                setPositionType(dataE.PositionTypes);
                setAxisOrientations(dataE.AxisOrientations);
                setBands(dataE.Bands);
                setIsLoadedEnums(true);
                //console.log(dataE)
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    // Fetch Vibration Object to populate detail from DB (only react on DB changes)
    const { isLoading: isLoadingObjectDetail, error: errorFetchAsset, data: dataA } =
        useQuery(["vibrationObjectDetail", objectID, sessionData.userID, { refresh }], fetchAssetDetail, {
            onSuccess: (dataA) => {
                //console.log("Fresh Detail Information Retreived...")
                //console.log(dataA)

                // Handle structural changes
                if (JSON.stringify(dataA.VibrationObject) !== JSON.stringify(vibrationObject)) {
                    setIsLoadingDetail(true);
                    setVibrationObject(dataA.VibrationObject);
                }

                if (JSON.stringify(dataA.VibrationObjectNodes) !== JSON.stringify(vibrationObjectNodes)) {
                    setIsLoadingDetail(true);
                    setVibrationObjectNodes(dataA.VibrationObjectNodes);
                }

                // Handle data changes
                if (JSON.stringify(dataA.NodeDiagnosticStatuses) !== JSON.stringify(nodeDiagnosticStatus)) {
                    setIsLoadingData(true);
                    setNodeDiagnosticStatus(dataA.NodeDiagnosticStatuses);
                }

                // Handle data changes
                let existingNodeCheckInStatus = nodeCheckInStatus.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.HoursSinceCheckIn;
                    delete newObj.HoursSinceDataSampled;
                    delete newObj.SecondsSinceCheckIn;
                    return newObj;
                });
                let newDataCheckInStatus = dataA.NodeCheckInStatuses.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.HoursSinceCheckIn;
                    delete newObj.HoursSinceDataSampled;
                    return newObj;
                });

                if (JSON.stringify(newDataCheckInStatus) !== JSON.stringify(existingNodeCheckInStatus)) {
                    setIsLoadingData(true);
                    setNodeCheckInStatus(dataA.NodeCheckInStatuses);
                }
                if (JSON.stringify(dataA.NodeMaxAlarmValues) !== JSON.stringify(nodeMaxAlarmValues)) {
                    setIsLoadingData(true);
                    setNodeMaxAlarmValues(dataA.NodeMaxAlarmValues);
                }
                if (JSON.stringify(dataA.NodeAmplitudeData) !== JSON.stringify(nodeAmplitudeData)) {
                    setIsLoadingData(true);
                    setNodeAmplitudeData(dataA.NodeAmplitudeData);
                }
                if (JSON.stringify(dataA.NodeFrequencyData) !== JSON.stringify(nodeFrequencyData)) {
                    setIsLoadingData(true);
                    setNodeFrequencyData(dataA.NodeFrequencyData);
                }
                if (JSON.stringify(dataA.NodePhaseData) !== JSON.stringify(nodePhaseData)) {
                    setIsLoadingData(true);
                    setNodePhaseData(dataA.NodePhaseData);
                }
            },
            onError: (errorFetchAsset) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorFetchAsset || "An unexpected error occurred.");
            }
        });


    // Update Object Detail structure when object changes
    useEffect(() => {
        if (isLoadingDetail && isLoadedEnums) {
            handleDetailStructureChange();
        }
    }, [vibrationObject, vibrationObjectNodes, machineType, positionType, driveType, axisOrientations]);


    // Update Object Data when data changes
    useEffect(() => {
        if (isLoadingData && !isLoadingDetail && isLoadedEnums) {
            handleDetailDataChange();
        }
    }, [detailData, isLoadingData, isLoadedEnums]);


    // Handler for structural change to network
    const handleDetailStructureChange = async () => {

        //Create detail data object 
        const newDetailData = {
            AccelerationID: vibrationObject.AccelerationRangeID,
            AnalysisTypeID: vibrationObject.AnalysisTypeID,
            DisplayName: vibrationObject.DisplayName,
            DriveType: driveType[vibrationObject.DriveTypeID],
            EnabledVibrationChannels: vibrationObject.EnabledVibrationChannels,
            FixedRPM: vibrationObject.FixedRPM,
            GearMeshValue: vibrationObject.GearMeshValue,
            HighRPM: vibrationObject.HighRPM,
            ID: vibrationObject.ID,
            IndividualNodeAlarms: vibrationObject.IndividualNodeAlarms,
            IsConnected: vibrationObject.IsConnected,
            LowRPM: vibrationObject.LowRPM,
            MachineType: machineType[vibrationObject.MachineTypeID],
            SamplingPeriod: vibrationObject.SamplingPeriod,
            Nodes: [],
            NodeNames: vibrationObject.DisplayName + " "
        };

        // Cycle through each DB node object
        vibrationObjectNodes.filter(n => n.ObjectID === vibrationObject.ID && n.NodeID != undefined).map((n) => {
            const node = {
                NodeID: n.NodeID,
                DisplayName: n.NodeDisplayName,
                OrientationID: n.OrientationID,
                PositionType: positionType.find(p => p.ID === n.PositionID),
                IsConnected: n.IsConnected,
                SignalInfo: {},
                BatteryInfo: {},
                TemperatureInfo: {},
                CheckinInfo: {},
                StatusInfo: {},
                AmplitudeData: []
            };
            newDetailData.Nodes.push(node);
        });

        // Update State
        setDetailData(newDetailData);
        setIsLoadingDetail(false);
        setIsLoadingData(true);

        //console.log("Updated Structure:")
        //console.log(newDetailData)
    }

    // Handler for newly received data
    const handleDetailDataChange = async () => {

        let diagnostics = nodeDiagnosticStatus.filter(s => s.ObjectID === detailData.ID);
        detailData.Diagnostics = [];
        if (diagnostics && diagnostics.length > 0) {
            detailData.Diagnostics = detailData.Diagnostics.concat(diagnostics);
        }

        let checkin = nodeCheckInStatus.filter(s => s.ObjectID === detailData.ID);
        detailData.CheckinInfo = checkin || { SecondsSinceCheckIn: null };

        let maxAlarm = nodeMaxAlarmValues.filter(a => a.ObjectID === detailData.ID);
        detailData.MaxAlarm = maxAlarm;

        // Cycle through each object node
        detailData.Nodes.map((n) => {
            n.SignalInfo = parseNodeSignal(n.NodeID);
            n.BatteryInfo = parseNodeBattery(n.NodeID);
            n.TemperatureInfo = parseNodeTemperature(n.NodeID);
            n.CheckinInfo = parseNodeCheckin(n.NodeID);
            n.StatusInfo = parseNodeStatus(n.NodeID, n.CheckinInfo);
            n.AmplitudeData = parseNodeVibrationData(n.NodeID, "Amplitude", n.OrientationID);
            //n.FrequencyData = parseNodeVibrationData(n.NodeID, "Frequency");
            //n.PhaseData = parseNodeVibrationData(n.NodeID, "Phase");
        });

        // Update state
        setDetailData(detailData);
        setIsLoadingData(false);

        //console.log("Updated Data:")
        //console.log(detailData);
    }

    // Handle a change in expanded rows
    const handleDetailRowChange = (expandedRows) => {
        setDetailRowsExpanded(expandedRows)
    }

    // Pull wireless signal diagnostic from  incoming DB information
    const parseNodeSignal = (nodeID) => {
        const info = nodeDiagnosticStatus.find(
            d => d.NodeID === nodeID
                && d.DataTypeDisplayName.includes('Signal Strength')
        );

        if (info && Object.keys(info).length > 0) {
            return info;
        } else {
            return {};
        }
    }

    // Pull battery diagnostic from incoming DB information
    const parseNodeBattery = (nodeID) => {
        const info = nodeDiagnosticStatus.find(
            d => d.NodeID === nodeID
                && d.NodeChannelDisplayName === 'Battery Voltage'
        );

        if (info && Object.keys(info).length > 0) {
            return info;
        } else {
            return {};
        }
    }

    // Pull temperature diagnostic from incoming DB information
    const parseNodeTemperature = (nodeID) => {
        const info = nodeDiagnosticStatus.find(
            d => d.NodeID === nodeID
                && d.NodeChannelDisplayName === 'Surface Temperature'
        );

        if (info && Object.keys(info).length > 0) {
            return info;
        } else {
            return {};
        }
    }

    // Pull node status from incoming DB information
    const parseNodeStatus = (nodeID, checkinInfo) => {
        const nodeStatus = nodeMaxAlarmValues.find(
            d => d.NodeID === nodeID
        );
        //console.log("NODE STATUS: " + JSON.stringify(nodeStatus));
        //console.log("CHECKIN INFO: " + JSON.stringify(checkinInfo));

        return GetSeverityStatusColorVIB(
            nodeStatus?.MaxAlarmValue,
            alarmStatusInfo,
            checkinInfo.SecondsSinceCheckIn
        );

    }


    // Pull node checkin info from incoming DB information
    const parseNodeCheckin = (nodeID) => {
        let checkin = nodeCheckInStatus.find(
            d => d.NodeID === nodeID
        );

        //console.log("CHECKIN PARSE: " + JSON.stringify(checkin));

        if (checkin && Object.keys(checkin).length > 0) {
            // Calculate the difference in seconds
            checkin.SecondsSinceCheckIn = CalculateTimeDifference(checkin.CheckInTimeZone);

            return checkin;

        } else {
            return { SecondsSinceCheckIn: null };
        }
    }

    const parseNodeVibrationData = (nodeID, dataType, orientationID) => {
        let nodeData = [];
        //console.log("nodeAmplitudeData " + JSON.stringify(nodeAmplitudeData));
        if (nodeAmplitudeData && nodeAmplitudeData.length > 0) {
            nodeAmplitudeData.map((d) => {
                if (d.NodeID == nodeID) {
                    const nodeDataPoint = {
                        NodeID: d.NodeID,
                        PositionID: d.PositionID,
                        OrientationID: orientationID,
                        AxisDisplayName: parseAxisDisplayName(orientationID, d.AxisID),
                        AxisID: d.AxisID,
                        BandID: d.BandID,
                        BandDisplayName: bands.find(b => b.ID === d.BandID).DisplayName,
                        IsEnabled: d.IsEnabled,
                        AlarmTypeID: d.AlarmTypeID,
                        ComputedValue: d.ComputedValue,
                        ComputedOn: d.ComputedOn,
                        CheckedInOn: d.CheckedInOn,
                        WarningValue: d.WarningValue,
                        DangerValue: d.DangerValue,
                        SecondsSinceCheckIn: d.CheckInTimeZone ?
                            CalculateTimeDifference(d.CheckInTimeZone) : null,
                        UnitTypeSymbol: d.UnitTypeSymbol,
                        WarningThresholdValue: d.WarningThresholdValue,
                        DangerThresholdValue: d.DangerThresholdValue,
                        DashboardDataID: d.DashboardDataID
                    };

                    nodeData.push(nodeDataPoint);
                }
            })
        }
        return nodeData;
    }

    const parseAxisDisplayName = (orientationID, axisID) => {
        const sensorAxis = axisOrientations.filter(x => x.OrientationID === orientationID && x.ID === axisID);
        let vibrationAxis = {};

        if (sensorAxis && sensorAxis.length > 0) {
            vibrationAxis = axisOrientations.find(x => x.OrientationID === orientationID && x.ID === sensorAxis.OrientationAxisID);
        }

        if ((sensorAxis && sensorAxis.length > 0) && (vibrationAxis && vibrationAxis.length > 0)) {
            return vibrationAxis.DisplayName + "(" + sensorAxis.DisplayName + ")";
        } else {
            return "";
        }


    }

    const handleBackButtonClick = () => {
        const { state } = location;
        if (state && state.from) {
            navigate(state.from);
        } else {
            navigate(`/Vibration/AssetDashboard/${sessionData.currentCompanyID}/${sessionData.userID}/${sessionData.viewAll}`);
        }
    }

    const pageStyle = {
        margin: "2%",
        flexGlow: 1
    }

    return (
        <Box sx={pageStyle}>
            {(isLoadingEnums || isLoadingObjectDetail) &&
                <StatusBackdrop open={(isLoadingEnums || isLoadingObjectDetail) && refresh === 0} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Asset Detail"
                    statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {(detailData && Object.keys(detailData).length > 0 && !props.hideBackToDashboard && !isLoadingDetail && !isLoadingData) &&
                <Grid container spacing={2} >
                    <Grid item xs={2} align="left" justifyContent="left" alignItems="left">
                        <Item><Button fullWidth onClick={handleBackButtonClick} variant="contained">Back To Dashboard</Button></Item>
                    </Grid>
                    <Grid item xs={12} align="center" justifyContent="center" alignItems="center">
                        <ObjectHeader
                            data={detailData}
                            alarmStatusInfo={alarmStatusInfo}
                        />
                    </Grid>
                </Grid>
            }
            {(!detailData && Object.keys(detailData).length === 0 && !isLoadingDetail && !isLoadingData) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <img src={EmptyAssetDetail} />
                    </Grid>
                </Grid>
            }
            {(detailData && Object.keys(detailData).length > 0 && !isLoadingDetail && !isLoadingData) &&
                <Grid item xs={12} paddingTop="12px">
                    <AssetDetailTableExpandable
                        data={detailData.Nodes}
                        count={detailData.Nodes.length}
                        alarmStatusInfo={alarmStatusInfo}
                        onRowExpansionChange={handleDetailRowChange}
                        detailRowsExpanded={detailRowsExpanded}
                    />
                </Grid>
            }
        </Box>
    );
}