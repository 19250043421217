import React, { useState, useContext } from 'react';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import MUIDataTable from 'mui-datatables';
import { useSession } from '../../SessionProvider';
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    Box,    
    Button,
} from '@mui/material';

const findCompanyName = (companies, companyID) => {
    const ID = Number(companyID);
    const company = companies.find(company => company.ID === ID);

    return company ? company.CompanyName : 'not found';
};

export default function AcctMgmtHomeCompanies(props) {
    const { sessionData, updateSessionData } = useSession();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const currentCompanyName = sessionData.currentCompanyID
        ? findCompanyName(props.userCompanies || [], sessionData.currentCompanyID) 
        : 'No Active Company';

    const defaultCompanyName = sessionData.defaultCompanyName
        ? findCompanyName(props.userCompanies || [], sessionData.defaultCompanyID)
        : 'No Default Company';

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns = [
        {
            name: 'CompanyName',
            label: 'Company Name',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'companyID',  // This is the field from your data
            label: 'Company ID',
            options: {
                filter: false,
                sort: false,
                display: 'excluded',  // This hides the column but keeps the data accessible
            },
        },
        {
            name: '',
            label: '',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const company = props.userCompanies[dataIndex];
                    return (
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                onClick={() => {
                                    props.setCompanyID(company.ID, company.CompanyName);
                                }}
                                sx={{ mr: 1 }}
                            >
                                Switch To
                            </Button>
                            <Button
                                onClick={() => {
                                    props.setDefaultCompanyID(company.ID, company.CompanyName);
                                }}
                            >
                                Set Default
                            </Button>
                        </div>
                    );
                },
            },
        },
    ];


    const options = {
        search: true,
        selectableRows: 'none',
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 15, 20],
        page: page,
        onChangePage: (currentPage) => {
            setPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
        },
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
    };

    const data = props.userCompanies.map((company) => ({
        CompanyName: company.CompanyName,
        Actions: '',
    }));

    return (
        <Card sx={{ minWidth: 275, boxShadow: 0 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }} aria-label="company">
                        <FactoryOutlinedIcon />
                    </Avatar>
                }
                title="Company"
                titleTypographyProps={{ variant: 'h5', component: 'div' }}
            />
            <CardContent>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Current Company:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {currentCompanyName}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Default Company:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {defaultCompanyName}
                    </Typography>
                </Box>
                <MUIDataTable
                    title="Companies"
                    data={data}
                    columns={columns}
                    options={options}
                />
            </CardContent>
        </Card>
    );
}
