import React from 'react';
import { Paper, Modal, Button, FormControl, RadioGroup, FormLabel, Radio, FormControlLabel, Grid } from '@mui/material';



export default function AssignModal(props) {
    //Shows a modal with a list of ControlGates the node can be assigned to
    return (
        <Modal
            open={props.assignModalOpen}
            key={props.id}
        >
            <Paper sx={props.modalStyle}>
                <FormControl>
                    <FormLabel>{`Select a parent for ${props.displayName}:`}</FormLabel>
                    <RadioGroup
                        value={props.selectedControlGate}
                        onChange={props.handleSelectedControlGateChange}
                    >
                        {props.controlgates.map((cg) => {
                            return (
                                <FormControlLabel key={cg.ObjectID} value={cg.ObjectID} control={<Radio />} label={cg.DisplayName} />
                            )
                        }) }
                    </RadioGroup>
                </FormControl>
                <br />
                <br />
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Button variant="contained" style={{float: "left"}} onClick={props.handleAssignModalClose}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "right" }} onClick={() => { return (props.handleAssignClick(props.id, props.selectedControlGate))}}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}