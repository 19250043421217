import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import AssetImage from './AssetImage';
import Grid from '@mui/material/Grid';
import { CalculateTimeDifference, GetSeverityStatusColorVIB } from '../Generic/MiscFunctions';



export default function ObjectHeader(props) {
    const [checkIn, setCheckIn] = useState(null);
    const [maxAlarmTypeID, setMaxAlarmTypeID] = useState(null);

    useEffect(() => {
        //console.log("MAX ALARM: " + JSON.stringify(props.data));
        const max = props.data.MaxAlarm.reduce((prev, current) => {
            // Check if either value is null, and return the non-null value or the greater value
            if (prev.MaxAlarmValue === null) return current;
            if (current.MaxAlarmValue === null) return prev;
            return (prev.MaxAlarmValue > current.MaxAlarmValue) ? prev : current;
        }, { MaxAlarmValue: null }).MaxAlarmValue;

        //console.log("Max: " + max);

        setMaxAlarmTypeID(max);
    }, [props.data.MaxAlarm]);

    useEffect(() => {
        const minSecondsSinceCheckIn = props.data.CheckinInfo.reduce((min, current) => {
            const secondsSinceCheckIn = CalculateTimeDifference(current.CheckInTimeZone);
            return Math.min(min, secondsSinceCheckIn);
        }, Infinity);

        setCheckIn(minSecondsSinceCheckIn);
    }, [props.data.CheckinInfo]);


    const DisplayNameRender = () => {

        let statusColor = GetSeverityStatusColorVIB(
            maxAlarmTypeID,
            props.alarmStatusInfo,
            checkIn);

        //console.log("STATUS COLOR: " + JSON.stringify(statusColor));

        return (
            <Typography variant="h6" sx={{ color: statusColor.StatusColor, alignContent: 'center' }}>
                <strong>{props.data.DisplayName}</strong>
            </Typography>
        )
    }

    const trafficLightRender = () => {      
        let statusColor = GetSeverityStatusColorVIB(
            maxAlarmTypeID,
            props.alarmStatusInfo,
            checkIn);
        return (
            <StatusTrafficLightDisplay
                Status={statusColor}
                alarmStatusInfo={props.alarmStatusInfo}
                keyName={                    
                    props.data.ID}
            />
        );

    }

    const rpmRender = () => {
        if (props.data.DriveType.ID == 0) {
            return (
                <Grid item xs={1}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>Fixed RPM</strong></Typography>
                    <Typography variant="h6" sx={{ alignContent: 'center' }}>{props.data.DriveType.RPM}</Typography>
                </Grid>
            );
        } else {
            return (
                <Grid item xs={1}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>RPM Range</strong></Typography>
                    <Typography variant="h6" sx={{ alignContent: 'center' }}>{props.data.DriveType.LowRPM + " - " + props.data.DriveType.HighRPM}</Typography>
                </Grid>
            );
        }
    }

    return (
        <>                        
            <Grid container spacing={2} sx={{ alignContent: 'center', display: { xs: 'none', md: 'flex' }, mt: 3 }}>               
                <Grid item xs={3}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>Asset Name</strong></Typography>
                    { DisplayNameRender() }
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>Asset Status</strong></Typography>
                    { trafficLightRender() }
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>Equipment Type</strong></Typography>
                    <Typography variant="h6" sx={{ alignContent: 'center' }}>{props.data.MachineType.DisplayName}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="h5" sx={{ alignContent: 'center', mt: 2, mb: 1 }}><strong>Drive Type</strong></Typography>
                    <Typography variant="h6" sx={{ alignContent: 'center' }}>{props.data.DriveType.DisplayName}</Typography>
                </Grid>
                { rpmRender() }             
                <Grid item xs={3}>
                    <AssetImage
                        sx={{ alignContent: 'center' }}
                        machineType={ props.data.MachineType.ID }
                    />
                </Grid>
            </Grid>
        </>
    );
}



