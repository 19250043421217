import React, { useState, useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import { TreeSelect } from 'primereact/treeselect';
import { Button } from 'primereact/button';
import { FormControl, Box, FormLabel, Tooltip, InputLabel } from '@mui/material';
import FieldHelperText from './FieldHelperText';
import StatusBackdrop from './StatusBackdrop';

export default function MultiSelectTreeField(props) {
    const { labelOverride } = props;
    const [field, meta, helpers] = useField(props);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            setSelectedKeys(props.values);
            //console.log("PROPS.VALUES: " + JSON.stringify(props.values));
            setLoading(false);
        }, [props.values]);

    const handleOnChange = (e) => {
        setSelectedKeys(e.value);
        helpers.setValue(e.value);
    };

    const expandAll = () => {
        let _expandedKeys = {};
        for (let node of props.options) {
            expandNode(node, _expandedKeys);
        }

        setExpandedKeys(_expandedKeys);
    };

    const collapseAll = () => {
        setExpandedKeys({});
    };

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;
            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    };

    const handleOnBlur = (e) => {
        if (!e || !e.target) {
            return;
        }

        if (field.onBlur && field.value !== null) {
            field.onBlur({
                ...e,
                target: {
                    ...e.target,
                    id: props.id || field.name,
                    name: field.name,
                },
            });
        }
    };


    const headerTemplate = (
        <div className="p-3 pb-0">
            <Button
                type="button"
                icon="pi pi-plus"
                onClick={expandAll}
                className="w-2rem h-2rem mr-2 p-button-outlined" />
            <Button
                type="button"
                icon="pi pi-minus"
                onClick={collapseAll}
                className="w-2rem h-2rem p-button-outlined" />
        </div>
    );

    const valueTemplate = (selectedNodes) => {
        return selectedNodes.length == 0 ?
            (<span key={-1} className="p-treeselect-token-label p-placeholder" data-pc-section="tokenlabel">{props.placeholder || "Select an Option"}</span>)
            : selectedNodes.filter((node) => node.children.length == 0).map((node) => (
                <Tooltip key={node.name} title={node[labelOverride] || node.label}>
                    <div className="p-treeselect-token" data-pc-section="token">
                        <span className="p-treeselect-token-label" data-pc-section="tokenlabel">{node.label}</span>
                    </div>
                </Tooltip>
            ));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <StatusBackdrop open={loading} />
                <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
                    <FormLabel id={"multi-tree-label-" + field.name}>{props.label}</FormLabel>
                    <TreeSelect
                        {...field}
                        id={field.name}
                        key={field.name}
                        inputId={field.name}
                        name={field.name}
                        value={selectedKeys}
                        options={props.options}
                        onToggle={(e) => setExpandedKeys(e.value)}
                        onChange={handleOnChange}
                        onBlur={(e) => handleOnBlur(e)}
                        expandedKeys={expandedKeys}
                        filter
                        metaKeySelection={false}
                        selectionMode="checkbox"
                        display="chip"
                        panelHeaderTemplate={headerTemplate}
                        valueTemplate={valueTemplate}
                        className={"w-full",
                        {
                            'p-invalid': (meta.touched && Boolean(meta.error)),

                        }}
                        variant="outlined"
                        placeholder={props.placeholder}
                    />
                </FormControl>
                {meta.touched && meta.error &&
                    <ErrorMessage name={field.name}>
                        {msg => <FieldHelperText message={msg} />}
                    </ErrorMessage>
                }
            </Box>
        </Box>
    );
};
