import React, { FunctionComponent } from "react";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

import GPTIcon from "./images/gpt.png";
import HumanIcon from "./images/human.png";

export function detectAddressing(text) {
    const regex = /\[([A-Za-z0-9-\s']+)\].*(.*)/;
    const match = text.match(regex);
    if (match == null) return null;

    const [, foo, rest] = match;
    const output = [foo, rest.trim()];
    return output;
}

const defaultMsgStyle = {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontSize: "16px",
};

function processMessage(message) {
    message = message.replaceAll("/r/n", "\n");
    const tokens = detectAddressing(message);
    if (tokens == null) {
        return (
            <span
                style={{
                    ...defaultMsgStyle,
                }}
            >
                {message}
            </span>
        );
    } else if (tokens[0] === "to USER" || tokens[0] === "USER") {
        return (
            <span
                style={{
                    ...defaultMsgStyle,
                }}
            >
                {message.slice(tokens[0].length + 3)}
            </span>
        );
    } else {
        return (
            <span
                style={{
                    ...defaultMsgStyle,
                    fontStyle: "italic",
                    color: "rgba(0,0,0,0.588)",
                }}
            >
                {message}
            </span>
        );
    }
}

export default function ChatMessageEntry(props) {
    return (
        <>
            <Divider variant="middle" sx={{ bgcolor: "gray" }} />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>{props.userID == null ? <Avatar src={GPTIcon} /> : <Avatar src={HumanIcon} />}</ListItemAvatar>
                <ListItemText primary={processMessage(props.content)} />
            </ListItem>
        </>
    );
}
