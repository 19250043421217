import { fetchRequest } from "../Generic/MiscFunctions";
import apiClient from "../../http-common";

export const fetchCompanyInfo = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/${companyID}`, "Failed to fetch Company Information");
};

export const fetchDashboards = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/getdashboards/${companyID}`, "Failed to fetch Company Dashboards");
};

export const fetchUsers = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter are required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/getcompanyusers/${companyID}`, "Failed to fetch Company Users");
};


export const fetchPendingInvites = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/getpendinginvites/${companyID}`, "Failed to fetch Pending Invites");
};

export const fetchTimeZones = async ({ queryKey }) => {
    const [_key] = queryKey;

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Company/gettimezone`, "Failed to fetch Time Zones");
};

export const createCompany = async (data) => {
    try {
        const response = await apiClient.post(`/Company/createcompany`, data);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error creating company:", error);
        throw new Error("Error creating company:");
    }
};

export const editCompany = async (data) => {
    try {
        const response = await apiClient.post(`/Company/editcompany`, data);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error editing company:", error);
        throw new Error("Error editing company:");
    }
};

export const removeUser = async (companyID) => {
    try {
        const response = await apiClient.delete(`/Company/removeuser/${companyID}`);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error deleting user:", error);
        throw new Error("Error deleting user:");
    }
};

export const sendInvite = async (data) => {
    try {
        const response = await apiClient.post(`/Company/sendinvite`, data);
        return response.data;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error sending invite:", error);
        throw new Error("Error sending invite:");
    }
};



      

