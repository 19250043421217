import React, { useState, useRef, useEffect } from "react";
import "../../../custom.css";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import ChatMessageEntry from "./ChatMessageEntry";
import ChatHistorySkeleton from "./ChatHistorySkeleton";

import Typography from "@mui/material/Typography";

import { queryChatDetail, executePrompt } from "./ForemanQueryFunctions";

export default function ForemanChatModal(props) {
    // Props has boolean chatOpen, and function handleClose
    const [recipeStateID, setRecipeStateID] = useState(props.recipeStateID);
    const [isWaiting, setWaiting] = useState(false);
    const [msgContent, setMsgContent] = useState("");
    const [messageList, setMessageList] = useState([]);

    const historyBottomRef = useRef(null);

    async function addMessage(content, userID) {
        if (content.trim() === "") return;
        setWaiting(true);
        setMsgContent("");

        try {
            executePrompt(recipeStateID, content, () => {
                fetchChatDetail(recipeStateID, 1);
                scrollToLatest();
                setWaiting(false);
            });
        } catch (ex) {
            //console.log("ERROR IN ADDING MESSAGE");
            //console.log(ex);
        }
    }
    async function fetchChatDetail(chatDefectID, userID, forceScroll = true) {
        updateChatHistory(await queryChatDetail(chatDefectID, userID), forceScroll);
    }

    function updateChatHistory(newList, forceScroll) {
        const previousList = messageList;
        if (newList === undefined) { return }
        setMessageList(newList);
        if (forceScroll || previousList.length !== newList.length) {
            scrollToLatest();
        }
    }

    function timerFunction() {
        if (isWaiting) {
            fetchChatDetail(recipeStateID, 1, false);
        }
    }

    useEffect(() => {
        const intervalId = setInterval(timerFunction, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (!props.chatOpen) return;

        scrollToLatest();
        fetchChatDetail(recipeStateID, 1);
    }, [props.chatOpen]);

    function scrollToLatest() {
        if (historyBottomRef.current == null) return;
        historyBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <Modal
            open={props.chatOpen}
            onClose={props.handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={props.chatOpen}>
                <div className="foreman-modal-container">
                    <Paper className="foreman-modal-paper" elevation={8}>
                        <div className="foreman-modal-top-bar">
                            <div className=""></div>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Stack className="foreman-modal-chat-history" spacing={0.75}>
                            {messageList.length === 0 ? <ChatHistorySkeleton /> : messageList.map((entry, index) => <ChatMessageEntry key={index} content={entry.content} userID={entry.userID} username={entry.username} figureID={entry.figureID} timestamp={entry.timestamp} />)}
                            <div ref={historyBottomRef} style={{ padding: "2px", paddingLeft: "0.85em" }}>
                                {isWaiting ? <Typography color="text.secondary">Waiting for GPT...</Typography> : <></>}
                            </div>
                        </Stack>

                        <Divider sx={{ bgcolor: "#000", marginBottom: "0.3em" }} />
                        <div style={{ display: "flex", padding: "0.4em" }}>
                            <TextField
                                multiline
                                value={msgContent}
                                onChange={(event) => {
                                    setMsgContent(event.target.value);
                                }}
                                maxRows={5}
                                variant="standard"
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="placeholder"
                            />
                            <Divider orientation="vertical" />
                            <IconButton
                                color="primary"
                                sx={{ p: "10px" }}
                                onClick={() => {
                                    addMessage(msgContent, 1);
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </div>
                    </Paper>
                </div>
            </Fade>
        </Modal>
    );
}
