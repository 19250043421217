import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Typography, Button, Alert, Box } from '@mui/material';

export default function AccountEULAMgmt({ userAccount, latestEulaVersion, userEULAAcceptance, handleAcceptEULAMgmt }) {
    const [isNewEULAAvailable, setIsNewEULAAvailable] = useState(false);
    const [mostRecentAcceptance, setMostRecentAcceptance] = useState(null);
    const [userHasAccepted, setUserHasAccepted] = useState(false);

    useEffect(() => {
        if (userEULAAcceptance && userEULAAcceptance.length > 0) {
            const allUserIDsNull = userEULAAcceptance.every(item => item.UserID === null);
            if (allUserIDsNull) {
                setMostRecentAcceptance(null);
            } else {
                const mostRecent = userEULAAcceptance.reduce((latest, current) => {
                    const latestDate = new Date(latest.AcceptedOn);
                    const currentDate = new Date(current.AcceptedOn);
                    return latestDate > currentDate ? latest : current;
                });
                setMostRecentAcceptance(mostRecent);
            }
        }
    }, [userEULAAcceptance]);

    useEffect(() => {
        const isNewEULAVersion = () => {
            const userVersionID = mostRecentAcceptance?.UserVersionID;
            const userID = mostRecentAcceptance?.UserID;
            const newVersionID = latestEulaVersion?.ID;

            if (newVersionID === userVersionID) {
                return false;
            }

            // Rule 1: If UserVersionID or UserID is null, return true
            if (userVersionID == null || userID == null) {
                return true;
            }

            // Rule 2: Check if latest EULA ID is greater than user accepted EULA ID
            if ((newVersionID ?? -1) > (userVersionID ?? -1)) {
                return true;
            }

            return false;
        };

        setIsNewEULAAvailable(isNewEULAVersion());
    }, [latestEulaVersion, userEULAAcceptance, mostRecentAcceptance]);


    const getFullEulaUrl = (eulaUri) => {
        return `${process.env.REACT_APP_EULA_BASE_URL}${eulaUri}`;
    };

    const handleAcceptEULAClick = () => {
        const userID = userAccount.UserID;
        const versionID = latestEulaVersion.ID;

        handleAcceptEULAMgmt(userID, versionID);
    };

    const checkAllUserIDsNull = (userEulaAcceptanceArray) => {
        return !userEulaAcceptanceArray?.every(item => item.UserID === null);
    };

    useEffect(() => {
        const result = checkAllUserIDsNull(userEULAAcceptance);
        setUserHasAccepted(result);
    }, [userAccount, userEULAAcceptance]);

    
    const renderContent = () => {

        if (mostRecentAcceptance?.AcceptedOn === null || !mostRecentAcceptance?.AcceptedOn) {
            return (
                <Card sx={{ minWidth: 275, boxShadow: 0 }}>
                    <CardContent>
                        <Alert severity="info" sx={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>
                            <Typography variant="body1" component="div" gutterBottom>
                                Please read through our End-User License Agreement (EULA) which can be found{' '}<br />
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="large"
                                    onClick={() => window.open(getFullEulaUrl(latestEulaVersion?.EulaUri), '_blank')}
                                >
                                    here
                                </Button>
                                <br /> This version of our EULA was published on{' '}
                                {new Date(latestEulaVersion?.AddedOn).toLocaleDateString()}.
                                <br />
                                <br />If you agree to the terms of the agreement, click the button below.
                                <br />By clicking the 'Accept EULA' button below, you implicitly agree to the terms of the license above.
                            </Typography>
                        </Alert>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <Button
                            color="primary"
                            size="large"
                            onClick={handleAcceptEULAClick}
                        >
                            Accept EULA
                        </Button>
                    </CardActions>
                </Card>
            );
        } else if ((mostRecentAcceptance?.AcceptedOn !== null) && isNewEULAAvailable) {
            return (
                <Card sx={{ minWidth: 275, boxShadow: 0 }}>
                    <CardContent>
                        <Alert severity="info" sx={{ textAlign: 'center', width: '75%', margin: '0 auto' }}>
                            <Typography variant="body1" component="div" gutterBottom>
                                You accepted a previous version of our EULA on{' '}
                                {new Date(mostRecentAcceptance?.AcceptedOn).toLocaleDateString()}.
                                <br />
                                If you wish to review the terms you agreed to previously, click here: {' '}
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="large"
                                    onClick={() => window.open(getFullEulaUrl(mostRecentAcceptance.EulaUri), '_blank')}
                                >
                                    Previous EULA Version
                                </Button>
                                <br />
                                <br />
                                <Typography variant="body1" component="div" gutterBottom>
                                    We have released a new version of our End-User License Agreement<br />
                                    Please read through our New End-User License Agreement (EULA), which can be found here:{' '}<br />
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="large"
                                        onClick={() => window.open(getFullEulaUrl(latestEulaVersion?.EulaUri), '_blank')}
                                    >
                                        Current EULA Version
                                    </Button>
                                    <br /> This version of our EULA was published on{' '}
                                    {new Date(latestEulaVersion?.AddedOn).toLocaleDateString()}.
                                    <br />
                                    <br />If you agree to the terms of the agreement, click the button below.
                                    <br />By clicking the 'Accept Current EULA' button below, you implicitly agree to the terms of the license above.
                                </Typography>
                            </Typography>
                        </Alert>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <Button
                            color="primary"
                            size="large"
                            onClick={handleAcceptEULAClick}
                        >
                            Accept Current EULA
                        </Button>
                    </CardActions>
                </Card>
            );
        } else if ((!isNewEULAAvailable)) {
            return (
                <Card sx={{ minWidth: 275, boxShadow: 0 }}>
                    <CardContent>
                        <Alert severity="info" sx={{ textAlign: 'center', width: '75%', margin: '0 auto' }}>
                            <Typography variant="body1" component="div" sx={{ fontSize: '1.2rem' }}>
                                You accepted our EULA on {new Date(mostRecentAcceptance?.AcceptedOn).toLocaleDateString()}.
                                <br />
                                If you wish to review the terms of the agreement, you can do so{' '}
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="large"
                                    onClick={() => window.open(getFullEulaUrl(mostRecentAcceptance?.EulaUri), '_blank')}
                                >
                                    here
                                </Button>
                            </Typography>
                        </Alert>
                    </CardContent>
                </Card>
            );
        }  

        return null;
    };


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ paddingTop: 4 }} // Add padding to the top
        >        
            {renderContent()}   
        </Box>
    );
}
