import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ListItemTextExtended } from 'mui-listitem-extended'
import { ListItemSecondaryAction } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import IconModalMenu from './IconModalMenu';


export default function DraggableListItem(props) {

    // Only render dropdown menu if menuItems exist
    const renderDropdownMenu = () => {
        if (props.menuItems.length > 0) {
            return (
                <ListItemSecondaryAction>
                    <IconModalMenu
                        menuTitle="Configure Node"
                        menuIcon={<MoreHoriz />}
                        menuItems={props.menuItems}
                        menuPreamble="Configure Node"
                        handleModalOpen={props.handleModalOpen}
                    />
                </ListItemSecondaryAction >
            )
        }
    }

    return (
        <Box sx={{
            mb: '5px',
            borderRadius: '15px',
            backgroundColor: 'lightgray',
            '&:hover': {
                backgroundColor: 'primary.dark',
                opacity: [0.9, 0.8, 0.7],
                borderColor: 'primary.dark'
            }
        }}>
            <Draggable key={props.draggableId} draggableId={props.draggableId} index={props.index} >
                {(provided) => (
                    <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={props.nodeID}
                        ContainerComponent="div"
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {props.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemTextExtended primary={props.secondary} secondary={props.primary} />
                        {renderDropdownMenu()}
                    </ListItem>
                )}
            </Draggable>
        </Box>
    );
};

