import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
    Card,
    CardContent,
} from '@mui/material';

export default function AccountCompanyInviteDataTable({ title, data, columns, options }) {

    return (
        <Card sx={{ minWidth: 275, boxShadow: 0 }}>            
            <CardContent>
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </CardContent>
        </Card>
    );
}