import React from 'react';
import { Paper, Grid } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import InputField from '../../Generic/InputField';
import AssetImage from '../AssetImage';

export default function VBTxConfigurationStep1(props) {

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <InputField
                        name={'assetName'}
                        label={'Asset Name'}
                        placeholder={'Asset Name'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors['assetName']}
                        width={'100%'}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'machineType'}
                        label={'Machine Type'}
                        data={props.machineTypes}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["machineType"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AssetImage machineType={props.formik?.values.machineType} />
                </Grid>
                
            </Grid>
        </Paper>
    )
}