import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FactoryIcon from '@mui/icons-material/Factory';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


export default function CompanyMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>            
            <Tooltip title="Company settings">
            <IconButton
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={open ? 'primary-company-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color="inherit"
            >
                <FactoryIcon sx={{ width: 32, height: 32, display: { xs: 'none', md: 'flex' } }} />
                <FactoryIcon sx={{ display: { xs: 'flex', md: 'none' } }} />
                <Typography sx={{ display: { xs: 'flex', md: 'none' }, pl: 2 }}>Company Account</Typography>
                </IconButton>                
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="primary-company-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}                
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    component={Link}
                    to={"/Company"}>
                    <ListItemIcon>
                        <FactoryIcon />
                    </ListItemIcon>
                        Company Account
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={"/UnitTypeSettings"}
                    state={{ isCompanyModeParam: true }}// Passing state
                >
                    <ListItemIcon>
                        <SquareFootIcon />
                    </ListItemIcon>
                        Company Unit Type Settings
                </MenuItem>                
                <Divider />
                <MenuItem
                    component={Link}
                    to={"/"}>
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>
                        Manage Users
                </MenuItem>                
            </Menu>
        </React.Fragment>
    );
}


