import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSession } from '../../SessionProvider';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import CustomHeader from '../Generic/CustomHeader';
import { CalculateTimeDifference, FormatDate } from '../Generic/MiscFunctions';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import EmptyList from '../images/EmptyAlarms.jpg';
import AlarmDashboardTableExpandable from "./AlarmDashboardTableExpandable";
import {
    fetchAlarmDashboard,
    fetchAlarmDashboardData,
    fetchCompanyEnums
} from './AlarmQueryFunctions';
import ToastAlarmDelete from './ToastAlarmDelete';

export default function AlarmDashboard() {
    const navigate = useNavigate();
    const { sessionData } = useSession();

    const userID = sessionData?.userID;
    const companyID = sessionData?.currentCompanyID; // Access companyID from session

    // State to handle recurring DB interactions
    const initalState = 0;
    const [refresh, setRefresh] = useState(initalState);
    const [errorState, setErrorState] = useState();
    const [alarmDashboard, setAlarmDashboard] = useState([]);
    const [alarmCurrentData, setAlarmCurrentData] = useState([]);
    const [showDeleteToast, setShowDeleteToast] = useState(false);
    const [selectedAlarmID, setSelectedAlarmID] = useState(null);
    const [selectedCompDSID, setSelectedCompDSID] = useState(null);
    const [companyInfo, setCompanyInfo] = useState({});

    // State to handle relevant enumerations
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);

    // State to control rendered dashboard objects
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    const [isLoadingDashData, setIsLoadingDashData] = useState(false);
    const [dashboardData, setDashboardData] = useState({ Channels: [], Alarms: [] });
    const [dashboardRowsExpanded, setDashboardRowsExpanded] = useState([]);
    const [dashboardRowsPerPage, setDashboardRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [sortedColumnName, setSortedColumnName] = useState("ChannelSeverityLevel");
    const [sortedColumnDirection, setSortedColumnDirection] = useState("desc");


    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 10000);
        return () => clearInterval(interval);
    }, [refresh]);

    const { isLoading, error, data } =
        useQuery(["companyInfo", sessionData.currentCompanyID], fetchCompanyInfo, {
        onSuccess: (data) => {
            setCompanyInfo(data);
        },
        onError: (error) => {
            // Handle the error here. For example, you can log the error or set an error state.
            //console.log("An error occurred while fetching company info:", error);
            setErrorState(error || "An unexpected error occurred.");
        },
        enabled: !!companyID
    });

    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", sessionData.currentCompanyID, sessionData.viewAll], fetchCompanyEnums, {
            onSuccess: (dataE) => {

                setAlarmStatusInfo(dataE.CompanyStdAlarmStatuses)
                setIsLoadedEnums(true)
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    // Fetch Standard Alarm Dashboard to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingStdAlarmDashboard, error: errorStdAlarmDashboard, data: dataA } =
        useQuery(["dashboard", sessionData.currentCompanyID, sessionData.userID, { refresh }], fetchAlarmDashboard, {
            onSuccess: (dataA) => {
                //console.log("Fresh Dashboard Information Retreived...");
                //console.log(dataA);

                // Handle structural changes
                if (JSON.stringify(dataA) !== JSON.stringify(alarmDashboard)) {
                    setIsLoadingDashboard(true);
                    setAlarmDashboard(dataA);

                }
            },            
            onError: (errorStdAlarmDashboard) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(errorStdAlarmDashboard));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorStdAlarmDashboard || "An unexpected error occurred.");
            }
        });

    // Fetch Standard Alarm Current Data to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingStdAlarmData, error: errorStdAlarmData, data: dataD } =
        useQuery(["dashboarddata", sessionData.currentCompanyID, sessionData.userID, { refresh }], fetchAlarmDashboardData, {
            onSuccess: (dataD) => {

                // Handle data changes
                if (JSON.stringify(dataD) !== JSON.stringify(alarmCurrentData)) {
                    setIsLoadingDashData(true);
                    setAlarmCurrentData(dataD);
                }
            },
            onError: (errorStdAlarmData) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(errorStdAlarmData));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorStdAlarmData || "An unexpected error occurred.");
            }
        });

    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingDashboard && isLoadedEnums) {
            //console.log("ALARM DASHBOARD STRUCTURE CHANGE USEEFFECT");
            handleDashboardStructureChange();
        }
    }, [alarmDashboard, alarmStatusInfo]);

    // Update Dashboard data when data changes
    useEffect(() => {
        if (isLoadingDashData && !isLoadingDashboard && isLoadedEnums) {
            //console.log("ALARM DASHBOARD DATA CHANGE USEEFFECT");
            handleDashboardDataChange();
        }
    }, [alarmCurrentData, isLoadingDashData, isLoadedEnums]);

    // Handler for structural change to dashboard
    const handleDashboardStructureChange = async () => {
        //console.log("handleDashboardStructureChange: newStdAlarmDashboard");
        //console.log(alarmDashboard);

        let newDashboard = [];
        alarmDashboard.map((nodeChannel) => {
            const obj = {
                CompanyName: nodeChannel.CompanyName,
                NodeID: nodeChannel.NodeID,
                NodeLocation: nodeChannel.NodeLocation,
                NodeDisplayName: nodeChannel.NodeDisplayName,
                NodeChannelDisplayName: nodeChannel.NodeChannelDisplayName,
                ProductionReadableSN: nodeChannel.ProductionReadableSN,
                IsConnected: nodeChannel.IsNodeConnected,
                NodeChannelID: nodeChannel.NodeChannelID,
                ComputedDataSourceID: nodeChannel.ComputedDataSourceID,
                ComputedValue: nodeChannel.ComputedValue,
                ComputedOnUTC: FormatDate(nodeChannel.ComputedOnUTC),
                ComputedOnTimeZone: FormatDate(nodeChannel.ComputedOnTimeZone),
                SensorUnitTypeSymbol: nodeChannel.SensorUnitTypeSymbol,
                SensorUnitTypeQuantityType: nodeChannel.SensorUnitTypeQuantityType,
                ConfigUnitTypeID: nodeChannel.ConfigUnitTypeID,
                ConfigQuantityType: nodeChannel.ConfigQuantityType,
                ConfigSymbol: nodeChannel.ConfigSymbol,
                ConvertedComputedValue: nodeChannel.ConvertedComputedValue,
                ChannelSeverityLevel: null,
                AlarmNames: "",
                AlarmTypes: [],
                SensorDataType: nodeChannel.SensorDataType,
                SecondsSinceCheckIn: nodeChannel.ComputedOnTimeZone ?
                    CalculateTimeDifference(nodeChannel.ComputedOnTimeZone) : null,
                VibrationAssetID: nodeChannel.VibrationAssetID,
                CheckInTimeZone: FormatDate(nodeChannel.CheckInTimeZone)


            }
            newDashboard.push(obj);
        });

        setDashboardData({ Channels: newDashboard, Alarms: dashboardData.Alarms });
        setIsLoadingDashboard(false);
        setIsLoadingDashData(true);

    }


    // Handler for newly received data
    const handleDashboardDataChange = async () => {

        let newAlarmCurrentData = [];
        //console.log(JSON.stringify(alarmCurrentData));
        alarmCurrentData.map((alarmData) => {
            const alarm = {
                NodeChannelID: alarmData.NodeChannelID,
                ComputedDataSourceID: alarmData.ComputedDataSourceID,
                IsConnected: alarmData.IsNodeConnected,
                AlarmID: alarmData.AlarmID,
                AlarmName: alarmData.AlarmName,
                ThresholdTriggerValue: alarmData.ThresholdTriggerValue,
                ThresholdOperatorDisplayName: alarmData.ThresholdOperatorDisplayName,
                ThresholdOperatorDisplaySymbol: alarmData.ThresholdOperatorDisplaySymbol,
                ThresholdUnitTypeQuantityType: alarmData.ThresholdUnitTypeQuantityType,
                ThresholdUnitTypeDisplayName: alarmData.ThresholdUnitTypeDisplayName,
                ThresholdUnitTypeSymbol: alarmData.ThresholdUnitTypeSymbol,
                SystemStatusID: alarmData.SystemStatusID,
                StatusSeverityLevel: alarmData.StatusSeverityLevel,
                StatusDisplayName: alarmData.StatusDisplayName,
                StatusHexColor: alarmData.StatusHexColor,
                AlarmType: alarmData.AlarmType,
                DefaultSensorQuantityType: alarmData.DefaultSensorQuantityType,
                DefaultSensorUnitTypeSymbol: alarmData.DefaultSensorUnitTypeSymbol,
                ComputedValue: alarmData.ComputedValue,
                ComputedOnUTC: alarmData.ComputedOnUTC,
                ComputedOnTimeZone: alarmData.ComputedOnTimeZone,
                DefaultSensorUnitTypeConvertedValue: alarmData.DefaultSensorUnitTypeConvertedValue,
                IsAlarmTriggered: alarmData.IsAlarmTriggered,
                AlarmDataType: alarmData.AlarmDataType,
                SecondsSinceCheckIn: alarmData.ComputedOnTimeZone ? CalculateTimeDifference(alarmData.ComputedOnTimeZone) : null,
                ObjectDefectSummary: alarmData.ObjectDefectSummary,
            }
            newAlarmCurrentData.push(alarm);
        });

        //console.log("Current Data: " + JSON.stringify(newAlarmCurrentData));
        let channels = dashboardData.Channels;

        if (channels && channels.length > 0) {
            for (let x = 0; x < channels.length; x++) {
                let channelAlarms = newAlarmCurrentData.filter(c => c.ComputedDataSourceID === channels[x].ComputedDataSourceID);
                if (channelAlarms && channelAlarms.length > 0) {

                    let triggered = channelAlarms.filter(ca => ca.IsAlarmTriggered === true);

                    if ((triggered && triggered.length > 0) && triggered.ComputedValue !== null) {
                        channels[x].ChannelSeverityLevel = Math.max.apply(Math, triggered.map((t) => { return t.StatusSeverityLevel }));
                    } else if (triggered.ComputedValue !== null) {
                        channels[x].ChannelSeverityLevel = alarmStatusInfo.find(i => i.StatusDisplayName === "Normal").Severity;
                    } else {
                        channels[x].ChannelSeverityLevel = alarmStatusInfo.find(i => i.StatusDisplayName === "Off").Severity;
                    }

                    for (let n = 0; n < channelAlarms.length; n++) {
                        channels[x].AlarmNames += channelAlarms[n].AlarmName + ", ";
                        channels[x].AlarmTypes.push(channelAlarms[n].AlarmType);
                    }
                }

            }
        }

        // Update state
        //console.log(JSON.stringify(newAlarmCurrentData));
        setDashboardData({ Channels: channels, Alarms: newAlarmCurrentData });
        setIsLoadingDashData(false);
    }

    const handleColumnSortChange = (changedColumn, direction) => {
        setSortedColumnName(changedColumn);
        setSortedColumnDirection(direction);
    };

    // Handle an Add Asset Button Click
    const handleAddButtonClick = () => {
        navigate(`/Alarm/Add/${sessionData.currentCompanyID}/${sessionData.userID}/${sessionData.viewAll}`);
    }

    const handleManageVibAlarmsClick = () => {
        navigate(`/Vibration/AssetDashboard/${sessionData.currentCompanyID}/${sessionData.userID}/${sessionData.viewAll}`);
    }

    const handleManageNotificationsClick = () => {
        let path = `https://hub.gracesense.com/AlarmNotification`;
        window.open(path);
    }

    // Handle a change in expanded rows
    const handleDashboardRowChange = (expandedRows) => {
        setDashboardRowsExpanded(expandedRows)
    }

    const handleRowsPerPageChange = (rowsPerPage) => {
        setDashboardRowsPerPage(rowsPerPage)
    }

    const handleCurrentPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    // Handle a change in search text
    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
        if (!searchText || searchText.length === 0) {
            setSearchText("");
        } else {
            setSearchText(searchText);
        }
    }

    const handleToastClose = () => {
        setShowDeleteToast(false); // Hide the ToastAlarmDelete component
    }

    const handleMoreMenuChange = (expandedMenu) => {
        setMoreMenuExpanded(expandedMenu)
    }

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }


    return (

        <Box sx={pageStyle}>
            <CustomHeader headerText={"Alarm Dashboard"} />
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                <Grid container spacing={1} columns={6}>
                    <Grid container item spacing={3}>
                        <Grid container item xs={12} align="right" justifyContent="right" alignItems="center">
                            <Button sx={{ mx: 2 }} onClick={handleManageVibAlarmsClick} variant="contained">Manage Vibration Alarms</Button>
                            {/*<Button sx={{ mx: 2 }} onClick={handleManageNotificationsClick} variant="contained">Manage Notifications</Button>*/}
                            <Button sx={{ ml: 2 }} onClick={handleAddButtonClick} variant="contained">Add Alarm</Button>
                        </Grid>
                    </Grid>
                </Grid>
            {(isLoadingEnums || isLoadingStdAlarmDashboard || isLoadingStdAlarmData)
                && <StatusBackdrop open={(isLoadingEnums || isLoadingStdAlarmDashboard
                    || isLoadingStdAlarmData) && refresh === 0} />}
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Alarm Dashboard"
                        statusCode={errorState.request.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }            
            {showDeleteToast &&
                <ToastAlarmDelete
                    alarmID={selectedAlarmID}
                    onToastClose={handleToastClose}
                    userID={sessionData.userID}
                    computedDataSourceID={selectedCompDSID}
                />
            }
                {(((!dashboardData["Channels"]) || (dashboardData["Channels"].length === 0)) && (!isLoadingDashboard) &&
                    ((!dashboardData["Alarms"]) || (dashboardData["Alarms"].length === 0)) && (!isLoadingDashData)) &&
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <img src={EmptyList} />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {(((dashboardData["Channels"]) && (dashboardData["Channels"].length > 0)) && (!isLoadingDashboard) &&
                ((dashboardData["Alarms"]) && (dashboardData["Alarms"].length > 0)) && (!isLoadingDashData)) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <AlarmDashboardTableExpandable
                            title={''}
                            channelsData={dashboardData["Channels"]}
                            alarmData={dashboardData["Alarms"]}
                            alarmStatusInfo={alarmStatusInfo}
                            companyID={sessionData.currentCompanyID}
                            userID={sessionData.userID}
                            refreshValue={refresh}
                            dashboardRowsExpanded={dashboardRowsExpanded}
                            onRowExpansionChange={handleDashboardRowChange}
                            dashboardRowsPerPage={dashboardRowsPerPage}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            dashboardPageNum={currentPage}
                            onChangeCurrentPage={handleCurrentPageChange}
                            searchText={searchText}
                            onChangeSearchText={handleSearchTextChange}
                            viewAll={sessionData.viewAll}
                            searchAlwaysOpen={true}
                            onDeleteFromTableExpandable={(alarmID, compDSID) => {
                                //event.preventDefault();
                                setSelectedAlarmID(alarmID);
                                setSelectedCompDSID(compDSID);
                                setShowDeleteToast(false);
                                setTimeout(() => setShowDeleteToast(true), 0);
                            }}
                            onToastCloseFromTableExpandable={handleToastClose} 
                            sortedColumnName={sortedColumnName}
                            sortedColumnDirection={sortedColumnDirection}
                            onColumnSortChange={handleColumnSortChange}
                        />
                    </Grid>
                </Grid>
            }
        </Box>

    );
}

