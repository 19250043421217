import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DefectElement from './DefectElement';

export default function DefectPeriodicTableRow({ tableElements, recipeID, rowNumber, activeDefects, handleElementChange }) {
    const isActiveElement = (item) => item.RecipeID === recipeID;

    // Filter elements by row and sort them by column number
    const elementsForRow = tableElements
        .filter(item => item.Row === rowNumber)
        .sort((a, b) => a.Column - b.Column);

    return (
        <Box sx={{ mt: 0.15, mb: 0.15 }}>
            <Grid container spacing={1}>
                {elementsForRow.map((element, index) => {
                    // Check if the RecipeID exists in the activeDefects array
                    const isInActiveDefects = activeDefects.some(defect => defect.RecipeID === element.RecipeID);

                    // Check for existence of properties or assign default values
                    const safeElement = {
                        RecipeID: element.RecipeID || null,
                        DisplayName: element.DisplayName || 'N/A',
                        Description: element.Description || 'No description available',
                        Symbol: element.Symbol || '',
                        MainSymbol: element.MainSymbol || '',
                        SubSymbol: element.SubSymbol || '',
                        FrequencyGrouping: element.FrequencyGrouping || 'Unknown',
                        ShortName: element.ShortName || 'No Short Name',
                        Icon: element.Icon || 'default-icon',
                        DominantDirectionalGrouping: element.DominantDirectionalGrouping || 'None',
                        Type: element.Type || 'Undefined',
                        Row: element.Row || 0,
                        Column: element.Column || 0,
                        IsActiveDefect: isInActiveDefects 
                    };

                    return (
                        <Grid item xs={1} key={index} sx={{ mr: 0.35 }}>
                            <DefectElement
                                elementData={safeElement}
                                isActiveElement={isActiveElement(safeElement)}
                                isPlaceHolder={safeElement["Type"]}
                                isActiveDefect={isInActiveDefects}
                                handleElementChange={handleElementChange}
                                activeDefects={activeDefects}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}
