import React, { useState } from 'react';
import { Opacity } from '../Generic/MiscFunctions';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'; //Green
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar'; //Yellow
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar'; //Red
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar'; //Grey
import Tooltip from '@mui/material/Tooltip';

import { useTheme } from "@mui/material/styles";

export default function CommunicationStatus(props) {
    const theme = useTheme();
    let opacity = (props.minCheckInTime && props.minCheckInTime < 86400) ? 1 : 0.6;
    let rssi = ((!props.checkinInfo) || (Object.keys(props.checkinInfo).length > 0)) ? props.value : -200;

    const [toolTipText] = useState(
        rssi > -200 ? rssi.toFixed(3) + " " + props.unitType : ""
    );

    if (rssi > -80) {
        return (
            <Tooltip title={toolTipText}>
                <SignalWifi4BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.success.main, opacity) }} />
            </Tooltip>
        );
    } else if (rssi > -90) {
        return (
            <Tooltip title={toolTipText}>
                <SignalWifi2BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.warning.main, opacity) }} />
            </Tooltip>
        );
    } else if (rssi <= -90) {
        return (
            <Tooltip title={toolTipText}>
                <SignalWifi1BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.error.main, opacity) }} />
            </Tooltip>
        );
    } else {
        return (
           <Tooltip title={toolTipText}>
                <SignalWifi0BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.grey.main, opacity) }} />
           </Tooltip>
        );
    }
}