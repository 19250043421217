import {
    StepConnector,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import theme from '../../theme'

export const styles = {
    stepper: {
        position: 'relative',
        width: '50%',
        margin: 'auto',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    paper: {
        elevation: 6,
        position: 'relative',
        width: '70%',
        margin: 'auto',
    },
    modalpaper: {
        position: 'absolute',
        top: '10%',
        left: '20%',
        width: '60%', 
        height: '80%',
        overflow: 'auto',
        padding: '16px', // Use static value
        backgroundColor: '#ffffff',
        boxShadow: '24px',
        outline: 'none',
    }
}

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(275deg, rgba(33,150,243,1) 35%, rgba(208,222,224,1) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(275deg, rgba(76,175,80,1) 35%, rgba(208,224,211,1) 100%))',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        borderRadius: 1,
    },
}));

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(rgba(33,150,243,1) 35%, rgba(33,150,243,1) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(rgba(76,175,80,1) 35%, rgba(76,175,80,1) 100%)',
    }),
}));