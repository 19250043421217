import React, { useState, useEffect, useRef, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './custom.css';
import theme from "./theme";

import AccountManagement from './components/Account/AccountManagement';
import AlarmDashboard from './components/Alarm/AlarmDashboard';
import AlarmEventDetails from './components/Alarm/AlarmEventDetails';
import AlarmStepperAddNew from './components/Alarm/AlarmStepperAddNew';
import AlarmStepperEdit from './components/Alarm/AlarmStepperEdit';
import { useAuth } from './components/Auth/AuthContext';
import Login from './components/Auth/Login';
import CompanyManagement from './components/Company/CompanyManagement';
import AssetIntegration from './components/Integration/AssetIntegration';
import FormComponent from './components/Integration/FormComponent';
import AppNavBar from './components/MainMenuBar/AppNavBar';
import APIManagement from './components/PublicAPI/APIManagement';
import AddAsset from './components/Vibration/AddAsset';
import AssetDashboard from './components/Vibration/AssetDashboard';
import AssetDetail from './components/Vibration/AssetDetail';
import DefectDashboard from './components/Vibration/DefectClassification/DefectDashboard';
import EditAsset from './components/Vibration/EditAsset';
import DefectDetail from './components/Vibration/DefectClassification/DefectDetail';
import ControlGateConfiguration from './components/Control/ControlGateConfiguration';
import NodeSettings from './components/NodeSettings/NodeSettings';
import { useSession } from './SessionProvider'; // Import the session hook

export const CompanyInfoContext = React.createContext();
import UnitTypeSettings from './components/Account/UnitTypeSetting';
import RecipientGroupPage from './components/RecipientGroup/RecipientGroupPage';

export default function App() {
    // This is the new version of MH 3.0 -- 07/31/2024
    const authInfo = useAuth();
    const { sessionData } = useSession(); // Access the session data
    const location = useLocation();
    const appRef = useRef(null);
    let scrollHeight = 0;

    useEffect(() => {
        const id = setInterval(() => {
            if (appRef.current) {
                const currentScrollHeight = appRef.current.scrollHeight;
                if (scrollHeight !== currentScrollHeight) {
                    scrollHeight = currentScrollHeight;
                    const payload = { url: window.location.href, height: currentScrollHeight };
                    window.parent.postMessage(payload, '*');
                }
            }
        }, 200);

        return () => clearInterval(id); // Cleanup on component unmount
    }, []); // Empty dependency array ensures this effect runs only once
  
    const PrivateRoute = ({ children }) => {
        return authInfo.isAuthenticated() && sessionData ? children : <Navigate to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} />;
    };

    return (
        <>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                <PrimeReactProvider>
                    <ThemeProvider theme={theme}>
                        <div ref={appRef}>
                                {/*Don't show AppNavBar for MH 2.5*/}
                            {process.env.REACT_APP_ENABLE_AUTH && <AppNavBar />}
                                <Routes>
                                {/* Redirect to login if not authenticated */}
                                <Route path="/" element={<Navigate to={authInfo.isAuthenticated() ? "/AssetDashboard" : "/login"} />} />

                                {/* Public Route */}
                                <Route path="/login" element={<Login />} />

                                {/* Private Routes */}
                                <Route path='/AssetDashboard' element={<PrivateRoute><AssetDashboard /></PrivateRoute>} />
                                <Route path='/Account' element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
                                <Route path='/Vibration/AssetDashboard' element={<PrivateRoute><AssetDashboard /></PrivateRoute>} />
                                <Route path='/Vibration/AddAsset' element={<PrivateRoute><AddAsset /></PrivateRoute>} />
                                <Route path='/Vibration/AddAsset/:objectID' element={<PrivateRoute><AddAsset /></PrivateRoute>} />
                                <Route path='/Vibration/EditAsset/:objectID' element={<PrivateRoute><EditAsset /></PrivateRoute>} />
                                <Route path='/Vibration/AssetDetail/:objectID' element={<PrivateRoute><AssetDetail /></PrivateRoute>} />
                                <Route path='/Alarm/AlarmDashboard' element={<PrivateRoute><AlarmDashboard /></PrivateRoute>} />
                                <Route path='/Alarm/Add' element={<PrivateRoute><AlarmStepperAddNew /></PrivateRoute>} />
                                <Route path='/Alarm/Edit/:alarmID/:cdsid/:crudType' element={<PrivateRoute><AlarmStepperEdit /></PrivateRoute>} />
                                <Route path='/Alarm/Clone/:alarmID/:cdsid/:crudType' element={<PrivateRoute><AlarmStepperEdit /></PrivateRoute>} />
                                <Route path='/Alarm/EventDetails/:userID/:urlFragment/:vib' element={<PrivateRoute><AlarmEventDetails /></PrivateRoute>} />
                                <Route path='/Alarm/EventDetails/view/:cdsid/:alarmID/:vib' element={<PrivateRoute><AlarmEventDetails /></PrivateRoute>} />
                                <Route path='/Defect/DefectDashboard' element={<PrivateRoute><DefectDashboard /></PrivateRoute>} />
                                <Route path='/Vendor/Form/:vendorID' element={<PrivateRoute><FormComponent /></PrivateRoute>} />
                                <Route path='/AssetIntegration/:vendorID' element={<PrivateRoute><AssetIntegration /></PrivateRoute>} />
                                <Route path='/Defect/Details/:vibrationObjectID/:defaultRecipeStateID' element={<PrivateRoute><DefectDetail /></PrivateRoute>} />
                                <Route path='/Defect/Details/:vibrationObjectID' element={<PrivateRoute><DefectDetail /></PrivateRoute>} />
                                <Route path='/Defect/Details' element={<PrivateRoute><DefectDetail /></PrivateRoute>} />
                                <Route path='/PublicAPI' element={<PrivateRoute><APIManagement /></PrivateRoute>} />
                                <Route path='/Company' element={<PrivateRoute><CompanyManagement /></PrivateRoute>} />
                                <Route path='/Control' element={<PrivateRoute><ControlGateConfiguration /></PrivateRoute>} />
                                <Route path='/UnitTypeSettings' element={<PrivateRoute><UnitTypeSettings /></PrivateRoute>} />
                                <Route path='/RecipientGroups' element={<PrivateRoute><RecipientGroupPage /></PrivateRoute>} />
                                <Route path='/NodeSettings' element={<PrivateRoute><NodeSettings /></PrivateRoute>} />
                                {/* Catch-all route for any unknown paths */}
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </div>
                        </ThemeProvider>
                    </PrimeReactProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </>
    );
}


