import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from '@mui/material';
import StatusBackdrop from "../../Generic/StatusBackdrop";
import StatusMessage from "../../Generic/StatusMessage";
import EmptyAsset from '../../images/EmptyAsset.png';
import DefectDashboardTableExpandable from "../DefectDashboardTableExpandable";
import {
    fetchDefectDashboard
} from '../VibrationQueryFunctions';
import CustomHeader from '../../Generic/CustomHeader';
import { useSession } from '../../../SessionProvider';


export default function DefectDashboard() {
    const navigate = useNavigate();

    const { sessionData } = useSession();
    const companyID = sessionData?.currentCompanyID;
    const userID = sessionData?.userID;

    // State to handle recurring DB interactions
    const [refresh, setRefresh] = useState(0);
    const [defectRecipes, setDefectRecipes] = useState([]);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    const [dashboardRowsExpanded, setDashboardRowsExpanded] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [errorState, setErrorState] = useState(null);
    
    // Fetch Vibration Objects to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingDefectRecipes, error: errorDefectRecipes, data: dataA, refetch: refetchDashboard } =
        useQuery(["dashboard", userID, companyID, { refresh }], fetchDefectDashboard, {
            onSuccess: (dataA) => {
                //console.log("Fresh Dashboard Information Retreived...")
                //console.log(dataA)
                if (JSON.stringify(dataA) !== JSON.stringify(defectRecipes)) {
                    setIsLoadingDashboard(true);
                    setDefectRecipes(dataA);
                }
            },
            onError: (errorDefectRecipes) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorDefectRecipes || "An unexpected error occurred.");
            }
        });

    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingDashboard) {
            handleDashboardChange();
        }
    }, [defectRecipes]);


    // Handler for structural change to network
    const handleDashboardChange = async () => {

        // Cycle through each DB vibration object
        let newDashboardData = [];
        defectRecipes.map((d) => {
            //console.log("v: " + JSON.stringify(v));
            //Creating dashboard data object per asset
            const obj = {
                CompanyID: d.sessionData.currentCompanyID,
                RecipeStateID: d.RecipeStateID,
                CompanyName: d.CompanyName,
                AssetName: d.AssetName,
                DefectName: d.DefectName,
                DefectDescription: d.DefectDescription,
                PercentComplete: d.PercentComplete,
                PercentBurnt: d.PercentBurnt,
                PercentUnknown: d.PercentUnknown,
                EvaluatedOn: d.EvaluatedOn,
                Likelihood: d.Likelihood
            };

            newDashboardData.push(obj);
        })

        // Update State
        setDashboardData(newDashboardData);
        setIsLoadingDashboard(false);
    }

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }

    return (
        <Box sx={pageStyle}>
            <CustomHeader headerText={"Defect Classification"} />
            <div style={{ padding: '10px' }}>
                {(isLoadingDashboard)
                    && <StatusBackdrop open={(isLoadingDashboard) && refresh === 0} />}
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Defect Dashboard"
                        statusCode={errorState.request.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    {(((!dashboardData) || (dashboardData.length === 0)) && (!isLoadingDashboard)) &&
                        <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <img src={EmptyAsset} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {((dashboardData) && (dashboardData.length > 0) && (!isLoadingDashboard)) &&
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                        <Grid item xs={12}>
                            <DefectDashboardTableExpandable
                                title={''}
                                data={defectRecipes}
                                userID={sessionData.currentCompanyID}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        </Box>

    );
}