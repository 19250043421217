import React from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Typography, Button } from '@mui/material';

export default function AcctMgmtHomeUser(props) {
    const userNameInitial = () => {
        return props.userAccount && props.userAccount.UserName
            ? Array.from(props.userAccount.UserName)[0]
            : '?';
    };

    const formatPhoneNumber = (countryCode, phoneNumber) => {
        if (!countryCode || !phoneNumber) {
            return 'Invalid phone number';
        }

        const trimmedCountryCode = countryCode.trim();

        const formatUSPhoneNumber = (number) => {
            const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]}-${match[3]}`;
            }
            return number;
        };

        if (trimmedCountryCode === '1') {
            return `+${trimmedCountryCode} ${formatUSPhoneNumber(phoneNumber)}`;
        } else {
            return `+${trimmedCountryCode} ${phoneNumber}`;
        }
    };

    return (
        <Card sx={{ minWidth: 275, boxShadow: 0 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }} aria-label="user">
                        {userNameInitial()}
                    </Avatar>
                }
                title={props.userAccount ? props.userAccount.UserName : 'Unknown User'}
                titleTypographyProps={{ variant: "h5", component: "div" }}
            />
            <CardContent>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Email:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {props.userAccount.Email}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Phone #:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {formatPhoneNumber(props.userAccount.PhoneCountryCode, props.userAccount.PhoneNumber)}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Email Notifications:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {props.userAccount.EmailOptOut ? 'Disabled' : 'Enabled'}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 1.5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1.5 }}>
                        Text Notifications:
                    </Typography>
                    <Typography sx={{ color: 'text.primary' }}>
                        {props.userAccount.TextOptOut ? 'Disabled' : 'Enabled'}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions>
                <Button                    
                    size="large"
                    onClick={() => props.setCurrentTab(3)} //Tab 3 is Edit Profile
                >
                    Edit Profile
                </Button>
            </CardActions>
        </Card>
    );
}
