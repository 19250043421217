import React from 'react';
import { Grid, Button, Tooltip, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";

export default function AssetAlignmentModalContent(props) {
    return (
        <React.Fragment>
            <RadioGroup
                aria-label="orientation"
                name="orientation"
                value={props.value}
                onChange={event => props.onChange(event, props.positionIdx)}
            >
                <Grid container rowSpacing={3} columnSpacing={3} columns={3}>
                    {['Top/Bottom Mount', 'Side Mount', 'Front/Back Mount'].map((item, index) => {
                        return (
                            <Grid key={index} item xs={1} md={1} lg={1} sx={{ textAlign: 'center' }}>
                                <Typography fontWeight={'bold'}>{item}</Typography>
                            </Grid>
                        )
                    })}
                    {props.enums.OrientationTypes.map((item, index) => {
                        return (
                            <Grid key={index} value={item.ID} item xs={1} md={1} lg={1}>
                                <Tooltip title={item.DisplayName}>
                                    <FormControlLabel id={`orientation${item.ID}`} value={item.ID}
                                        control={<Radio />}
                                        label={<img src={item.DisplayImageUrl} alt={item.DisplayName} style={{ height: '150px' }} />} />
                                </Tooltip>
                            </Grid>
                        )
                    })}
                </Grid>
            </RadioGroup>
            <Grid container spacing={2} columns={12} sx={{ marginTop: '4%' }}>
                {/* Close Button Grid Item */}
                <Grid item xs={2}>
                    <Button fullWidth onClick={props.closeModals} variant="contained">Close</Button>
                </Grid>
                <Grid item xs={10} />
            </Grid>
        </React.Fragment>
    )
}