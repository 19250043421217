import React, { useState } from 'react';
import { Typography, Grid, Paper, Button, Box, Modal, Divider, TextField, Select, MenuItem, FormControl } from '@mui/material';

export default function EditCompanyModal(props) {
    return (
        <Modal
            open={props.editCompanyModalOpen}
        >
            <Paper sx={props.modalStyle}>
                <Typography variant="h6">{props.save ? "Edit Company" : "Create Company"}</Typography>
                <Divider fullWidth/>
                <br />
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={3}>
                        <Typography align="center"><b>Name</b></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            label="New Company Name"
                            variant="outlined"
                            fullWidth
                            style={{ float: "right" }}
                            value={props.companyName}
                            onChange={props.handleCompanyNameChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="center"><b>Time Zone</b></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl fullWidth>
                            <Select
                                notched={true}
                                value={props.timeZone}
                                onChange={props.onTimeZoneChange}

                            >
                                {props.timeZonesList.map((timeZ, index) => {
                                    return (
                                        <MenuItem key={index} value={timeZ.Value}>{timeZ.Name}</MenuItem>
                                )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "left" }} onClick={props.handleCloseClick}>Close</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "right" }} onClick={props.handleSaveClick}>{props.saveLabel}</Button>
                    </Grid>
                </Grid>
                
            </Paper>
        </Modal>
    )
}

