export const clearBandFlags = () => {
    return ([{ checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }]);
}

export const setBasicBandFlags = () => {
    return ([{ checked: true }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }, { checked: false }]);
}

export const setLowFreqBandFlags = () => {
    return ([{ checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: false }, { checked: false }]);
}

export const setIntermediateBandFlags = () => {
    return ([{ checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }, { checked: true }]);
}

export const clearAnalysisFlags = () => {
    return ([{ checked: false }, { checked: false }, { checked: false }]);
}

export const setIntermediateAnalysisFlags = () => {
    return ([{ checked: true }, { checked: true }, { checked: true }]);
}

export const convertToMiscCheckboxes = (APIflags) => {
    let checkboxInfo = { x: clearAnalysisFlags(), y: clearAnalysisFlags(), z: clearAnalysisFlags() }
    checkboxInfo.x[0].checked = (APIflags & 0x0008) != 0;
    checkboxInfo.y[0].checked = (APIflags & 0x0010) != 0;
    checkboxInfo.z[0].checked = (APIflags & 0x0020) != 0;
    checkboxInfo.x[1].checked = (APIflags & 0x0040) != 0;
    checkboxInfo.y[1].checked = (APIflags & 0x0080) != 0;
    checkboxInfo.z[1].checked = (APIflags & 0x0100) != 0;
    checkboxInfo.x[2].checked = (APIflags & 0x0200) != 0;
    checkboxInfo.y[2].checked = (APIflags & 0x0400) != 0;
    checkboxInfo.z[2].checked = (APIflags & 0x0800) != 0;
    return checkboxInfo
}

export const convertToBandedCheckboxes = (APIflags) => {
    let checkboxInfo = clearBandFlags();
    checkboxInfo[0].checked = ((APIflags & 0x01) != 0);
    checkboxInfo[1].checked = ((APIflags & 0x02) != 0);
    checkboxInfo[2].checked = ((APIflags & 0x04) != 0);
    checkboxInfo[3].checked = ((APIflags & 0x08) != 0);
    checkboxInfo[4].checked = ((APIflags & 0x10) != 0);
    checkboxInfo[5].checked = ((APIflags & 0x20) != 0);
    checkboxInfo[6].checked = ((APIflags & 0x40) != 0);
    checkboxInfo[7].checked = ((APIflags & 0x80) != 0);
    return checkboxInfo
}

export const convertFromBandedCheckboxes = (checkboxInfo) => {
    let hexByte =
        (checkboxInfo[0].checked && 0x01) |
        (checkboxInfo[1].checked && 0x02) |
        (checkboxInfo[2].checked && 0x04) |
        (checkboxInfo[3].checked && 0x08) |
        (checkboxInfo[4].checked && 0x10) |
        (checkboxInfo[5].checked && 0x20) |
        (checkboxInfo[6].checked && 0x40) |
        (checkboxInfo[7].checked && 0x80)

    return hexByte
}

export const convertFromMiscCheckboxes = (xInfo, yInfo, zInfo) => {
    let hexShort =
        (xInfo[0].checked && 0x0008) |
        (yInfo[0].checked && 0x0010) |
        (zInfo[0].checked && 0x0020) |
        (xInfo[1].checked && 0x0040) |
        (yInfo[1].checked && 0x0080) |
        (zInfo[1].checked && 0x0100) |
        (xInfo[2].checked && 0x0200) |
        (yInfo[2].checked && 0x0400) |
        (zInfo[2].checked && 0x0800)

    return hexShort;
}