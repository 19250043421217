import React from 'react';
import FieldHelperText from './FieldHelperText';
import { useField, ErrorMessage } from 'formik';
import {
    FormLabel,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Chip,
    Box,
    FormControlLabel
} from '@mui/material';

export default function GroupSelectField(props) {
    const { label, data, ...rest } = props;
    const [field, meta, helpers] = useField(props);
    const { value: selectedValue } = field;

    const handleClick = (option) => {
        const newValue = [...selectedValue];
        const index = newValue.findIndex((v) => v.value === option.value);

        if (index > -1) {
            newValue.splice(index, 1);
        } else {
            newValue.push(option);
        }

        helpers.setValue(newValue);
    };


    const getOptionLabel = (value) => {
        for (const group of data) {
            for (const option of group.options) {
                if (option.value === value.value && option.group === value.group) {
                    return option.label;
                }
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <FormControl {...rest} fullWidth>
                    <FormLabel id={"select-label-" + field.name}>{props.label}</FormLabel>
                    <Select                                           
                        multiple
                        value={selectedValue ? selectedValue : []}
                        variant="outlined"                        
                        error={meta.touched && Boolean(meta.error)} 
                        renderValue={(selected) => (
                            selected.map((value, index) => (                                
                                <Chip key={value + index} label={getOptionLabel(value)} />
                            ))
                        )}
                        disabled={!props.enabled} 
                    >
                        {props.data.map((group, index) => (
                            <div key={index}>
                                <MenuItem disabled key={group.label}>{group.label}</MenuItem>
                                {group.options.map((option) => (
                                    <MenuItem key={group.label + option.value}
                                        value={option.value}
                                        onClick={() => handleClick(option)}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id={"checkbox-" + option.value}
                                                    name={"checkbox-" + option.value}
                                                    key={'checkBox-' + group.label + "-" + option.value}
                                                    checked={selectedValue.some((v) => v.value === option.value)}
                                                />
                                            }
                                            label={option.label}
                                        />
                                    </MenuItem>
                                ))}
                            </div>
                        ))}
                    </Select>

                </FormControl>
                <ErrorMessage name={field.name}>{msg => <FieldHelperText message={msg} />}</ErrorMessage>                               
            </Box>
        </Box>
    );
}
