import React, { useState, useEffect } from 'react';
import AccountCompanyInviteDataTable from './AccountCompanyInviteDataTable';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { FormatDate, RemoveTimeFromDate } from '../Generic/MiscFunctions';
import { useTheme } from "@mui/material/styles";
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    Box,
    Button,
} from '@mui/material';

export default function AccountCompanyInvitationsAccepted({ userInvites }) {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [acceptedInvites, setAcceptedInvites] = useState(userInvites);

    useEffect(() => {
        if (userInvites && Array.isArray(userInvites)) {
            const filteredData = userInvites.filter(record => record.AcceptedOn !== null && record.DeclinedOn === null);
            setAcceptedInvites(filteredData);
        } else {
            setAcceptedInvites([]);
        }
    }, [userInvites]);

    const columns = [
        {
            name: 'CompanyName',
            label: 'Company Name',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'InvitedOn',
            label: 'Date Invited',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object
                    return (
                        <Typography variant="body1" component="div" >
                            {RemoveTimeFromDate(FormatDate(invite[1]))}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'InviterUserName',
            label: 'Invited By',
            options: {
                filter: false,
                sort: true,
                ccustomBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object

                    return (
                        <Typography variant="body1" component="div" >
                            {invite?.InviterUsername}{' '}({invite?.InviterEmail})
                        </Typography>
                    );
                }
            },
        }, {
            name: 'AcceptedOn',
            label: 'Date Accepted',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const invite = tableMeta.rowData; // Assuming each rowData has the full data object

                    return (
                        <Typography variant="body1" component="div" >
                            {RemoveTimeFromDate(FormatDate(invite[3]))}
                        </Typography>
                    );
                }
            },
        },
    ]

    
    const options = {
        search: true,
        selectableRows: 'none',
        rowsPerPage: rowsPerPage,
        page: page,
        onChangePage: (currentPage) => {
            setPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
        },
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ paddingTop: 4 }} // Add padding to the top
        >              
            <Card sx={{ minWidth: 275, boxShadow: 0 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme.palette.success.main }} aria-label="company">
                            <ThumbUpIcon />
                        </Avatar>
                    }
                    title="Accepted Invitations"
                    titleTypographyProps={{ variant: 'h5', component: 'div' }}
                />
                <CardContent>
                    <AccountCompanyInviteDataTable
                        title=""
                        data={acceptedInvites}
                        columns={columns}
                        options={options}
                    />
                </CardContent>
            </Card>           
        </Box>
    );
}