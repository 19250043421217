import * as Yup from 'yup';
import { alarmFormModel } from './alarmFormModel';

const {
    formField: {
        alarmName,
        alarmSummary,
        thresholdDataTypeID,
        thresholdDataTypeName,
        assignedComputedDataSourceIDs,
        isCheckedTargets,
        thresholdComparisonID,
        thresholdTriggerValue,
        thresholdUnitTypeID,
        alarmSeverityID,
        // *** NOTIFICATIONS ARE NOT REQUIRED *** 
        sendNotification,
        notificationID,
        //notificationPeriodID,
        notificationRecipients,
        selectedRecipients
    }
} = alarmFormModel;

export const alarmAddValidationSchema = [
    Yup.object().shape({
        [alarmName.name]: Yup.string()
            .required(`${alarmName.requiredErrorMsg}`)
            .max(255, `${alarmName.invalidErrorMsg}`),
        [alarmSummary.name]: Yup.string().nullable(),
        [assignedComputedDataSourceIDs.name]: Yup.array().required(`${assignedComputedDataSourceIDs.requiredErrorMsg}`),
        [isCheckedTargets.name]: createIsCheckedTargetsSchema(),
        [thresholdDataTypeID.name]: Yup.number().required(`${thresholdDataTypeID.requiredErrorMsg}`),
        [thresholdDataTypeName.name]: Yup.string().nullable(),
    }),
    Yup.object().shape({
        [thresholdComparisonID.name]: Yup.number().required(`${thresholdComparisonID.requiredErrorMsg}`),
        [thresholdTriggerValue.name]: Yup.number()
            .required(`${thresholdTriggerValue.requiredErrorMsg}`)
            .min(-1000000, `${thresholdTriggerValue.minErrorMsg}`)
            .max(1000000, `${thresholdTriggerValue.maxErrorMsg}`),
        [thresholdUnitTypeID.name]: Yup.number().required(`${thresholdUnitTypeID.requiredErrorMsg}`),
        [alarmSeverityID.name]: Yup.number().required(`${alarmSeverityID.requiredErrorMsg}`),
        [sendNotification.name]: Yup.bool().required(`${sendNotification.requiredErrorMsg}`),
        [notificationID.name]: Yup.number().nullable(),
        //[notificationPeriodID.name]: Yup.number().nullable(),
        [notificationRecipients.name]: Yup.array().nullable() 
    })
];

function hasCheckedItem(obj) {
    if (!obj || typeof obj !== 'object') return false;

    for (const key in obj) {
        if (obj[key]?.checked) return true;
        if (hasCheckedItem(obj[key])) return true;
    }

    return false;
}

function createIsCheckedTargetsSchema() {
    return Yup.object()
        .required('Required')
        .test(
            'at-least-one-checked',
            'At least one item must be checked',
            hasCheckedItem
        );
}
