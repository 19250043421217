import React, { useRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from '../../SessionProvider';
import { Box, Grid, Button } from '@mui/material';
import { Toast } from 'primereact/toast';
import CustomHeader from '../Generic/CustomHeader';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import ConfirmMessage from '../Generic/ConfirmMessage';
import EmptyList from '../images/EmptyList.png';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import {
    fetchAvailableVibObjects,
    fetchIntegrationObjectList,
    postDisconnectIntegration,
    postSynchronizeAssetList,
    postAssetEvent,
    OnClickFunctionType
} from './IntegrationQueryFunctions';
import SyncAssetTableExpandable from "./SyncAssetTableExpandable";

export default function AssetIntegration() {
    const navigate = useNavigate();
    const { sessionData } = useSession();
    const toast = useRef(null);
    const companyID = sessionData?.currentCompanyID;
    const userID = sessionData?.userID;
    const viewAll = sessionData?.viewAll;

    const { vendorID } = useParams();

    // State to handle errors for DB interactions
    const [errorState, setErrorState] = useState();
    const [selections, setSelections] = useState([]);
    const [finishing, setFinishing] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessageObj, setConfirmMessageObj] = useState({
        message: {
            message: `Are you sure you want to unlink this asset?`,
            header: "Undo Confirmation"
        }
    });

    // State to hold object data
    const [assets, setAssets] = useState([]);
    const [loadInfo, setLoadInfo] = useState({});
    const [mhObjects, setMHObjects] = useState([]);

    const [rootFolderName] = useState("Ungrouped");
    const [notSyncedFolderName] = useState("Not Synced");

    // State to control rendered objects in data table
    const [tableRowsPerPage, setTableRowsPerPage] = useState(15);       // PageSize parameter for search
    const [currentPage, setCurrentPage] = useState(0);   // PageNumber parameter for search
    const [rowsExpanded, setRowsExpanded] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [rowNameOverrides, setRowNameOverrides] = useState([]);
    const [assetLoadingStates, setAssetLoadingStates] = useState([]);

    const syncMutation = useMutation(postSynchronizeAssetList);
    const assetEventMutation = useMutation(postAssetEvent);

    const toggleAssetSaving = (assetID, loading, value = true) => {
        setAssetLoadingStates(prevState => {
            const updatedAssetLoadingStates = { ...prevState };

            if (loading) {
                // If loading, add or update the property
                updatedAssetLoadingStates[assetID] = value;
            } else {
                // If not loading, remove the property
                delete updatedAssetLoadingStates[assetID];
            }

            return updatedAssetLoadingStates;
        });
    };

    const { isLoading, error, data } = useQuery(["companyInfoCMMS", companyID], fetchCompanyInfo, {
        onSuccess: (data) => {
            console.log(JSON.stringify(data));

            setCompanyInfo(data);
        },
        onError: (error) => setErrorState(error || "An unexpected error occurred."),
    });

    // Fetch assets to populate data grid from DB 
    const { isLoading: isQueryLoading, error: queryError, data: dataA, refetch  } =
        useQuery(["table", currentPage, tableRowsPerPage, vendorID, companyID], fetchIntegrationObjectList, {
            onSuccess: (dataA) => {
                setLoadInfo({
                    "PageNumber": dataA.PageNumber,
                    "PageSize": dataA.PageSize,
                    "TotalItems": dataA.TotalItems,
                    "TotalPages": dataA.TotalPages
                });
                const hierarchyData = createParentChildHierarchy(dataA.Items);
                setAssets(hierarchyData);
            },
            onError: (queryError) => setErrorState(queryError || "An unexpected error occurred.")
        });

    const { isLoading: isObjectsLoading, error: objectsError, data: dataO } =
        useQuery(["objects", companyID], fetchAvailableVibObjects, {
            onSuccess: (dataO) => setMHObjects(dataO),
            onError: (objectsError) => setErrorState(objectsError || "An unexpected error occurred.")
        });

    const createParentChildHierarchy = (items) => {
        const hierarchy = {};
        const newSelections = [];

        let ignoredItems = items.filter(i => i.IsIgnored === true);
        let allOthers = items.filter(i => i.IsIgnored === false);

        allOthers.forEach(item => {
            const pathParts = (item.HierarchyPath || '').split('/');
            pathParts.pop();
            const parentPath = pathParts.slice(1).join(' > ');

            if (!hierarchy[parentPath]) {
                hierarchy[parentPath] = [];
            }

            if (item.VibrationObjectID != null) {
                newSelections.push({ id: item.ID, action: item.VibrationObjectID });
            }

            if (item.IsIgnored) {
                newSelections.push({ id: item.ID, action: 0 });
            }

            hierarchy[parentPath].push(item);
        });

        ignoredItems.forEach(item => {
            const pathParts = (item.HierarchyPath || '').split('/');
            pathParts.pop();
            const parentPath = notSyncedFolderName;

            if (!hierarchy[parentPath]) {
                hierarchy[parentPath] = [];
            }

            if (item.VibrationObjectID != null) {
                newSelections.push({ id: item.ID, action: item.VibrationObjectID });
            }

            if (item.IsIgnored) {
                newSelections.push({ id: item.ID, action: 0 });
            }

            hierarchy[parentPath].push(item);
        });

        const sortedHierarchy = Object.entries(hierarchy).map(([parentPath, children]) => ({
            ParentPath: parentPath || rootFolderName,
            VendorParentPath: parentPath,
            Children: children,
            ChildrenDescriptions: children.map(child => `${child.Name} (${child.Code})`).join(', ')
        }));

        sortedHierarchy.sort((a, b) => {
            if (a.ParentPath === rootFolderName) return -1;
            if (b.ParentPath === rootFolderName) return 1;
            if (a.ParentPath === notSyncedFolderName) return -1;
            if (b.ParentPath === notSyncedFolderName) return 1;
            return a.ParentPath.localeCompare(b.ParentPath);
        });

        setSelections(newSelections);

        return sortedHierarchy;
    };

    useEffect(() => {
        const searchTextLower = searchText.toLowerCase();
        return assets.filter(item => {
            const matchesParent = item.ParentPath.toLowerCase().includes(searchTextLower);
            const matchesChildren = item.Children.some(child =>
                child.Name.toLowerCase().includes(searchTextLower) ||
                child.HierarchyPath.toLowerCase().includes(searchTextLower) ||
                child.Code.toLowerCase().includes(searchTextLower)
            );
            return matchesParent || matchesChildren;
        }, [searchText, assets])
    });

    const disconnectMutation = useMutation((payload) => postDisconnectIntegration(payload), {
        onSuccess: (result) => {
            const toastMessage = {
                severity: result.status === 200 ? 'success' : 'warn',
                summary: result.status === 200 ? 'Success Message' : 'Unexpected Status',
                detail: result.status === 200 ? 'Successfully removed integration' : `Unable to remove integration. Status: ${result.status} - ${result.statusText}`,
                life: result.status === 200 ? 5000 : 15000,
                position: 'center',
            };
            toast.current.show(toastMessage);
        },
        onError: () => {
            const toastMessage = {
                severity: 'error',
                summary: 'Failure Message',
                detail: 'An error occurred while attempting to remove integration.',
                life: 30000,
                position: 'center',
            };
            toast.current.show(toastMessage);
        }
    });

    const handleSynchronize = (asset) => {
        const payload = {
            CompanyID: companyID,
            UserID: userID,
            VendorID: vendorID,
            Assets: [asset]
        };

        return syncMutation.mutateAsync(payload, {
            onSuccess: (result) => {
                refetch();
                const status = result.ErrorCount === 0 ? 'success' : 'error';
                const toastMessage = {
                    severity: status,
                    summary: status.toUpperCase(),
                    detail: `Asset ${asset.Code}/${asset.Name} has been updated.` +
                        (result.ErrorCount === 0 ? '' : ', Failures: ' + result.ErrorCount),
                    life: 5000,
                    position: 'center'
                };
                toast.current.show(toastMessage);

                const updatedAsset = result.Assets.find(item => item.SynchronizationID === asset.SynchronizationID);

                if (updatedAsset) {
                    setAssets(prevData => {
                        const newData = [...prevData];

                        // Ensure the item is not already in the original parent path
                        const originalParentPath = (updatedAsset.HierarchyPath || '').split('/').slice(1, -1).join(' > ');
                        const parentFolder = newData.find(folder => folder.ParentPath === originalParentPath);

                        if (parentFolder) {
                            const existingChildIndex = parentFolder.Children.findIndex(child => child.ID === updatedAsset.ID);
                            if (existingChildIndex === -1) {
                                // Add the item to its parent path if it doesn't already exist
                                parentFolder.Children.push(updatedAsset);
                            } else {
                                // Update the existing item
                                parentFolder.Children[existingChildIndex] = updatedAsset;
                            }
                        } else {
                            // If the parent path doesn't exist, create it and add the item
                            newData.push({
                                ParentPath: originalParentPath,
                                Children: [updatedAsset]
                            });
                        }

                        // Remove the item from the "Not Synced" folder
                        const notSyncedFolderIndex = newData.findIndex(folder => folder.ParentPath === notSyncedFolderName);
                        if (notSyncedFolderIndex !== -1) {
                            const itemIndexInNotSynced = newData[notSyncedFolderIndex].Children.findIndex(child => child.ID === asset.ID);
                            if (itemIndexInNotSynced !== -1) {
                                newData[notSyncedFolderIndex].Children.splice(itemIndexInNotSynced, 1);

                                // If "Not Synced" folder is empty, remove the folder
                                if (newData[notSyncedFolderIndex].Children.length === 0) {
                                    newData.splice(notSyncedFolderIndex, 1);
                                }
                            }
                        }
                        return newData;
                    });
                }

                console.log(selections);

                const toRemove = selections.find(selection => selection.id === asset.ID);

                if (toRemove) {
                    selections.splice(selections.indexOf(toRemove), 1);
                }
                toggleAssetSaving(asset.ID, false);
                toggleAssetNameOverride(asset.ID, false);
            },
            onError: (error) => {
                toggleAssetSaving(asset.ID, false);
                toggleAssetNameOverride(asset.ID, false);

                const toastMessage = {
                    severity: 'error',
                    summary: 'Failure',
                    detail: `Asset ${asset.Code}/${asset.Name} could not be updated. Please try again.`,
                    life: 10000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            }
        });
    };

    const toggleAssetNameOverride = (assetID, newName) => {
        const updatedRowNameOverrides = [...rowNameOverrides];
        if (newName) {
            updatedRowNameOverrides[assetID] = newName;
        } else {
            delete updatedRowNameOverrides[assetID];
        }
        setRowNameOverrides(updatedRowNameOverrides);
    };

    const handleSyncAsset = async (asset) => {
        if (assetLoadingStates[asset.ID])
            return;

        toggleAssetSaving(asset.ID, true);

        return new Promise((resolve, reject) => {
            handleSynchronize(asset).then(updatedAsset => {
                resolve(updatedAsset);  // Return the updated asset
            }).catch(reject);
        });
    }

    const handleUndoClick = (asset, onClickFunction) => {
        if (assetLoadingStates[asset.ID])
            return;

        toggleAssetSaving(asset.ID, true);

        switch (onClickFunction) {
            case OnClickFunctionType.UndoDoNotSync:
                setConfirmMessageObj({
                    message: {
                        message: `Are you sure you want to remove the 'Do Not Sync' property from this asset?`,
                        header: "Undo Confirmation"
                    },
                    acceptFunction: async () => {
                        await handleSynchronize(asset)
                            .then(() => {
                                // TODO these don't seem to execute
                                toggleAssetSaving(asset.ID, false);
                                setShowConfirm(false);
                            });
                    },
                    rejectFunction: () => {
                        // TODO this don't seem to execute
                        setShowConfirm(false);
                        toggleAssetSaving(asset.ID, false);
                    },
                    showConfirmDialog: true
                });
                break;
            case OnClickFunctionType.Unsync:

                setConfirmMessageObj({
                    message: {
                        message: `Are you sure you want to unlink this asset?`,
                        header: "Undo Confirmation"
                    },
                    acceptFunction: async () => {
                        await handleSynchronize(asset)
                            .then(() => {
                                toggleAssetSaving(asset.ID, false);
                                setShowConfirm(false);
                            });
                    },
                    rejectFunction: () => {
                        setShowConfirm(false);
                        toggleAssetSaving(asset.ID, false);
                    },
                    showConfirmDialog: true
                });
                break;
            default:
                toggleAssetSaving(asset.ID, false);
                break;
        }

        setShowConfirm(true);
    };

    const vendorName = "";      // companyInfoCMMS.CMMS.VendorName;



    const handleTriggerTestEvent = (asset) => {
        if (assetLoadingStates[asset.ID])
            return;

        toggleAssetSaving(asset.ID, true, 'test');

        const payload = {
            assetHierarchyPath: asset.HierarchyPath,
            userID,
            assets: [
                {
                    VendorID: vendorID,
                    CompanyID: companyID,
                    VendorAssetID: asset.VendorAssetID,
                    CreatedDate: new Date().toISOString(),
                    Description: 'Test event triggered by User ID: ' + userID,
                }
            ]
        };
        assetEventMutation.mutate(payload, {
            onSuccess: (result) => {
                toggleAssetSaving(asset.ID, false);
                const toastMessage = {
                    severity: 'success',
                    summary: 'Test Event Triggered!',
                    detail: result.map(ev => `${vendorName} Asset: ${asset.Code}/${asset.Name} has a new Test Event ID: ${ev.ExternalEventID}`).join('<br/>'),
                    life: 10000,
                    sticky: true,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (error) => {
                toggleAssetSaving(asset.ID, false);
                const toastMessage = {
                    severity: 'error',
                    summary: 'Test Event Not Triggered.',
                    detail: Object.values(error.response.data.errors).flat().join('<br/>'),
                    life: 10000,
                    sticky: true,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            }
        });
    };


    //#region Remove Integration
    const handleDisconnect = () => {
        setConfirmMessageObj({
            message: {
                message: `Removing this integration will also remove the associated Assets, do you want to continue?`,
                header: "Disconnect Confirmation"
            },
            acceptFunction: () => handleConfirmAccept_Disconnect(),
            rejectFunction: () => handleConfirmReject_Disconnect(),
            showConfirmDialog: true
        });
        setShowConfirm(true);
    };

    const handleConfirmAccept_Disconnect = () => {
        setFinishing(true);
        const payload = {
            companyID: companyID,
            vendorID: vendorID,
            userID: userID,
        };
        disconnectMutation.mutateAsync(payload);
        setShowConfirm(false);
    };

    const handleConfirmReject_Disconnect = () => {
        setShowConfirm(false);
    };
    //#endregion

    const renderHeader = () => {
        if (viewAll) {
            return (
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" p="10px">
                    <CustomHeader headerText={"Asset Integration"} />
                    {viewAll && (
                        <Button sx={{ position: 'fixed', right: 0, marginRight: '25px' }} onClick={handleDisconnect}>
                            Remove Integration
                        </Button>
                    )}
                </Box>
            );
        } else {
            return <CustomHeader headerText={"Asset Integration"} />;
        }
    };


    const handleRowsPerPageChange = (rowsPerPage) => {
        setTableRowsPerPage(rowsPerPage)
    }

    const handleCurrentPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    // Handle a change in expanded rows
    const handleRowChange = (expandedRows) => {
        setRowsExpanded(expandedRows);
    }

    // Handle a change in search text
    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
        if (!searchText || searchText.length === 0) {
            setSearchText("");
        } else {
            setSearchText(searchText);
        }
    }

    const handleSelection = (id, action) => {
        setSelections(currentSelections => {
            console.log(currentSelections);
            // Find the index of the existing selection, if it exists
            const index = currentSelections.findIndex(selection => selection.id === id);

            if (index !== -1) {
                // If found, create a new array with the updated action for the found selection
                return [
                    ...currentSelections.slice(0, index),
                    { id, action },
                    ...currentSelections.slice(index + 1),
                ];
            } else {
                // If not found, add a new selection object to the array
                return [...currentSelections, { id, action }];
            }
        });
    };

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    };

    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            {renderHeader()}
            <ConfirmMessage
                message={confirmMessageObj.message}
                acceptFunction={confirmMessageObj.acceptFunction}
                rejectFunction={confirmMessageObj.rejectFunction}
                showConfirmDialog={showConfirm}
            />
            {/*<div style={{ padding: '10px' }}>*/}
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                {(isQueryLoading || isObjectsLoading || finishing) && <StatusBackdrop open />}
                {errorState && (
                    <StatusMessage
                        open
                        severity="error"
                        location="Asset Integration"
                        statusCode={errorState?.request?.status}
                        message={errorState.message}
                        error={errorState}
                    />
                )}
                {(!assets || assets.length === 0) && !isQueryLoading && !isObjectsLoading && (
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <img src={EmptyList} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {assets.length > 0 && !isQueryLoading && !isObjectsLoading && !finishing && (
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <SyncAssetTableExpandable
                            data={assets}
                            options={mhObjects}
                            loadInfo={loadInfo}
                            tableRowsPerPage={tableRowsPerPage}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            tablePageNum={currentPage}
                            onChangeCurrentPage={handleCurrentPageChange}
                            toast={toast}
                            selections={selections}
                            companyInfo={companyInfo}
                            handleUndoClick={handleUndoClick}
                            tableRowsExpanded={rowsExpanded}
                            onRowExpansionChange={handleRowChange}
                            onSearchTextChange={handleSearchTextChange}
                            handleTriggerTestEvent={handleTriggerTestEvent}
                            onSelectionChange={handleSelection}
                            handleSyncAsset={handleSyncAsset}
                            rowNameOverrides={rowNameOverrides}
                            toggleAssetNameOverride={toggleAssetNameOverride}
                            toggleAssetSaving={toggleAssetSaving}
                            assetLoadingStates={assetLoadingStates}
                        />
                    </Grid>
                </Grid>
            )}
            {/*</div>*/}
        </Box>
    );
}

