import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";


export default function AssetDropdownFormItem(props) {
    //console.log(props)
    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel id={props.id + 'label'} shrink={true}>{props.label}</InputLabel>
                <Select
                    notched={true}
                    required
                    labelId={props.id + 'label'}
                    id={props.id}
                    value={props.value}
                    label={props.label}
                    onChange={props.onChange}
                    disabled={props.disabled && props.disabled}
                >
                    {props.selectItems.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.ID}>
                                {item.DisplayName}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}