import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Toast } from 'primereact/toast';
import { useSession } from '../../SessionProvider';
import {
    fetchUserAccountMgmt,
    acceptEULA,
    respondToInvitation,
    fetchTimeZones,
    setDefaultCompany
} from './AccountMgmtQueryFunctions';
import StatusBackdrop from '../Generic/StatusBackdrop';
import StatusMessage from '../Generic/StatusMessage';
import CustomHeader from '../Generic/CustomHeader';
import AccountMgmtHome from './AccountMgmtHome';
//import AccountChangePassword from './AccountChangePassword';
import AccountCompanyInvites from './AccountCompanyInvites';
import AccountEULAMgmt from './AccountEULAMgmt';
import AccountEditProfile from './AccountEditProfile';
import { styled } from '@mui/system';
import {
    Tabs,
    Tab,
    Box,
} from '@mui/material';

// Using styled utility
const StyledTab = styled(Tab)`
  font-size: 18px;
  font-weight: bold;
`;

// Parent component for the Account Management components
export default function AccountManagement() { 
    const { sessionData, updateSessionData } = useSession();
    const toast = useRef(null);

    const userID = sessionData?.userID;
    const companyID = sessionData?.currentCompanyID; // Access companyID from session

    const [errorState, setErrorState] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [userAccount, setUserAccount] = useState({});
    const [latestEulaVersion, setLatestEulaVersion] = useState({});
    const [userEULAAcceptance, setUserEULAAcceptance] = useState([]);
    const [userInvites, setUserInvites] = useState([]);
    const [userCompanies, setUserCompanies] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [isThisDirty, setIsThisDirty] = useState(true);

    // Utility function to safely set isDirty state
    const safelySetIsDirty = (value) => {
        setIsThisDirty((prevValue) => prevValue !== value ? value : prevValue);
    };

    const handleTabChange = (event, newValue) => {  
        if (typeof newValue === 'number') {
            setCurrentTab(newValue);
        } else if (event !== null) {
            setCurrentTab(event);
        }
    };

    const handleSetAsDefault = (defaultCompanyID, defaultCompanyName) => {
        updateSessionData({
            defaultCompanyID: defaultCompanyID,
            defaultCompanyName: defaultCompanyName
        });
        doSetAsDefault.mutateAsync({ userID, defaultCompanyID, defaultCompanyName });
    };

    const handleSwitchToCompanyID = (companyID, companyName) => {
        updateSessionData({ currentCompanyID: companyID, currentCompanyName: companyName });
    };

    const handleIsDirty = (isThisDirty) => {
        safelySetIsDirty(isThisDirty);
    }

    const doSetAsDefault =
        useMutation(({ userID, defaultCompanyID, defaultCompanyName }) =>
            setDefaultCompany({ userID, defaultCompanyID, defaultCompanyName }), {
            onSuccess: (result, variables) => {
                const { defaultCompanyName } = variables;
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: `Success! ${defaultCompanyName} has been set as your default company.`,
                    life: 3000,
                    position: 'center'
                };               

                if (toast.current) {
                    toast.current.show(toastMessage);
                }

                safelySetIsDirty(true);
            },
            onError: (failError, variables) => {
                const { defaultCompanyName } = variables;
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: `An error occurred while attempting to set ${defaultCompanyName} as your default company.\n${failError.message}`,
                    life: 30000,
                    position: 'center',
                };              

                if (toast.current) {
                    toast.current.show(toastMessage);
                }

                safelySetIsDirty(false);
            }
        });

    const { isLoading: isLoadingTZ, error: errorTZ, data: dataB } =
        useQuery(["timezones"], fetchTimeZones, {
            onSuccess: (dataB) => {
                setTimeZones(dataB);
            },
            onError: (errorTZ) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorTZ || "An unexpected error occurred.");
            }
        });

    const { isLoading: isLoadingAcct, error: errorAcct, data: dataA } =
        useQuery(["userAccountMgmt", userID], fetchUserAccountMgmt, {
            onSuccess: (dataA) => {

                setUserAccount(dataA);
                setLatestEulaVersion(dataA.LatestEulaVersion);
                setUserEULAAcceptance(dataA.UserEulaAcceptance);
                setUserInvites(dataA.UserInvitations);
                setUserCompanies(dataA.UserCompanies);
                safelySetIsDirty(false);
            },
            onError: (errorAcct) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorAcct || "An unexpected error occurred.");
            }, 
            enabled: isThisDirty
        });

    const handleAcceptEULA = (userID, versionID) => {
        doAcceptEULA.mutateAsync({ userID, versionID });
    };

    const doAcceptEULA =
        useMutation(({ userID, versionID }) => acceptEULA({ userID, versionID }), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'End-User License Agreement (EULA) has been accepted.',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);  
                safelySetIsDirty(true);
            },
            onError: (failError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: `An error occurred while attempting to accept End-User License Agreement (EULA).\n${failError.message}`,
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
                safelySetIsDirty(false);
            }
        });

    const handleInvitationResponse = (userID, companyID, accept) => {
        doInviteResponse.mutateAsync({ userID, companyID, accept });
    };

    const doInviteResponse =
        useMutation(({ userID, companyID, accept }) => respondToInvitation({ userID, companyID, accept }), {
            onSuccess: (result) => {
                let toastMessage = {
                    severity: 'success',
                    summary: 'Success Message',
                    detail: 'Invitation Accepted.',
                    life: 3000,
                    position: 'center'
                };
                toast.current.show(toastMessage);
            },
            onError: (failError) => {
                let toastMessage = {
                    severity: 'error',
                    summary: 'Failure Message',
                    detail: `An error occurred while attempting to accept invitation.\n${failError.message}`,
                    life: 30000,
                    position: 'center',
                };
                toast.current.show(toastMessage);
            }
        });
    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }


    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            <CustomHeader headerText={"Account Management"} />
            {( isLoadingAcct)
                && <StatusBackdrop open={( isLoadingAcct)}/>}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Account Management"
                    statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }            
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                centered
                aria-label="Account Management"
            >
                <StyledTab label="Account Home" />
                <StyledTab label="EULA Management" />
                {/*<StyledTab label="Change Password" />*/}
                <StyledTab label="Company Invitations" />
                <StyledTab label="Edit Profile" />
            </Tabs>
            {currentTab === 0 &&
                <AccountMgmtHome
                    userAccount={userAccount}
                    currentCompany={companyID}
                    userCompanies={userCompanies}
                    handleTabChange={handleTabChange}
                    handleSetAsDefault={handleSetAsDefault}
                    handleSwitchToCompanyID={handleSwitchToCompanyID}
                />
            }
            {currentTab === 1 &&
                <AccountEULAMgmt
                    userAccount={userAccount}        
                    latestEulaVersion={latestEulaVersion}
                    userEULAAcceptance={userEULAAcceptance}
                    handleAcceptEULAMgmt={handleAcceptEULA}
                />
            }
            {/*{currentTab === 2 &&*/}
            {/*    <AccountChangePassword*/}
            {/*        userID={userID}*/}
            {/*    />*/}
            {/*}*/}
            {currentTab === 2 &&
                <AccountCompanyInvites
                    userInvites={userInvites}
                    handleInvitationResponse={handleInvitationResponse}                    
                />
            }
            {currentTab === 3 &&
                <AccountEditProfile
                    userAccount={userAccount}
                    timeZones={timeZones}
                    handleIsDirty={handleIsDirty}
                />
            }
        </Box>
    );
}