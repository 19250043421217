import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React from 'react';
import CustomPaper from '../Generic/CustomPaper';

export default function SelectField(props) {
    return (
        <Box
            sx={{
                width: props.width,
                maxWidth: '100%',
            }}
        >
            <Autocomplete
                disablePortal={false}
                id={"autocomplete-select-" + props.name}
                PaperComponent={CustomPaper}
                options={props.options}
                value={props.options.find(item => item.value === props.selectedValue) || null}
                onChange={(event, newValue) => {
                    props.onChange(newValue); // Directly pass newValue
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                        placeholder="Select an option..."
                        disabled={!props.enabled}
                    />
                )}
                getOptionKey={(option) => option.value} // Adjusted to match the structure
                getOptionLabel={(option) => option.label || ''} // Adjusted to match the structure
                isOptionEqualToValue={(option, value) => option.value === value.value} // Adjusted for consistency
            />
        </Box>
    );
}
