import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box, Button, Grid, Typography, ButtonGroup, Tooltip, TextField, InputLabel, Select, Snackbar, Alert } from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import {
    getRecipientGroups,
    createRecipientGroup,
    renameRecipientGroup,
    deleteRecipientGroup,
    getAvailableRecipients,
    addRecipient,
    modifyRecipient
} from './RecipientGroupQueryFunctions';
import MUIDataTable from "mui-datatables";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ActionModal from './ActionModal';
import BadgeIcon from '@mui/icons-material/Badge';
import { useSession } from '../../SessionProvider';


export default function RecipientGroupPage() {
    const { sessionData } = useSession();

    const createMutation = useMutation(createRecipientGroup);
    const renameMutation = useMutation(renameRecipientGroup);
    const deleteMutation = useMutation(deleteRecipientGroup);
    const getRecipientsMutation = useMutation(getAvailableRecipients);
    const addRecipientMutation = useMutation(addRecipient);
    const modifyRecipientMutation = useMutation(modifyRecipient);

    const toast = useRef(null);
    // Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const displaySnackbarMessage = (message, status) => {
        setSnackbarSeverity(status);
        setSnackBarMessage(message);
        setOpenSnackbar(true);
    };

    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedRecipientID, setSelectedRecipientID] = useState(0);
    const [availableRecipients, setAvailableRecipients] = useState([]);


    // Modal Action
    const ModalActionObjectTypes = {
        Group: 'Recipient Group',
        Recipient: 'Recipient'
    };
    const ModalActionTypes = {
        Read: 'List',
        Update: 'Change',
        Create: 'Create',
        Delete: 'Delete'
    };
    const ModalSaveActions = [
        { objectType: ModalActionObjectTypes.Group, actionType: ModalActionTypes.Create, mutation: createMutation },
        { objectType: ModalActionObjectTypes.Group, actionType: ModalActionTypes.Update, mutation: renameMutation },
        { objectType: ModalActionObjectTypes.Group, actionType: ModalActionTypes.Delete, mutation: deleteMutation },
        { objectType: ModalActionObjectTypes.Recipient, actionType: ModalActionTypes.Delete, mutation: modifyRecipientMutation },
        { objectType: ModalActionObjectTypes.Recipient, actionType: ModalActionTypes.Create, mutation: addRecipientMutation },
    ];
    const [modalOpen, setModalOpen] = useState(false);
    const [modalObjectType, setModalObjectType] = useState('Recipient Group');
    const [modalObjectValue, setModalObjectValue] = useState('');
    const [modalActionType, setModalActionType] = useState(null);
    const [modalSubmitButtonText, setModalSubmitButtonText] = useState(false);
    const [modalEditable, setModalEditable] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [modalOnClose, setModalOnClose] = useState(null);
    const [showAddEditModalContent, setShowAddEditModalContent] = useState(false);
    const [showRecipientsModalContent, setShowRecipientsModalContent] = useState(false);
    const [modalFormIsValid, setModalFormIsValid] = useState(false);

    const [errorState, setErrorState] = useState();
    const [isSaving, setIsSaving] = useState(false);

    const { isLoading: isGroupsLoading, refetch: refetchGroups } = useQuery(
        ["groups", sessionData.currentCompanyID],
        getRecipientGroups,
        {
            onSuccess: (data) => {
                setGroups(data.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)));

                if (selectedGroup) {
                    setSelectedGroup(data.find(r => r.RecipientGroupID == selectedGroup.RecipientGroupID));
                }
            },
            onError: (error) => {
                setErrorState(error);
            }
        });

    const handleCloseModalAction = () => {
        setModalOpen(false);
        setShowAddEditModalContent(false);
        setShowRecipientsModalContent(false);
        if (typeof modalOnClose == 'function') {
            modalOnClose();
            setModalOnClose(null);
        } else {
            setSelectedGroup({});
            setAvailableRecipients([]);
        }
    };

    const handleSubmitModalAction = (event, closeModalOnSuccess = true, objectTypeOverride = null, actionTypeOverride = null, payloadOverride = null, onSuccessOverride = null, toastMessageSuccessOverride = null, toastMessageFailOverride = null) => {
        event.preventDefault();
        var mutation = null;
        for (var i = 0; i < ModalSaveActions.length; i++) {
            var action = ModalSaveActions[i];
            if (action.objectType == (objectTypeOverride || modalObjectType) && action.actionType == (actionTypeOverride || modalActionType)) {
                mutation = action.mutation;
                break;
            }
        }
        if (mutation == null) {
            return;
        }

        setIsSaving(true);
        mutation.mutate(
            {
                queryKey: [`${modalObjectType}-${modalActionType}`, payloadOverride || modalPayload]
            },
            {
                onSuccess: () => {
                    setTimeout(refetchGroups, 250);
                    setIsSaving(false);
                    if (closeModalOnSuccess) {
                        handleCloseModalAction();
                    }
                    if (typeof onSuccessOverride == 'function') {
                        onSuccessOverride();
                    }
                    displaySnackbarMessage(toastMessageSuccessOverride || `${modalObjectType} was ${modalActionType.toLowerCase()}d.`, 'success');
                },
                onError: (error) => {
                    setIsSaving(false);
                    //console.error(error?.response?.data?.errors?.join(', '));
                    displaySnackbarMessage(toastMessageFailOverride || `${modalObjectType} update failed.`, 'error');
                }
            });
    };

    const getRecipients = (recipientGroupID) => {
        setIsSaving(true);
        getRecipientsMutation.mutate(
            {
                queryKey: [`${modalObjectType}-${modalActionType}`, recipientGroupID]
            },
            {
                onSuccess: (data) => {
                    setAvailableRecipients(data.sort((a, b) => a.Name.localeCompare(b.Name)));
                    setIsSaving(false);
                    setModalOpen(true);
                    setShowRecipientsModalContent(true);
                },
                onError: () => {
                    setIsSaving(false);
                    displaySnackbarMessage(`Failed to retreive recipients for group ${modalObjectValue}.`, 'error');
                }
            });
    };

    const renderGroups = () => {
        return (<>
            <Grid item xs={12} align="left">
                <FormControl variant={'standard'}>
                    <Box display="flex" alignItems="center">
                        <Button variant="contained" onClick={(event) => {
                            setModalActionType(ModalActionTypes.Create);
                            setModalObjectType(ModalActionObjectTypes.Group);
                            setModalObjectValue('');
                            setModalPayload({
                                CompanyID: sessionData.currentCompanyID,
                                DisplayName: modalObjectValue
                            });
                            setModalEditable(true);
                            setShowAddEditModalContent(true);
                            setModalFormIsValid(false);
                            setModalOpen(true);
                        }}>Create Group</Button>
                    </Box>
                </FormControl>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <MUIDataTable
                    title={""}
                    columns={[
                        {
                            name: "DisplayName",
                            label: "Group",
                            options: {
                                customHeadLabelRender: (columnMeta) => {
                                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                                }
                            }
                        },
                        {
                            name: "",
                            label: "Actions",
                            options: {
                                customHeadLabelRender: (columnMeta) => {
                                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                                },
                                customBodyRenderLite: (dataIndex) => {
                                    const item = groups[dataIndex];
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title={"Manage Recipients for the Group."} style={{ marginRight: '10px' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box sx={{ position: 'relative' }}>
                                                        <Button
                                                            startIcon={<PeopleAltIcon />}
                                                            onClick={() => {
                                                                setModalActionType(ModalActionTypes.Read);
                                                                setModalObjectType(ModalActionObjectTypes.Recipient);
                                                                setSelectedGroup(item);
                                                                getRecipients(item.RecipientGroupID);
                                                            }}
                                                        >({item.Recipients.length})</Button>
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title={"Rename the Group."} style={{ marginRight: '10px' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box sx={{ position: 'relative' }}>
                                                        <Button
                                                            startIcon={<EditIcon />}
                                                            onClick={() => {
                                                                setModalActionType(ModalActionTypes.Update);
                                                                setModalObjectType(ModalActionObjectTypes.Group);
                                                                setModalObjectValue(item.DisplayName);
                                                                setSelectedGroup(item);
                                                                setModalSubmitButtonText('Update');
                                                                setModalPayload({
                                                                    CompanyID: sessionData.currentCompanyID,
                                                                    RecipientGroupID: item.RecipientGroupID,
                                                                });
                                                                setModalEditable(true);
                                                                setShowAddEditModalContent(true);
                                                                setModalOpen(true);
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title={"Delete the Group."} style={{ marginRight: '10px' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box sx={{ position: 'relative' }}>
                                                        <Button
                                                            startIcon={<DeleteIcon />}
                                                            onClick={() => {
                                                                setModalActionType(ModalActionTypes.Delete);
                                                                setModalObjectType(ModalActionObjectTypes.Group);
                                                                setModalObjectValue(item.DisplayName);
                                                                setSelectedGroup(item);
                                                                setModalSubmitButtonText('Confirm');
                                                                setModalPayload({
                                                                    CompanyID: sessionData.currentCompanyID,
                                                                    RecipientGroupID: item.RecipientGroupID,
                                                                });
                                                                setModalEditable(false);
                                                                setShowAddEditModalContent(true);
                                                                setModalOpen(true);
                                                            }}
                                                        />
                                                    </Box>
                                                </Box >
                                            </Tooltip >
                                        </div>
                                    );
                                }
                            }
                        }]}
                    data={groups}
                    options={{
                        fixedHeader: true,
                        filter: false,
                        search: false,
                        print: false,
                        download: false,
                        viewColumns: false,
                        customToolbar: null,
                        responsive: 'vertical',
                        pagination: true,
                        selectableRowsHideCheckboxes: true,
                        enableNestedDataAccess: ".",
                    }}
                />
            </Grid>
        </>
        );
    };

    const renderRecipients = () => {
        return (<>
            <FormControl variant={'standard'} fullWidth>
                <Box display="flex" alignItems="center">
                    <InputLabel htmlFor={modalObjectType}>Add {modalObjectType} to <b>{selectedGroup.DisplayName}</b></InputLabel>
                    <Select
                        fullWidth
                        value={selectedRecipientID}
                        name={modalObjectType}
                        onChange={(event) => {
                            setSelectedRecipientID(event.target.value);
                        }}>
                        <MenuItem value={0} key={0}>
                            Select an Option
                        </MenuItem>
                        {availableRecipients.map((recipient) => (
                            <MenuItem value={recipient.ID} key={recipient.ID}>
                                {recipient.Name} | {recipient.Email} | {recipient.PhoneNumber}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button variant="contained" onClick={(event) => {
                        if (!selectedRecipientID)
                            return;
                        var selectedRecipient = availableRecipients.find(r => r.ID == selectedRecipientID);
                        handleSubmitModalAction(event, false, ModalActionObjectTypes.Recipient, ModalActionTypes.Create, {
                            CompanyID: sessionData.currentCompanyID,
                            RecipientGroupID: selectedGroup.RecipientGroupID,
                            UserID: selectedRecipientID,
                        }, () => {
                            setSelectedRecipientID(0);
                            getRecipients(selectedGroup.RecipientGroupID);
                        }, `Recipient ${selectedRecipient.Name} was added.`);
                    }}>Add</Button>
                </Box>
            </FormControl>
            <Divider />
            <br />
            <MUIDataTable
                title={""}
                columns={[
                    {
                        name: "Name",
                        label: "Name",
                        options: {
                            customHeadLabelRender: (columnMeta) => {
                                return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                            },
                            customBodyRenderLite: (dataIndex) => {
                                const item = selectedGroup.Recipients[dataIndex];
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {item.IsGraceEmployee && <BadgeIcon />} {item.Name}
                                    </div>
                                );
                            }
                        }
                    },
                    {
                        name: "Email",
                        label: "Email",
                        options: {
                            customHeadLabelRender: (columnMeta) => {
                                return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                            }
                        }
                    },
                    {
                        name: "PhoneNumber",
                        label: "Phone",
                        options: {
                            customHeadLabelRender: (columnMeta) => {
                                return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                            }
                        }
                    },
                    {
                        name: "",
                        label: "Actions",
                        options: {
                            customHeadLabelRender: (columnMeta) => {
                                return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                            },
                            customBodyRenderLite: (dataIndex) => {
                                const item = selectedGroup.Recipients[dataIndex];
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title={`Remove recipient from ${selectedGroup.DisplayName}.`} style={{ marginRight: '10px' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ position: 'relative' }}>
                                                    <Button
                                                        startIcon={<DeleteIcon />}
                                                        onClick={(event) => {
                                                            handleSubmitModalAction(event, false, ModalActionObjectTypes.Recipient, ModalActionTypes.Delete, {
                                                                CompanyID: sessionData.currentCompanyID,
                                                                RecipientGroupID: selectedGroup.RecipientGroupID,
                                                                UserID: item.ID,
                                                                Active: false,
                                                            }, () => {
                                                                getRecipients(selectedGroup.RecipientGroupID);
                                                            }, `Recipient ${item.Name} was removed.`);
                                                        }}
                                                    />
                                                </Box>
                                            </Box >
                                        </Tooltip >
                                    </div>
                                );
                            }
                        }
                    }]}
                data={selectedGroup.Recipients}
                options={{
                    fixedHeader: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    customToolbar: null,
                    responsive: 'vertical',
                    pagination: true,
                    selectableRowsHideCheckboxes: true,
                    enableNestedDataAccess: ".",
                }}
            />
        </>);
    };

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                key={'topright'}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Typography variant="h4" component="h2" className="header">
                Recipient Groups
            </Typography>
            <div style={{ padding: '10px' }}>
                <Grid container spacing={1} columns={12} align="center" justifyContent="center" alignItems="center">
                    {(isGroupsLoading || isSaving)
                        && <StatusBackdrop open={(isGroupsLoading || isSaving)} />}
                    {errorState &&
                        <StatusMessage
                            open={errorState}
                            severity="error"
                            location="Recipient Groups"
                            statusCode={errorState?.request?.status}
                            message={errorState.message}
                            error={errorState}
                        />
                    }
                    {groups && !isGroupsLoading && !isSaving && renderGroups()}
                    <ActionModal
                        modalOpen={modalOpen}
                        objectType={modalObjectType}
                        action={modalActionType}
                        hideSubmitButton={!showAddEditModalContent}
                        disableSubmitButton={!modalFormIsValid}
                        submitButtonText={modalSubmitButtonText}
                        handleCloseClick={handleCloseModalAction}
                        handleSubmitClick={(event) => {
                            handleSubmitModalAction(event);
                        }}>
                        {showAddEditModalContent && <TextField
                            label={"Name"}
                            variant="outlined"
                            fullWidth
                            style={{ float: "right" }}
                            value={modalObjectValue}
                            onChange={(event) => {
                                setModalFormIsValid(event.target.value && event.target.value.length >= 1);
                                setModalObjectValue(event.target.value);
                                setModalPayload((prevPayload) => ({
                                    ...prevPayload,
                                    DisplayName: event.target.value,
                                }));
                            }}
                            disabled={!modalEditable}
                        />}
                        {showRecipientsModalContent && renderRecipients()}
                    </ActionModal>
                </Grid>
            </div>
        </Box>
    );
}