import React from 'react';
import FieldHelperText from './FieldHelperText';
import { useField, ErrorMessage } from 'formik';
import {
    FormLabel,
    FormControl,
    Select,
    MenuItem,
    Box,
    InputLabel
} from '@mui/material';

export default function FormikSelectField(props) {
    const { label, data, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <FormControl {...rest} fullWidth>
                    <FormLabel id={"select-label-" + field.name}>{props.label}</FormLabel>
                    <Select {...field}
                        value={(selectedValue !== undefined && selectedValue !== null && data.length > 0) ? selectedValue : ''}
                        variant="outlined"
                        labelId={"select-label-" + field.name}
                        label={"Select " + props.label}   
                        error={meta.touched && Boolean(meta.error)} 
                        disabled={!props.enabled} 
                    >
                        {data.map((item, index) => (
                            <MenuItem key={index} value={item.value} >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>                    
                </FormControl>
                {meta.touched && meta.error &&
                    <ErrorMessage name={field.name}>
                        {msg => <FieldHelperText message={msg} />}
                    </ErrorMessage>
                }
            </Box>
        </Box>
    );
}
