import axios from "axios";
import authService from './components/Auth/AuthService';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY
    }
});

api.interceptors.request.use(
    config => {
        const user = authService.getCurrentUser();
        if (user && user.token) {
            config.headers['Authorization'] = 'Bearer ' + user.token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;