import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function ChatHistorySkeleton(props) {
    const skeletons = [];

    for (let i = 0; i < 8; i++) {
        skeletons.push(<div key={i} style={{
            display: "flex",
            width: "92.5%",
            justifyContent: "space-evenly",
            marginTop: "1em",
            alignItems: "center"
        }}>
            <Skeleton variant="circular" height="3em" width="3em" />
            <Skeleton key={i} variant="rounded" height={100} width={"80%"} />
        </div>);
    }

    return (
        <>
            {skeletons}
        </>
    );
}