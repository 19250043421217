import React, { useState } from 'react';
import {
    Typography, Grid, Stack, Box
} from '@mui/material';
import NumericInputField from '../../Generic/NumericInputField';
import { styled } from '@mui/material/styles';
import GearBox from "../images/GearBox.png";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));
export default function FormikGearBox(props) {

    const renderOutputSpeed = (shaftSpeed) => {
        const outputSpeed = Math.floor((shaftSpeed * props.formik?.values.inputToothCount) / props.formik?.values.outputToothCount)
        if (isNaN(outputSpeed) || !isFinite(outputSpeed)) {
            return shaftSpeed
        } else {
            return outputSpeed;
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                >
                    <Item><Typography align="center"><b>Gearbox Input</b></Typography></Item>
                    <Item>{props.formik?.values.driveType == 0 ?
                        <Typography align="center">{`Shaft Speed: ${props.formik?.values.shaftSpeed}`}</Typography>
                        :
                        <Typography align="center">{`Shaft Speed: ${props.formik?.values.shaftSpeedLoBound} - ${props.formik?.values.shaftSpeedHiBound}`}</Typography>
                    }</Item>
                    <Item>
                        <NumericInputField
                            name={'inputToothCount'}
                            label={'Input Tooth Count'}
                            placeholder={'Input Tooth Count'}
                            onBlur={props.formik?.onBlur}
                            form={props.formik}
                            step={1}
                            errors={props.formik?.errors['inputToothCount']}
                            width={'100%'}
                            digits={0}
                            preventempty="false"
                        />
                    </Item>
                </Stack>
                
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                <img
                    src={GearBox}
                    alt={'GearBox'}
                    style={{ maxHeight: '200px', maxWidth: '100%' }}>
                </img>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-start" alignItems="center">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                >
                    <Item><Typography align="center"><b>Gearbox Output</b></Typography></Item>
                    <Item>{props.formik?.values.driveType == 0 ?
                        <Typography align="center">{`Shaft Speed: ${renderOutputSpeed(props.formik?.values.shaftSpeed)}`}</Typography>
                        :
                        <Typography align="center">
                            {`Shaft Speed: ${renderOutputSpeed(props.formik?.values.shaftSpeedLoBound)} - ${renderOutputSpeed(props.formik?.values.shaftSpeedHiBound)}`}
                        </Typography>
                    }</Item>
                    <Item>
                        <NumericInputField
                            name={'outputToothCount'}
                            label={'Output Tooth Count'}
                            placeholder={'Output Tooth Count'}
                            onBlur={props.formik?.onBlur}
                            form={props.formik}
                            step={1}
                            errors={props.formik?.errors['outputToothCount']}
                            width={'100%'}
                            digits={0}
                            preventempty="false"
                        />
                    </Item>
                </Stack>

            </Grid>
        </Grid>
    )
}