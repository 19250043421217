import axios from "axios";

export async function queryChatDetail(chatDefectID, userID) {
    try {
        const response = await axios.get(`https://foremangpt-server.azurewebsites.net/api/chat?RecipeStateID=${chatDefectID}&UserID=${userID}`);

        const data = response.data;

        const chatHistory = data.map((entry) => ({
            userID: entry.userID,
            username: entry.username,
            timestamp: entry.timestamp,
            content: entry.content,
            figureID: entry.figureID,
        }));

        return chatHistory
    } catch (error) {
        //console.log("ERROR IN GETTING CHAT");
        //console.log(error);
    }
}

export async function executePrompt(defectID, messageContent, onCompletion) {
    const url = "https://foremangpt-server.azurewebsites.net/api/foreman/prompt";
    const headers = {
        "Content-Type": "application/json",
    };

    const data = {
        defectID: defectID,
        content: messageContent,
    };

    axios
        .post(url, data, { headers })
        .then((response) => {
            onCompletion();
        })
        .catch((error) => {
            console.error(error);
        });
}
