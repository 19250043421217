import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';


export default function ModalMenuItem(props) {
    return (
        <React.Fragment>
            <MenuItem onClick={props.handleModalOpen}>
                <ListItemIcon> {props.menuIcon} </ListItemIcon>
                {props.menuText}
            </MenuItem>
        </React.Fragment>
    )
}