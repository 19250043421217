import { postRequest } from "../Generic/MiscFunctions";
import { jwtDecode } from 'jwt-decode';

class AuthService {
    async login(username, password) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/login`;

        var postData = {
            username,
            password
        };

        try {
            const response = await postRequest(url, "Error logging in", postData);

            if (response.token) {
                const decodedToken = jwtDecode(response.token);
                localStorage.setItem('user', JSON.stringify(response.token));
                localStorage.setItem('decodedToken', JSON.stringify(decodedToken));
            }

            return response.token;
        } catch (error) {
            // Log the error or handle it as needed
            console.error("Error logging in:", error);
            throw new Error("Error logging in: " + JSON.stringify(error.message));
        }
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('decodedToken');
    }

    getCurrentUser() {
        const user = localStorage.getItem('user');
        return typeof user === 'string' ? JSON.parse(user) : null;
    }

    getDecodedToken() {
        const decodedToken = localStorage.getItem('decodedToken');
        return typeof decodedToken === 'string' ? JSON.parse(decodedToken) : null;
    }
}

export default new AuthService();
