import Highcharts from 'highcharts';
import React, { useEffect, useState } from 'react';
import {
    Chart,
    ColumnSeries,
    HighchartsChart,
    LineSeries, Title,
    Tooltip, withHighcharts,
    XAxis,
    YAxis
} from 'react-jsx-highcharts';

const ComboChart = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [referrer, setReferrer] = useState(null);

    const getReferrerBaseUrl = () => {
        //console.log(JSON.stringify(document.referrer));
        return document.referrer ? new URL(document.referrer).origin : null;
    }

    useEffect(() => {
        //console.log("ComboChart" + JSON.stringify(props));
    }, [props]);

    const handleColumnClick = (dashboardDataID) => {
        let url = getReferrerBaseUrl() + "/Data?NodeChannelIDs=" + dashboardDataID;
        window.open(url, '_blank');
        //sendMessageToParent(dashboardDataID);
    };

    const sendMessageToParent = (dashboardDataID) => {
        // Send a message to the parent window with the dashboardDataID
        //console.log("dashboardDataID " + JSON.stringify(dashboardDataID));
        window.parent.postMessage({ dashboardDataID: dashboardDataID }, '*');
    };

    return (
        <HighchartsChart plotOptions={{
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            // Call handleColumnClick with the dashboardDataID when a column is clicked
                            //console.log("this " + JSON.stringify(this.options));

                            handleColumnClick(this.options.dashboardDataID); // Access the custom field here
                        }
                    }
                }
            },
            line: {
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            }
        }} >
            <Chart borderWidth={0} />

            <Title style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.Title}</Title>

            <Tooltip valueSuffix={` ${props.unitType || 'ips'}`} valueDecimals={4} />

            <XAxis categories={props.ColSeries.map(x => x.name)} />

            <YAxis>
                <YAxis.Title>{`Vibration Amplitude (${props.unitType || 'ips'})`}</YAxis.Title>
                <ColumnSeries
                    name={"Amplitude"}
                    data={props.ColSeries}
                />
                <LineSeries
                    color="#F0AD4E"
                    step="true"
                    data={props.WarningSeries}
                />
                <LineSeries
                    color="#B30101"
                    step="true"
                    data={props.DangerSeries}
                />
            </YAxis>
        </HighchartsChart>
    );
};

export default withHighcharts(ComboChart, Highcharts);
