import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    createTheme,
    ThemeProvider,
    alpha,
    getContrastRatio,
} from '@mui/material/styles';

export default function CustomAlarmChip(props) {

    const colorBase = props.color;
    const colorMain = alpha(colorBase, 0.7);

    const theme = createTheme({
        palette: {
            myColor: {
                main: colorMain,
                light: alpha(colorBase, 0.5),
                dark: alpha(colorBase, 0.9),
                contrastText: getContrastRatio(colorMain, '#fff') > 4.5 ? '#fff' : '#111',
            },
        },
    });

    switch (props.statusSeverityLevel) {
        case 1: {
            return (
                <ThemeProvider theme={theme} >
                    <Stack direction="row" spacing={1}>
                        <Chip icon={<CheckCircleOutlineIcon />} label={props.statusDisplayName} color="myColor" />
                    </Stack>
                </ThemeProvider>
            );
        }
        case 2: {
            return (
                <ThemeProvider theme={theme} >
                    <Stack direction="row" spacing={1}>
                        <Chip icon={<ErrorOutlineIcon />} label={props.statusDisplayName} color="myColor" />
                    </Stack>
                </ThemeProvider>
            );
        }
        case 3: {
            return (
                <ThemeProvider theme={theme} >
                    <Stack direction="row" spacing={1}>
                        <Chip icon={<HighlightOffIcon />} label={props.statusDisplayName} color="myColor" />
                    </Stack>
                </ThemeProvider>
            );
        }
        default: {
            return (
                <ThemeProvider theme={theme} >
                    <Stack direction="row" spacing={1}>
                        <Chip icon={<CheckCircleOutlineIcon />} label={props.statusDisplayName} color="myColor" />
                    </Stack>
                </ThemeProvider>
            );
        }
    }
}