import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { Card, Stack, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useQuery } from "react-query";
import StatusMessage from '../../Generic/StatusMessage';
import AssetDetail from '../AssetDetail';
import { fetchDefectDetail } from '../VibrationQueryFunctions';

export default function DefectInformation(props) {
    const [refresh, setRefresh] = useState(0);
    const [errorState, setErrorState] = useState();
    const [selecteDefect, setSelectedDefect] = useState(props.data.item);
    const [ingredients, setIngredients] = useState([]);
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    // Fetch Vibration Objects to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingDefectDetail, error: errorDefectDetail, data: dataA, refetch: refetchDetail } =
        useQuery(["detail", selecteDefect.RecipeStateID, { refresh }], fetchDefectDetail, {
            onSuccess: (dataA) => {
                if (JSON.stringify(dataA) !== JSON.stringify(ingredients)) {
                    setIngredients(dataA);
                }
                setIsLoadingDetail(false);
            },
            onError: (errorDefectDetail) => {
                // Handle the error here. For example, you can log the error or set an error state.
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorDefectDetail || "An unexpected error occurred.");
                setIsLoadingDetail(false);
            },
            enabled: !!selecteDefect.RecipeStateID
        });

    const ExplanationHTML = () => {
        return (

            <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    border: '1px solid #000',
                    borderRadius: '16px'
                }}>
                    <Tooltip title={`Positive Indicators: ${selecteDefect.PercentComplete}%`} arrow>
                        <Box sx={{
                            height: `${selecteDefect.PercentComplete}%`,
                            backgroundColor: theme.palette.error.main,
                            filter: 'brightness(75%)',
                            //border: `${'2px solid #000'}`,
                            width: 10,

                        }} />
                    </Tooltip>
                    <Tooltip title={`Missing Indicators: ${selecteDefect.PercentUnknown}%`} arrow>
                        <Box sx={{
                            height: `${selecteDefect.PercentUnknown}%`,
                            backgroundColor: theme.palette.grey.main,
                            //border: `${title === unknownsTitle ? '2px solid #000' : ""}`,
                            width: 10,
                        }} />
                    </Tooltip>
                    <Tooltip title={`Counter Indicators: ${selecteDefect.PercentBurnt}%`} arrow>
                        <Box sx={{
                            height: `${selecteDefect.PercentBurnt}%`,
                            backgroundColor: alpha(theme.palette.error.main, 0.5),
                            //border: `${title === burntsTitle ? '2px solid #000' : ""}`,
                            width: 10,
                        }} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', ml: 1 }}>
                    {ingredients
                        .sort((a, b) => {
                            // Sort by PercentComplete descending (records with 100 Complete first)
                            if (a.PercentComplete === 100 && b.PercentComplete !== 100) {
                                return -1;
                            }
                            if (a.PercentComplete !== 100 && b.PercentComplete === 100) {
                                return 1;
                            }

                            // Sort by PercentUnknown descending (records with 100 Unknown next)
                            if (a.PercentUnknown === 100 && b.PercentUnknown !== 100) {
                                return -1;
                            }
                            if (a.PercentUnknown !== 100 && b.PercentUnknown === 100) {
                                return 1;
                            }

                            // Sort by PercentBurnt descending (records with 100 Burnt last)
                            if (a.PercentBurnt === 100 && b.PercentBurnt !== 100) {
                                return -1;
                            }
                            if (a.PercentBurnt !== 100 && b.PercentBurnt === 100) {
                                return 1;
                            }
                            return 0;
                        })
                        .map(ingredient => (
                            <Tooltip title={`ID: ${ingredient.IngredientID} | Name: ${ingredient.IngredientName} | Complete: ${ingredient.PercentComplete} | Missing: ${ingredient.PercentUnknown} | Absent: ${ingredient.PercentBurnt}`} key={ingredient.IngredientID} arrow>
                                <Typography
                                    variant={isMobile ? 'body2' : 'subtitle1'}
                                    component="div">
                                    {ingredient.PercentComplete >= 100 && <CheckBoxIcon sx={{ color: theme.palette.error.main, filter: 'brightness(75%)' }} />}
                                    {ingredient.PercentUnknown >= 100 && <HelpCenterIcon sx={{ color: theme.palette.grey.main }} />}
                                    {ingredient.PercentBurnt >= 100 && <DisabledByDefaultIcon sx={{ color: theme.palette.error.main, opacity: .5 }} />}
                                    {ingredient.IngredientDescription}
                                </Typography>
                            </Tooltip>
                        ))}
                </Box>
            </Box>

        )
    }

    return (
        <React.Fragment>
            {(isLoadingDetail) && <div>Loading Detail From Database...</div>}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    justifyContent: 'space-around',
                    flexDirection: isMobile ? 'column' : 'row',
                }}>
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Add Asset"
                        statusCode={errorState?.request?.status}
                        message={errorState?.message}
                        error={errorState}
                    />
                }
                <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '95%' }}>
                    <Stack direction="column" sx={{ margin: '20px' }}>
                        {!isLoadingDetail && ExplanationHTML()}
                    </Stack>
                </Card>
                {/*<ForemanChatInitiatorCard recipeStateID={props.recipeStateID} />*/}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    justifyContent: 'space-around',
                    flexDirection: isMobile ? 'column' : 'row',
                }}>
                {selecteDefect.VibrationObjectID &&
                    <AssetDetail
                        companyID={selecteDefect.CompanyID}
                        objectID={selecteDefect.VibrationObjectID}
                        userID={props.userID}
                        hideBackToDashboard={true}
                    />
                }
            </Box>
        </React.Fragment>
    );
}


