import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Button, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import { Toast } from 'primereact/toast';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { useSession } from '../../SessionProvider';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import { useLocation } from 'react-router-dom';
import { fetchCompanyUnitTypeSettings, fetchUserUnitTypeSettings, postUnitTypeSettings } from './AccountMgmtQueryFunctions';

export default function UnitTypeSettings() {
    const mutation = useMutation(postUnitTypeSettings);
    const toast = useRef(null);
    const { sessionData } = useSession();
    const location = useLocation();
    const { isCompanyModeParam } = location.state || {}; // Extract the passed prop

    const userID = sessionData?.userID;
    const companyID = sessionData?.currentCompanyID; 
    const companyName = sessionData?.currentCompanyName;

    console.log(location.state);
    const [isCompanyMode, setIsCompanyMode] = useState(isCompanyModeParam);
    const [settings, setSettings] = useState(null);
    const [settingChanges, setSettingChanges] = useState([]);

    const [errorState, setErrorState] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isSwitching, setIsSwitching] = useState(false);

    const fetchSettings = useCallback((queryKey) => {
        return isCompanyMode ? fetchCompanyUnitTypeSettings(queryKey) : fetchUserUnitTypeSettings(queryKey);
    }, [isCompanyMode]);

    const { isLoading: isSettingsLoading, refetch: refetchSettings } = useQuery(
        ["settingsInfo", companyID, userID],
        fetchSettings,
        {
            onSuccess: (data) => {
                setSettings(data);
                setIsSwitching(false);
            },
            onError: (error) => {
                setErrorState(error);
                setIsSwitching(false);
            }
        });

    useEffect(() => {
        setIsSwitching(true);
        setSettingChanges([]);
        refetchSettings();
    }, [isCompanyMode]);

    const handleSwitchChange = (event) => {
        setIsCompanyMode(event.target.checked);
    };

    const getSwitchLabel = () => {
        const displayName = settings?.DisplayName || 'Unknown User'; 
        return isCompanyMode ? 'Company' : `User (${displayName})`;
    };


    const handleRadioChange = (setting, value, origin) => {
        setSettingChanges((prev) => {
            const filteredChanges = prev
                .filter(c => !(c.ObjectID === setting.ObjectID && c.ObjectType === setting.ObjectType));

            if (value != origin) {
                filteredChanges.push({
                    CompanyID: sessionData.currentCompanyID,
                    UserID: isCompanyMode ? null : sessionData.userID,
                    ObjectID: setting.ObjectID,
                    ObjectType: setting.ObjectType,
                    DefaultUnitTypeID: parseInt(value),
                });
            }

            return [...new Set(filteredChanges)];
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        mutation.mutate(
            {
                queryKey: ["postUnitTypeSettings", sessionData.userID, settingChanges]
            },
            {
                onSuccess: () => {
                    setIsSaving(false);
                    const toastMessage = {
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Settings were updated, please wait while we refresh the screen.',
                        life: 5000,
                        position: 'center'
                    };
                    toast.current.show(toastMessage);
                    setIsSwitching(true);
                    setSettingChanges([]);
                    setTimeout(refetchSettings, 500);
                },
                onError: () => {
                    setIsSaving(false);
                    const toastMessage = {
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Settings were not accepted.',
                        life: 5000,
                        position: 'center'
                    };
                    toast.current.show(toastMessage);
                }
            });
    };

    const renderSetting = (setting, margin) => {
        return (<>
            <Grid item align="left" xs='auto'>
                {setting.Children.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)).map((s) =>
                    <Card key={`${s.ObjectID}`} variant={margin == 0 ? "outlined" : ""} sx={{ marginLeft: margin, marginRight: margin }}>
                        <FormControl>
                            <Grid container alignItems="center" columns={12}>
                                <Grid item>
                                    <FormLabel sx={{ minWidth: margin == 0 ? '330px' : '250px' }} id={`${s.ObjectID}-label`}>{margin == 0 && <FolderIcon />}{margin > 0 && <EditIcon />}&nbsp;{s.DisplayName}</FormLabel>
                                </Grid>
                                <Divider orientation="vertical" flexItem sx={{ marginLeft: 2, marginRight: 2, bgcolor: 'rgba(0, 0, 0, 0.5)' }} />
                                <Grid item>
                                    <RadioGroup
                                        row
                                        defaultValue={s.DefaultUnitTypeID || s.DataTypeDefaultUnitTypeID}
                                        name={`${s.ObjectID}-group`}
                                        onChange={(event) => handleRadioChange(s, event.target.value, s.DefaultUnitTypeID || s.DataTypeDefaultUnitTypeID)}
                                    >
                                        {s.UnitTypeList.map((ut) =>
                                            <FormControlLabel
                                                key={`${s.ObjectID}-${ut.UnitTypeID}`}
                                                control={<Radio />} label={`${ut.DisplayName}${ut.UnitTypeID == s.DataTypeDefaultUnitTypeID ? ' (DEFAULT)' : ''}`}
                                                value={ut.UnitTypeID} />
                                        )}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                        {margin == 0 && <Divider />}
                        {renderSetting(s, margin + 10)}
                    </Card>
                )}
            </Grid>
        </>);
    };

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }

    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            <Typography variant="h4" component="h2" className="header">
                {companyName}{' - '}{isCompanyMode ? 'Company' : 'User'}{ ' ' } Unit Type Settings
                <FormGroup sx={{ display: 'inline', marginLeft: '15px', }}>
                    <FormControlLabel control={<Switch
                        checked={isCompanyMode}
                        onChange={handleSwitchChange}
                        color="primary" />} label={getSwitchLabel()} />
                </FormGroup>
            </Typography>
            <div style={{ padding: '10px' }}>
                <Grid container spacing={1} align="center" justifyContent="center" alignItems="center">
                    {( isSettingsLoading || isSaving || isSwitching)
                        && <StatusBackdrop open={( isSettingsLoading || isSaving || isSwitching)} />}
                    {errorState &&
                        <StatusMessage
                            open={errorState}
                            severity="error"
                            location="Unit Type Settings"
                            statusCode={errorState?.request?.status}
                            message={errorState.message}
                            error={errorState}
                        />
                    }
                    {(settings && !isSettingsLoading && !isSaving && !isSwitching) &&
                        <Grid container columns={1} align="center" justifyContent="center">
                            <Grid item xs={1}>
                                <Button type="submit" variant="contained" color="primary" disabled={settingChanges.length == 0} onClick={handleSubmit}>Save Changes{settingChanges.length > 0 ? ` (${settingChanges.length})` : ''}</Button>
                            </Grid>
                            {renderSetting(settings, 0)}
                        </Grid>
                    }
                </Grid>
            </div>
        </Box>
    );
}