import React from 'react';
import { Button, Grid, Modal, Typography, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';

export default function ActionModal(props) {
    const defaultSx = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth: 480,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '480px',
        overflow: 'auto',
        display: 'block'
    };
    return (
        <Modal open={props.modalOpen} >
            <Paper sx={props.modalStyle || defaultSx}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={props.handleCloseClick}
                    aria-label="close"
                    style={{ position: 'absolute', right: 20, top: 5 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" align={'center'} >{`${props.action} ${props.objectType}`}</Typography>
                <Divider />
                <br />
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
                {!props.hideSubmitButton && <>
                    <Divider />
                    <br />
                    <Grid container spacing={2} direction="row" justifyContent='center'>
                        <Grid item xs={'auto'}>
                            <Button variant="contained"
                                disabled={typeof props.disableSubmitButton != 'undefined' && props.disableSubmitButton}
                                onClick={props.handleSubmitClick}>{props.submitButtonText || 'Submit'}</Button>
                        </Grid>
                    </Grid>
                </>}
            </Paper>
        </Modal>
    );
}