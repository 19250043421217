import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import AccountCompanyInvitationsAccepted from './AccountCompanyInvitationsAccepted';
import AccountCompanyInvitationsDeclined from './AccountCompanyInvitationsDeclined'; 
import AccountCompanyInvitationsPending from './AccountCompanyInvitationsPending';
import { styled } from '@mui/system';
import { useTheme } from "@mui/material/styles";


const DarkDivider = styled(Divider)`
  background-color: rgba(0, 0, 0, 0.87);
`;

export default function AccountCompanyInvites({ userInvites, handleInvitationResponse }) {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
        >
            <AccountCompanyInvitationsPending
                userInvites={userInvites }
                handleInvitationResponse={handleInvitationResponse }
            />
            <DarkDivider orientation="vertical" flexItem />
            <AccountCompanyInvitationsAccepted
                userInvites={userInvites }
            />
            <DarkDivider orientation="vertical" flexItem />
            <AccountCompanyInvitationsDeclined
                userInvites={userInvites }
            />
        </Box>
    );
}
