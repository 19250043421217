import React from 'react';
import { Paper, Modal, Button, Box, Grid, Typography, Divider } from '@mui/material';



export default function DocumentModal(props) {
    //Shows a modal for downloading documents from Azure blob storage
    return (
        <Modal
            open={props.documentModalOpen}
        >
            <Paper sx={props.modalStyle}>
                <Typography variant="h5"><b>Documents</b></Typography>
                <Divider />
                <br />
                {props.docList.map((doc, index) => {
                    return (
                        <Box sx={{ mb: "5%" }} key={index}>
                            <Button sx={{ margin: 'auto' }} fullWidth variant="contained" onClick={() => props.handleDocumentDownload(doc)}>{`Download ${doc}`}</Button>
                        </Box>
                    )
                }) }
                <br />
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Button variant="contained" style={{ float: "right" }} onClick={props.handleDocumentModalClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}