import React, { useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

const ConfirmMessage = ({ message, acceptFunction, rejectFunction, showConfirmDialog }) => {

    const accept = () => {
        if (acceptFunction) {
            acceptFunction();
        }
    };

    const reject = () => {
        if (rejectFunction) {
            rejectFunction();
        }
    };

    useEffect(() => {
        if (showConfirmDialog) {
            confirmDialog({
                group: 'headless',
                message: message.message,
                header: message.header,
                icon: 'pi pi-exclamation-triangle',
                defaultfocus: 'accept',
                accept,
                reject
            });
        }
    }, [showConfirmDialog]);

    return (
        <>
            <ConfirmDialog
                group="headless"
                content={({ headerRef, contentRef, footerRef, hide, message }) => (
                    <div className="flex flex-column align-items-center p-5 surface-overlay border-round w-30rem">
                        <div className="border-circle bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                            <i className="pi pi-question text-5xl"></i>
                        </div>
                        <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
                            {message.header}
                        </span>
                        <p
                            className="mb-0 justify-content-center align-items-center"
                            ref={contentRef}
                            style={{
                                whiteSpace: 'pre-wrap',  // Keeps whitespace and wraps text
                                wordWrap: 'break-word',  // Break long words if necessary
                            }}>
                            {message.message}
                        </p>
                        <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>                            
                            <Button
                                label="No"      
                                outlined
                                onClick={(event) => { 
                                    hide(event);
                                    reject();
                                }}
                                className="w-8rem"
                            ></Button>
                            <Button
                                label="Yes"                                
                                onClick={(event) => { 
                                    hide(event);
                                    accept();
                                }}
                                className="w-8rem"
                            ></Button>
                        </div>
                    </div>
                )}
            />              
        </>
    );
};

export default ConfirmMessage;
