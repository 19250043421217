import React from 'react';
import { useField, ErrorMessage } from 'formik';
import FieldHelperText from './FieldHelperText';
import {
    TextField,
    Box,
    FormControl,
    FormLabel
} from '@mui/material';

export default function InputField(props) {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: props.width,
                    maxWidth: '100%',
                }}
            >
                <FormControl {...rest} fullWidth>
                    <FormLabel id={"Input-label-" + field.name}>{props.label}</FormLabel>
                    <TextField
                        type={"text"}
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        error={meta.touched && Boolean(meta.error)}
                        placeholder={props.placeholder}
                        {...field}                    
                    />
                </FormControl>
                {meta.touched && meta.error &&
                    <ErrorMessage name={field.name}>
                        {msg => <FieldHelperText message={msg} />}
                    </ErrorMessage>
                }
            </Box>
        </Box>
    );
}