import { fetchBlobRequest } from "../Generic/MiscFunctions";

export const fetchImage = async (imageFileName, container) => {
    //console.log(imageFileName);
    if (!imageFileName) {
        throw new Error("The 'imageFileName' parameter is required.");
    }

    let image = {
        "ContainerName": container, "ImageFileName": imageFileName
    }

    return fetchBlobRequest(`/Blob/image`, "Failed to fetch Image", image);
};
