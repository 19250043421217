import React, { useState } from 'react';
import { TextField } from '@mui/material';


export default function AssetNameFormItem(props) {
    const [validAssetName, setValidAssetName] = useState(false);

    // Validate characters in Asset Name
    const validateAssetName = (e) => {
        if (props.value.replace(/\s/g, "").length === 0) {
            setValidAssetName(true);
        } else {
            setValidAssetName(false);
        }
    }

    return (
        <React.Fragment>
                <TextField
                    required
                    fullWidth
                    value={props.value}
                    onChange={props.onChange}
                    inputProps={{ maxLength: 255 }}
                    label="Asset Name"
                    InputLabelProps={{ shrink: true }}
                    onBlur={validateAssetName}
                    error={validAssetName}
                    helperText={validAssetName ? 'Required' : ''}
                />
        </React.Fragment>
    )
}