import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from '@mui/material';
import StatusBackdrop from "../Generic/StatusBackdrop";
import StatusMessage from "../Generic/StatusMessage";
import EmptyAsset from '../images/EmptyAsset.png';
import AssetDashboardTableExpandable from "./AssetDashboardTableExpandable";
import { useSession } from '../../SessionProvider';
import CustomHeader from '../Generic/CustomHeader';
import { GetSeverityStatusColorVIB, CalculateTimeDifference } from '../Generic/MiscFunctions';
import { fetchCompanyInfo } from '../Company/CompanyQueryFunctions';
import {
    fetchVibrationEnums,
    fetchVibrationDashboard
} from './VibrationQueryFunctions';
import { fetchImage } from '../images/ImageQueryFunctions';

export default function AssetDashboard() {
    const navigate = useNavigate();
    const { sessionData } = useSession();

    const companyID = sessionData?.currentCompanyID;
    const userID = sessionData?.userID;
    const [refresh, setRefresh] = useState(0);
    const [vibrationObjects, setVibrationObjects] = useState([]);
    const [vibrationObjectNodes, setVibrationObjectNodes] = useState([]);
    const [nodeDiagnosticStatus, setNodeDiagnosticStatus] = useState([]);
    const [nodeCheckInStatus, setNodeCheckInStatus] = useState([]);
    const [nodeMaxAlarmValues, setNodeMaxAlarmValues] = useState([]);
    const [driveType, setDriveType] = useState([]);
    const [machineType, setMachineType] = useState([]);
    const [positionType, setPositionType] = useState([]);
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);
    const [dashboardRowsExpanded, setDashboardRowsExpanded] = useState([]);
    const [dashboardRowsPerPage, setDashboardRowsPerPage] = useState(10);
    const [dashboardPage, setDashboardPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [errorState, setErrorState] = useState();
    const [imagesLoading, setImagesLoading] = useState(true);
    const [vendorIcon, setVendorIcon] = useState(null);
    const [failedToLoad, setFailedToLoad] = useState({});

    // Force react components to refresh every 10 seconds (will run on refresh variable change)
    // Debounced refresh
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(prevRefresh => prevRefresh + 1);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const handleImageError = (vendorName) => {
        setFailedToLoad((prevFailedToLoad) => ({ ...prevFailedToLoad, [vendorName]: true }));
    };

    const fetchVendorImage = async (ImageFileName) => {
        if (!ImageFileName) {
            setImagesLoading(false);
            return;
        }

        try {
            const image = await fetchImage(ImageFileName, process.env.REACT_APP_IMAGE_CONTAINER_NOTIFICATION);
            setVendorIcon(image);
        } catch (error) {
            console.error('Error fetching image:', error);
        } finally {
            setImagesLoading(false);
        }
    };

    useEffect(() => {
        if (imagesLoading) {
            fetchVendorImage('fiix_logo_color_center.png');
        }
    }, [imagesLoading]);

    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } = useQuery(
        ["enums"],
        fetchVibrationEnums,
        {
            onSuccess: (dataE) => {
                setDriveType(dataE.DriveTypes);
                setMachineType(dataE.MachineTypes);
                setAlarmStatusInfo(dataE.AlarmStatusInfo);
                setPositionType(dataE.PositionTypes);
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        }
    );

    const { isLoading: isLoadingVibrationObjects, error: errorVibrationObjects, data: dataA } = useQuery(
        ["dashboard", companyID, userID, { refresh }],
        fetchVibrationDashboard,
        {
            onSuccess: (dataA) => {
                if (JSON.stringify(dataA.VibrationObjects) !== JSON.stringify(vibrationObjects)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjects(dataA.VibrationObjects);
                }
                if (JSON.stringify(dataA.VibrationObjectNodes) !== JSON.stringify(vibrationObjectNodes)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjectNodes(dataA.VibrationObjectNodes);
                }
                let newNodeCheckInStatus = nodeCheckInStatus.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.SecondsSinceCheckIn;
                    return newObj;
                });
                if (JSON.stringify(dataA.NodeDiagnosticStatuses) !== JSON.stringify(nodeDiagnosticStatus)) {
                    setIsLoadingData(true);
                    setNodeDiagnosticStatus(dataA.NodeDiagnosticStatuses);
                }
                if (JSON.stringify(dataA.NodeCheckInStatuses) !== JSON.stringify(newNodeCheckInStatus)) {
                    setIsLoadingData(true);
                    setNodeCheckInStatus(dataA.NodeCheckInStatuses);
                }
                if (JSON.stringify(dataA.NodeMaxAlarmValues) !== JSON.stringify(nodeMaxAlarmValues)) {
                    setIsLoadingData(true);
                    setNodeMaxAlarmValues(dataA.NodeMaxAlarmValues);
                }
            },
            onError: (errorVibrationObjects) => {
                setErrorState(errorVibrationObjects || "An unexpected error occurred.");
            },
            enabled: !!companyID && !!userID
        }
    );

    useEffect(() => {
        if (isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardStructureChange();
        }
    }, [vibrationObjects, vibrationObjectNodes, machineType, positionType, driveType, imagesLoading]);

    useEffect(() => {
        if (isLoadingData && !isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardDataChange();
        }
    }, [dashboardData, isLoadingData, isLoadedEnums, imagesLoading]);

    const handleDashboardStructureChange = useCallback(() => {
        let newDashboardData = vibrationObjects.map((v) => {
            const obj = {
                AccelerationID: v.AccelerationID,
                AnalysisTypeID: v.AnalysisTypeID,
                DisplayName: v.DisplayName,
                DriveType: driveType[v.DriveTypeID],
                EnabledVibrationChannels: v.EnabledVibrationChannels,
                FixedRPM: v.FixedRPM,
                GearMeshValue: v.GearMeshValue,
                HighRPM: v.HighRPM,
                ID: v.ID,
                IndividualNodeAlarms: v.IndividualNodeAlarms,
                IsConnected: v.IsConnected,
                LowRPM: v.LowRPM,
                MachineType: machineType[v.MachineTypeID],
                SamplingPeriod: v.SamplingPeriod,
                Nodes: [],
                NodeNames: v.DisplayName + " ",
                AssetStatus: {},
                VendorName: v.VendorName,
                VendorIcon: vendorIcon,
                ObjectDefectSummary: v.ObjectDefectSummary
            };
            vibrationObjectNodes.filter(n => n.ObjectID === v.ID && n.NodeID != undefined).forEach((n) => {
                const node = {
                    NodeID: n.NodeID,
                    DisplayName: n.NodeDisplayName,
                    OrientationID: n.OrientationID,
                    PositionType: positionType.find(p => p.ID === n.PositionID),
                    IsConnected: n.IsConnected,
                    CheckinInfo: {},
                    SignalInfo: {},
                    BatteryInfo: {},
                    StatusInfo: {}
                };
                obj.Nodes.push(node);
                obj.NodeNames += (n.NodeDisplayName ? n.NodeDisplayName + " " : " ");
            });
            return obj;
        });

        setDashboardData(newDashboardData);
        setIsLoadingDashboard(false);
        setIsLoadingData(true);
    }, [vibrationObjects, vibrationObjectNodes, driveType, machineType, positionType, vendorIcon]);

    const handleDashboardDataChange = useCallback(() => {
        let updatedDashboardData = dashboardData.map((o) => {
            let diagnostics = nodeDiagnosticStatus.filter(s => s.ObjectID === o.ID);
            o.Diagnostics = diagnostics || [];

            let checkin = nodeCheckInStatus.filter(s => s.ObjectID === o.ID);
            o.CheckinInfo = checkin || [];

            let maxAlarm = nodeMaxAlarmValues.filter(a => a.ObjectID === o.ID);
            o.MaxAlarm = maxAlarm || [];
            o.AssetStatus = parseAssetStatus(maxAlarm, checkin);

            o.Nodes = o.Nodes.map((n) => {
                n.SignalInfo = parseNodeSignal(o.ID, n.NodeID);
                n.BatteryInfo = parseNodeBattery(o.ID, n.NodeID);
                n.CheckinInfo = parseNodeCheckin(o.ID, n.NodeID);
                n.StatusInfo = parseNodeStatus(o.ID, n.NodeID, n.CheckinInfo);
                return n;
            });

            return o;
        });

        setDashboardData(updatedDashboardData);
        setIsLoadingData(false);
    }, [dashboardData, nodeDiagnosticStatus, nodeCheckInStatus, nodeMaxAlarmValues]);

    const parseNodeSignal = useCallback((objectID, nodeID) => {
        return nodeDiagnosticStatus.find(
            d => d.ObjectID === objectID && d.NodeID === nodeID && d.DataTypeDisplayName.includes('Signal Strength')
        ) || {};
    }, [nodeDiagnosticStatus]);

    const parseNodeBattery = useCallback((objectID, nodeID) => {
        return nodeDiagnosticStatus.find(
            d => d.ObjectID === objectID && d.NodeID === nodeID && d.NodeChannelDisplayName === 'Battery Voltage'
        ) || {};
    }, [nodeDiagnosticStatus]);

    const parseAssetStatus = useCallback((maxAlarm, checkin) => {
        const maxAlarmValues = maxAlarm.map((alarm) => alarm.MaxAlarmValue);
        const nonNullValues = maxAlarmValues.filter((value) => value !== null);
        const maxAlarmValue = nonNullValues.length > 0 ? Math.max(...nonNullValues) : null;
        const secondsSinceCheckIns = checkin.map((checkIn) => CalculateTimeDifference(checkIn.CheckInTimeZone));
        const nonsecNullValues = secondsSinceCheckIns.filter((value) => value !== null);
        const minSecondsSinceCheckIn = nonsecNullValues.length > 0 ? Math.min(...nonsecNullValues) : null;

        return GetSeverityStatusColorVIB(maxAlarmValue, alarmStatusInfo, minSecondsSinceCheckIn);
    }, [alarmStatusInfo]);

    const parseNodeStatus = useCallback((objectID, nodeID, checkinInfo) => {
        const nodeStatus = nodeMaxAlarmValues.find(d => d.ObjectID === objectID && d.NodeID === nodeID);
        let status = nodeStatus ? nodeStatus.MaxAlarmValue : null;

        return GetSeverityStatusColorVIB(status, alarmStatusInfo, checkinInfo ? checkinInfo.SecondsSinceCheckIn : null);
    }, [nodeMaxAlarmValues, alarmStatusInfo]);

    const parseNodeCheckin = useCallback((objectID, nodeID) => {
        let checkin = nodeCheckInStatus.find(d => d.ObjectID === objectID && d.NodeID === nodeID);

        if (checkin && Object.keys(checkin).length > 0) {
            const seconds = checkin.CheckInTimeZone ? CalculateTimeDifference(checkin.CheckInTimeZone) : null;
            checkin.SecondsSinceCheckIn = seconds;
            return checkin;
        } else {
            return {};
        }
    }, [nodeCheckInStatus]);

    const handleAddButtonClick = () => {
        navigate(`/Vibration/AddAsset/${companyID}/${userID}/${sessionData.viewAll}`);
    }

    const handleRowsPerPageChange = (rowsPerPage) => {
        setDashboardRowsPerPage(rowsPerPage);
    }

    const handleDashboardRowChange = (expandedRows) => {
        setDashboardRowsExpanded(expandedRows);
    }

    const handlePageChange = (currentPage) => {
        setDashboardPage(currentPage);
    }

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText || "");
    }

    return (
        <Box sx={{ margin: "2%", flexGrow: 1 }}>
            <CustomHeader headerText={"Asset Dashboard"} />
            {(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard) &&
                <StatusBackdrop open={(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard) && refresh === 0} />
            }
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Asset Dashboard"
                    statusCode={errorState.request?.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                <Grid container spacing={1} columns={6}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} align="right" justifyContent="right" alignItems="center">
                            <Button onClick={handleAddButtonClick} variant="contained">Add Asset</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {(((!dashboardData) || (dashboardData.length === 0)) && (!isLoadingDashboard) && (!isLoadingData)) &&
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <img src={EmptyAsset} />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {((dashboardData) && (dashboardData.length > 0) && (!isLoadingDashboard) && (!isLoadingData)) && (!imagesLoading) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <AssetDashboardTableExpandable
                            title={''}
                            data={dashboardData}
                            alarmStatusInfo={alarmStatusInfo}
                            companyID={companyID}
                            userID={userID}
                            onRowExpansionChange={handleDashboardRowChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            onChangeCurrentPage={handlePageChange}
                            dashboardRowsExpanded={dashboardRowsExpanded}
                            dashboardRowsPerPage={dashboardRowsPerPage}
                            onChangeSearchText={handleSearchTextChange}
                            searchText={searchText}
                            dashboardPage={dashboardPage}
                            viewAll={sessionData.viewAll}
                            searchAlwaysOpen={true}
                        />
                    </Grid>
                </Grid>
            }
        </Box>
    );
}
