import React, { useCallback, useRef, useState, useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Box } from '@mui/system';
import { Toast } from 'primereact/toast';
import CustomHeader from '../../Generic/CustomHeader';
import StatusBackdrop from "../../Generic/StatusBackdrop";
import StatusMessage from "../../Generic/StatusMessage";
import {
    fetchDefectRecipes
} from '../VibrationQueryFunctions';
import Defect3DAnimation from './Defect3DAnimation';
import DefectPeriodTable from './DefectPeriodicTable';
import Defects from './Defects';
import { useSession } from '../../../SessionProvider';

// Expected Route: <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID' element={<DefectDetail />} />
export default function DefectDetail() {
    const { sessionData } = useSession();
    const { vibrationObjectID, defaultRecipeStateID } = useParams();
    const toast = useRef(null);
    const [errorState, setErrorState] = useState();
    const [isLoadingDefectRecipes, setIsLoadingDefectRecipes] = useState(false);
    const [defectRecipes, setDefectRecipes] = useState([]);
    const [headerText, setHeaderText] = useState("Defects");
    const [selectedValue, setSelectedValue] = useState({ index: '', name: '', item: {} });
    const [recipeID, setRecipeID] = useState();
    const [recipeStateID, setRecipeStateID] = useState(Number(defaultRecipeStateID));

    const handleDropdownChange = useCallback((index, name, recipeID, item) => {
        setRecipeID(recipeID);
        setSelectedValue({ index, name, item });
    }, []); // Dependency array is empty because we don't depend on any external values

    const handleElementChange = useCallback((defect) => {
        setRecipeStateID(defect.RecipeStateID);
        setRecipeID(defect.RecipeID);
        setSelectedValue({ index: defect.RecipeStateID, name: defect.DefectName, item: defect });
    }, []); // Dependency array is empty because we don't depend on any external values


    useQuery(["DefectDetail.defectRecipes", sessionData.userID, vibrationObjectID], fetchDefectRecipes, {
        onSuccess: (data) => {
            function formatDataRows(dataRows) {
                return dataRows.filter(dr => dr.Likelihood != "4 - Unlikely" || (dr.Likelihood == "4 - Unlikely" && dr.PercentComplete > 0))
                    .map(dr => {
                        dr.Likelihood = dr.Likelihood.replace(/[0-9]+ - /, '');
                        return dr;
                    });
            }
            let formattedDataRows = formatDataRows(data);

            setDefectRecipes(formattedDataRows);
            setIsLoadingDefectRecipes(false);
            // Assume that data is an array and the first item always exists for header setting
            setHeaderText(`Defects Details: ${data[0]?.AssetName}`);

            // Check if defaultRecipeStateID is provided and valid
            if (recipeStateID) {
                const matchedRecipe = data.find(recipe => Number(recipe.RecipeStateID) === Number(recipeStateID));
                if (matchedRecipe) {
                    setRecipeID(matchedRecipe.RecipeID);
                    setSelectedValue({ index: matchedRecipe.RecipeStateID, name: matchedRecipe.DefectName, item: matchedRecipe });
                }
            }
        },
        onError: (errorDefectRecipes) => {
            // Handle the error here. For example, you can log the error or set an error state.
            setErrorState(errorDefectRecipes || "An unexpected error occurred.");
            setIsLoadingDefectRecipes(false);
        }
    })

    const getHeaderText = () => {
        return <CustomHeader headerText={headerText} />;
    };

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }
    return (
        <Box sx={pageStyle}>
            {/*<CustomHeader headerText={headerText} />*/}
            {getHeaderText()}
            <Toast ref={toast} />
            {isLoadingDefectRecipes && <StatusBackdrop open={isCompanyLoading} />}
            {
                errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location={getHeaderText()}
                    statusCode={errorState?.request?.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {!isLoadingDefectRecipes && defectRecipes &&
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Defects
                            userID={sessionData.userID}
                            defects={defectRecipes}
                            onDropdownChange={handleDropdownChange}
                            selectedValue={selectedValue}
                            defaultRecipeStateID={recipeStateID}
                        />
                    </Grid>

                    <Grid item xs={7}>
                        <DefectPeriodTable
                            userID={sessionData.userID}
                            activeRecipeID={recipeID}
                            defectRecipes={defectRecipes}
                            defaultRecipeStateID={recipeStateID}
                            selectedValue={selectedValue}
                            handleElementChange={handleElementChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Defect3DAnimation />
                    </Grid>
                </Grid>
            }

        </Box>
    );
}