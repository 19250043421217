import React from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { Grid, Paper, Typography } from '@mui/material';
import DroppableList from '../Generic/DroppableList';


export default function AssetNodeSelect(props) {

    const handleUnassignedNodesChange = (event) => { props.handleUnassignedNodesChange(event) }
    const handleP1NodesChange = (event) => { props.handleP1NodesChange(event, 0) }
    const handleP2NodesChange = (event) => { props.handleP2NodesChange(event, 1) }
    const handleP3NodesChange = (event) => { props.handleP3NodesChange(event, 2) }
    const handleP4NodesChange = (event) => { props.handleP4NodesChange(event, 3) }


    // Initialize node lists (dropZones)
    const dropZones = [
        {
            items: props.unassignedNodes,
            droppableId: "UnassignedList_",
            title: "Unassigned Nodes",
            handler: handleUnassignedNodesChange
        },
        {
            items: props.p1Nodes,
            droppableId: "DropListP1_",
            title: "Outboard Motor (P1) Node",
            handler: handleP1NodesChange
        },
        {
            items: props.p2Nodes,
            droppableId: "DropListP2_",
            title: "Inboard Motor (P2) Node",
            handler: handleP2NodesChange
        },
        {
            items: props.p3Nodes,
            droppableId: "DropListP3_",
            title: "Inboard Machine (P3) Node",
            handler: handleP3NodesChange
        },
        {
            items: props.p4Nodes,
            droppableId: "DropListP4_",
            title: "Outboard Machine (P4) Node",
            handler: handleP4NodesChange
        }
    ]

    // Handler for onDragEnd (drop) behavior
    const onAssetNodeSelectDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        // Don't do anything if node is dropped outside of droppable
        if (!destination || !source) {
            return;
        }

        // Don't do anything if node hasn't changed lists
        if (source.droppableId === destination.droppableId) {
            return;
        }

        // Define destination zone
        const destZone = dropZones.find((element) => {
            return element.droppableId === destination.droppableId
        })

        // Define source zone
        const sourceZone = dropZones.find((element) => {
            return element.droppableId === source.droppableId
        })

        // Drop source item in destination zone
        const sourceItem = sourceZone.items[source.index];
        destZone.items.splice(destination.index, 0, sourceItem)

        // Eliminate source item from source zone
        sourceZone.items.splice(source.index, 1);

        // Update parent state
        destZone.handler(destZone)
        sourceZone.handler(sourceZone)
    };
    
    return (
        <React.Fragment>
            <Grid container item spacing={3} style={{ height: '100%' }}>
                <DragDropContext onDragEnd={onAssetNodeSelectDragEnd} style={{ height: '100%' }}>
                    <Grid item xs={4} style={{ height: '100%' }} >
                        <Paper elevation={8} style={{ padding: '1px', height: '100%', overflow: 'auto' }}>
                            <Typography fontWeight={'bold'}>Unassigned Nodes</Typography>
                            <DroppableList
                                items={dropZones[0].items}
                                droppableId={dropZones[0].droppableId}
                                key={dropZones[0].droppableId}
                                title={dropZones[0].title}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4} style={{ height: '100%' }} >
                        <Paper elevation={8} style={{ padding: '1px', height: '100%' }}>
                            {
                                dropZones.map(({ items, droppableId, title }, index) => {
                                    if (index > 0) {
                                        let posIdx = index - 1
                                        return (
                                            <Grid item xs={12} style={{ height: '100px' }} key={index} >
                                                <Paper variant="outline" style={{padding: '5px', height: '98%'}}>
                                                    <Typography fontWeight={'bold'}>{title}</Typography>
                                                    <DroppableList
                                                        items={items}
                                                        droppableId={droppableId}
                                                        key={droppableId}
                                                        title={title}
                                                        handleModalOpen={(e) => props.handleModalOpen(e, posIdx)}
                                                    />
                                                </Paper>
                                            </Grid>
                                        );
                                    }
                                })}
                        </Paper>
                    </Grid>
                </DragDropContext>
            </Grid>
        </React.Fragment>
    );
}
