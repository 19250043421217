import React, { createContext, useState, useContext } from 'react';
import AuthService from './AuthService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

    const login = (user) => {
        AuthService.login(user).then(            
            (response) => {
                setCurrentUser(response);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const logout = () => {
        AuthService.logout();
        setCurrentUser(null);
    };

    const isAuthenticated = () => {     

        return !process.env.REACT_APP_ENABLE_AUTH || currentUser != null;
    };

    return (
        <AuthContext.Provider value={{ currentUser, login, logout, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);